
export const FORMSLIBRARY = {
    EN: {
        PROJECT_TABLE: {
            TITLES: {
                FORMS_LIBRARY_TITLE: 'Forms Library',
                FORMS_TITLE: 'Forms',
                FORMS_DESIGNER_TITLE: 'Form Designer',
                FORMS_HELP_TITLE: 'Form Help',
                CLICK_HEADING_SORT_FILTER: 'Click Heading to sort and filter'
            },
            GRID_CAPTIONS: {
                APPROVE: "Approve",
                READY_RELEASE: "Ready for Release",
                ACTIVE: "Active",
                REVIEWED: "Reviewed",
                NOROWSLABEL: "No data loaded. Please select a methodology version to load data.",
                NO_RESULTS_AFTER_FILTER: 'No forms match filter criteria.'
            },
            COLUMNS: {
                INDUSTRY: 'Industry',
                FORM_CATEGORY: 'Form Category',
                FORM_NAME: 'Form Name',
                FORM_DESCRIPTION: 'Form Description',
                METHODOLOGY_VERSION: 'Methodology Version',
                APPROVAL_COMMENT: 'Approval Comment',
                STATUS: 'Status'
            },
            ACTION: {
                ELLIPSIS: {
                    NAME: 'ELLIPSIS',
                    POSITION: 'START'
                }
            }
        },
        CONTEXT_MENU: {
            OPEN: 'Open/Edit',
            DUPLICATE: 'Duplicate',
            DELETE: 'Delete',
            REMOVE_SIGNOFF: 'Remove Sign-off',
            UNAPPROVE: 'Unapprove'
        },
        BUTTON: {
            ADD_FORM: "Add Form",
            CANCEL: "Cancel",
            APPROVE: "Approve",
            DELETE: "Delete",
            UNAPPROVE: "Unapprove",
            OK: "OK"
        },
        APPROVAL: {
            APPROVAL_HEADER: "Confirmation",
            APPPROVAL_MESSAGE: "Are you sure you want to approve this form Ready for Release?"
        },
        DUPLICATE: {
            DUPLICATE_HEADER: "Form cannot be deleted",
            DUPLICATE_MESSAGE: "Components on this form are referenced within components in other forms in the methodology version. The form cannot be deleted until those references are removed."
        },
        DELETE: {
            DELETE_HEADER: "Warning",
            DELETE_MESSAGE: "Are you sure you want to delete this? It will permanently delete the data."
        },
        UNAPPROVAL: {
            UNAPPROVAL_HEADER: "Confirmation",
            UNAPPPROVAL_MESSAGE: "Are you sure you want to unapprove this form? It will remove all sign offs and revert the form back to Draft status."
        }
    }
}


export const TABLE_FORMSLIBRARY_COLUMNS = [
    { id: 'actions', label: '', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "40px" },
    { id: 'industry', label: 'Industry', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "50px", width: "80px" },
    { id: 'formcategory', label: 'Form Category', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'formname', label: 'Form Name', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'formdescription', label: 'Form Description', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'methodologyversion', label: 'Methodology Version', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'approvalcomment', label: 'Approval Comment', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'status', label: 'Status', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
]

export const FORMLIBRARY_TABLE_MOCK_DATA = {
    data: [{
        id: 1,
        industry: "Government",
        category: "Government Audit",
        formName: "Major Fund Determination Worksheet",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2022.1,
        approvalComment: 5,
        status: "Approve",
    },
    {
        id: 2,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 7,
        status: "Ready for Release",
    },
    {
        id: 3,
        industry: "All",
        category: "Fraud Category",
        formName: "Fraud prevention and deterrence",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 4,
        industry: "Law Enforcement",
        category: "Investigation",
        formName: "Investigation",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 3,
        status: "Ready for Release",
    },
    {
        id: 5,
        industry: "Business",
        category: "Loan",
        formName: "Settlement factors",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 6,
        industry: "Law Enforcement",
        category: "Federal Program",
        formName: "Federal Program Number 1",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    }
        , {
        id: 7,
        industry: "Business",
        category: "Liabilities",
        formName: "Liabilities",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Approve",
    },
    {
        id: 8,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 11,
        status: "Ready for Release",
    },
    {
        id: 9,
        industry: "All",
        category: "Monitoring of controls",
        formName: "Monitoring of controls",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 9,
        status: "Ready for Release",
    },
    {
        id: 10,
        industry: "Business",
        category: "Permanent funds",
        formName: "Permanent funds",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 11,
        industry: "Aerospace",
        category: "NASA",
        formName: "Spaceships",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 12,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 13,
        industry: "Rules",
        category: "Segregation of Duties",
        formName: "Entity Duties",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 14,
        industry: "Business",
        category: "Special revenue ",
        formName: "Special revenue ",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 2,
        status: "Ready for Release",
    },
    {
        id: 15,
        industry: "Accounts",
        category: "State Board of Accounts",
        formName: "State Board of Accounts",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 16,
        industry: "Government",
        category: "Tax Abatement",
        formName: "Tax Abatement",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 1,
        status: "Ready for Release",
    },
    {
        id: 17,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 2,
        status: "Ready for Release",
    },
    {
        id: 18,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 19,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    },
    {
        id: 20,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 7,
        status: "Ready for Release",
    },
    {
        id: 21,
        industry: "All",
        category: "Risk Assessment Procedures",
        formName: "Understanding the Entity",
        formDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        methodology: 2023.1,
        approvalComment: 0,
        status: "Ready for Release",
    }
    ]
}