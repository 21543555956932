import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ButtonGroup, Button } from '@mui/material';

import { DRAG_TYPES, INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';
import { DeleteIconComponent } from '@ais/assets';
import { ReactComponent as DeleteDisabledIcon } from '@assets/form_instance_delete_disabled.svg';
import { ReactComponent as DraggableArrowIcon } from '@assets/arrow_draggable.svg'; 
import { useProjectFormInstanceProviderV2 } from '@providers';

import styles from '../../FormView.module.css'; 
  
export const VFDroppableExistingField = (props) => {
  const {
    isEditMode,
    field,
    rules,
    children,
    section,
    sectionIndex,
    rowIndex,
    handleDeleteField,
    saveFields 
  } = props;
  const { projectForm, schema, actions: { updateSchema } } = useProjectFormInstanceProviderV2();

  const { formTypeId } = projectForm;
  const dropRef = useRef(null);

  const applyClass = () => {
    let classList = [styles['rendered-field']];
    if (isEditMode) classList.push(styles['edit-mode']);
    return classList.join(' ');
  };

  const [, drop] = useDrop({
    accept: DRAG_TYPES.RENDERED_FIELD,
    hover: (item, _) => {
      if (!dropRef.current) return;
      const dragIndex = item.rowIndex;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) return; 
      const fields = [...schema[sectionIndex]?.fields];
      const dragged = fields[dragIndex];
      fields.splice(dragIndex, 1);
      fields.splice(hoverIndex, 0, dragged);
      saveFields(hoverIndex, dragged[0]);
      updateSchema((prevSchema) => {
        return prevSchema.map((data) => {
          if (data.id.toLowerCase() === section.id.toLowerCase()) {
            return {
              ...data,
              fields: fields
            }
          }
          return data;
        })
      })
      item.rowIndex = hoverIndex;
    }
  });

  drop(dropRef);

  const sectionType = section?.sectionType ?? section?.type;
  const formIsInternalControl = formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
  const getWrapperClass = (type) => {
    switch (type) {
      case DRAG_TYPES.SECTION_BLANK: {
        if (formIsInternalControl) return null;
        return styles['vf-section-blank-wrapper'];
      }
      case DRAG_TYPES.SECTION_CUSTOM:
        return styles['vf-section-custom-wrapper'];
      default:
        return styles['vf-rendered-field-wrapper'];
    }
  };
  return (
    <div
      className={getWrapperClass(sectionType)}
      style={{ width: `${field.width}%` }}
    >
      <div ref={dropRef} className={applyClass()}>
        {children}
        {isEditMode && <div className={styles['field-overlay']}></div>}
        {isEditMode && field?.isFormInstanceQuestion && (
          <DraggableArrowIcon className={styles['vf-rendered-field-drag-icon']} />
        )}
      </div>
      {isEditMode && (
        <div className={styles['vf-rendered-field-buttons']}>
          <ButtonGroup
            sx={{ borderColor: 'gray' }}
            variant="outlined"
            aria-label="medium button group"
          >
            <Button
              sx={{ borderColor: 'gray' }}
              disabled={!!rules?.canNotBeDeleted}
              onClick={() => handleDeleteField(field.id)}
            >
              {!!rules?.canNotBeDeleted ? <DeleteDisabledIcon /> : <DeleteIconComponent />}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};
