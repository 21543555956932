import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { IconButton, Collapse } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';

import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";

import {
  ACTION_ELLIPSIS_TYPES
} from '@constants/forms'; 
import { useQuery, useScroll } from '@hooks/index';
import string from "@utilities/stringHelper";

import { EllipsisHeader } from './EllipsisHeader/EllipsisHeader';
import { AddQuestion, AddRiskFactor, AddRisk, DeficiencyModal } from '@components/FormView/actions';
import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { VFSectionV2Description } from './VFSectionV2Description';
import { VFSectionV2Tooltip } from './VFSectionV2Tooltip';
import styles from './VFSectionV2.module.css';

import { useProjectFormInstanceProviderV2 } from '@providers';
import {
  DRAG_TYPES,
  FIELD_TYPES
} from '@ais/constants';
import {
  KPIS_RATIOS
} from '@constants/forms';
import PurposeObjectiveProcedure from '@views/InternalControl/components/Sections/PurposeObjectiveProcedure/PurposeObjectiveProcedure';
import { useAccountAssertionLevelRiskState } from '@views/RiskAssesmentSummary/components/AccountAssertionLevelRisk/AccountAssertionLevelRiskContext';
import { useProjectScope } from '@views/ProjectScopingForm';
import { useRiskAssessment } from '@views/RiskAssesmentSummary';
import { usePlanningAnalytics } from '@views/PlanningAnalytics';

import useRules from '../hooks/useRules';


const VFSectionV2 = (props) => {
  const { projectForm, answerList, units, sectionOnEditView , deleteModalData, actions: { handleIsSectionExpanded, handleSectionOnEditView } } = useProjectFormInstanceProviderV2();
  const { updateScopingSectionExpanded } = useProjectScope();
  const { projectId } = useParams();
  const { projectFormID, projectFormName } = projectForm;

  const { actions: { updateFinancialStatementLevelRiskToggle, updateRiskSectionExpanded } } = useRiskAssessment();
  const { actions: { updateAnalyticsSectionExpanded } } = usePlanningAnalytics();
  const { isChildExpanded } = useAccountAssertionLevelRiskState();
  const frameworks = useMemo(() => (
    projectForm.frameworks ? projectForm.frameworks.map(f => f.FrameworkId) : []
  ), [projectForm.frameworks]);

  const performanceStandards = useMemo(() => (
    projectForm.performanceStandards ? projectForm.performanceStandards.map(ps => ps.PerformanceStandardId) : []
  ), [projectForm.performanceStandards]);

  const { section, isProjectFinalized, children } = props
  const query = useQuery();
  const sectionExpanded = isChildExpanded(section.id);
  const { sectionType: _sectionType } = section;
  const sectionType = _sectionType ?? section.type;
  const [isExpanded, setIsExpanded] = useState(!section?.showCollapsed)
  const [modalType, setModalType] = useState(null);
  const [executeScroll, elRef] = useScroll()
  const sectionId = query.get('sectionId')
  const questionIds = query.get('questionIds')
  const hasSingleQuestion = !questionIds || questionIds.split(',').length <= 1
  const shouldScrollToSection = string.toLowerCaseTrim(section.id) === string.toLowerCaseTrim(sectionId) && hasSingleQuestion;
  const isAssertionLevelRiskSection = sectionType === 'CUSTOM' && section?.fields?.flatMap(i => i).some(f => f.type === FIELD_TYPES.ACCOUNT_AND_ASSERTION_LEVEL_RISK);
  const { shouldDisplay } = useRules(section.rules, answerList, projectForm.methodologyIndustries, frameworks, performanceStandards);
  const updateMyPresence = useUpdateMyPresence();
  const isIdle = useRoomIdle();
  
  const handleModalType = (type) => {
    if (ACTION_ELLIPSIS_TYPES.EDIT_MOVE_QUESTION === type) {
      handleSectionOnEditView(section.id);
      return;
    }
    setModalType(type)
  }

  useEffect(() => {
    if (shouldScrollToSection) executeScroll()
  }, [])

  useEffect(() => {
    if (isAssertionLevelRiskSection) {
      setIsExpanded(sectionExpanded)
    }
  }, [sectionExpanded])

  useEffect(() => {
    updateScopingSectionExpanded(section.id, isExpanded);
    updateRiskSectionExpanded(section.id, isExpanded);
    updateAnalyticsSectionExpanded(section.id, isExpanded);
    handleIsSectionExpanded(section.id, isExpanded);
  }, [isExpanded]);

	useEffect(() => {
		if (isIdle && projectForm.formTypeId === 6) {
			setModalType(null);
		}
	}, [isIdle]);

  if (!shouldDisplay) return <></>;
  //excluding planning analytics KPI ratio section
  if (section?.title === KPIS_RATIOS.title) return <></>;
  //end
  if (section?.type === DRAG_TYPES.SECTION_BLANK &&
    section.fields.some(row => row.some(field => field.type === FIELD_TYPES.PURPOSE_OBJECTIVE_PROCEDURE))) return <PurposeObjectiveProcedure />
  if (section.type === DRAG_TYPES.SECTION_BLANK)
    return (
      <div ref={elRef} className={['vf-section-blank']}>
        {children}
      </div>
    )

  return (
    <ClickAwayListener
			mouseEvent={!deleteModalData.visible ? 'onClick' : false}
			onClickAway={() => {
        if(sectionOnEditView === section.id){
          handleSectionOnEditView(null);
        }
      }}
		>
      <div className={styles['vf-section-wrapper']}>
        <div ref={elRef} className={styles['vf-section']}>
          <div className={styles['section-header-wrapper']}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
            </IconButton>
            <div className={styles['section-header']}>
              {section?.title}
              <VFSectionV2Tooltip tooltip={section.tooltip} />
            </div>
            <EllipsisHeader
              section={section}
              setModalType={handleModalType}
              isAssertionLevelRiskSection={isAssertionLevelRiskSection}
              sectionType={sectionType}
              formTypeId={projectForm.formTypeId}
              isProjectFinalized={isProjectFinalized}
            />
          </div>
          <VFSectionV2Description description={section.description} />
          <Collapse in={isExpanded} collapsedSize={0}>
            {children}
          </Collapse>
        </div>
        <AddQuestion
          section={section}
          projectFormId={projectFormID}
          visible={modalType === ACTION_ELLIPSIS_TYPES.ADD_QUESTION}
          setVisible={() => { setModalType(null) }}
        />
        <AddRisk
          section={section}
          visible={modalType === ACTION_ELLIPSIS_TYPES.ADD_RISK}
          setVisible={() => { setModalType(null) }}
          formSectionId={section.id}
          formName={projectFormName}
          isEdit={false}
          showWorkPaperReference={false}
          onSuccessfulSave={() => updateFinancialStatementLevelRiskToggle()}
        />
        <AddRiskFactor
          section={section}
          formName={projectFormName}
          visible={modalType === ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR}
          setVisible={() => { setModalType(null) }}
          projectId={projectId}
          projectFormId={projectFormID}
          isEdit={false}
        />
        <DeficiencyModal
          section={section}
          visible={modalType === ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY}
          setVisible={() => { setModalType(null); updateMyPresence({ focusedId: null }); }}
          formName={projectFormName}
          units={units}
        />
      </div>
    </ClickAwayListener>
  )
}

export default React.memo(VFSectionV2)