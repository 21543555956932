export const RISK_SUMMARY = {
    EN: {
        TITLE: {
            PAGE: 'Risk Summary',
            CONTAINER: 'Summary of Identified Risks',
        },
        LINK: {
            EXPAND_ALL: 'Expand All',
            COLLAPSE_ALL: 'Collapse All',
        },
        BUTTON: {
            ADD_ANOTHER_RISK: 'Add Another Risk'
        },
        COLUMN: {
            FIELD: {
                ACTION: 'action',
                RISK_NAME: 'riskName',
                RISK_DESCRIPTION: 'riskDescription',
                RISK_ASSESSMENT_SUMMARY: 'riskAssessmentSummary',
                UNITS: 'units',
                WORKPAPER_REFERENCE_OR_FORM_NAME: 'workpaperReferenceOrFormName',
                COMMENT: 'comment',
            },
            HEADER: {
                ACTION: '',
                RISK_NAME: 'Risk Name',
                RISK_DESCRIPTION: 'Risk Description',
                RISK_ASSESSMENT_SUMMARY: 'Risk Assessment Summary',
                UNITS: 'Units',
                WORKPAPER_REFERENCE_OR_FORM_NAME: 'Workpaper Reference/ Form Name',
                COMMENT: 'Comment',
            }
        },
        ACTION_ELLIPSIS_OPTIONS: {
            VIEW_OR_EDIT: 'View/Edit',
            DELETE: 'Delete'
        },
        MODAL: {
            DELETE_MESSAGE: "Are you sure you want to delete this? It will permanently delete the data."
        },
        SUMMARY_TABLE: {
            RISK_LIKELIHOOD: 'Is the likelihood of the risk occuring more than remote?',
            RISK_MAGNITUDE: 'Is the magnitude of the risk more than material?',
            RISK_RELATION: 'Is the risk related to error or fraud?',
            FRAUD_RISK_TYPE: 'Select the type of fraud risk',
            CONDITIONS_DESCRIPTION: 'Describe the conditions indicating the error occurred',
            RISK_PERVASIVE: 'Is the risk pervasive?',
            ASSERTION_DESCRIPTION: 'Describe what could go wrong at the assertion level',
            ACCOUNTING_ESTIMATE: 'Does the risk relate to an accounting estimate?',
            AUDIT_AREA: 'Audit Area',
            SCOTABDS: 'SCOTABDs',
            ASSERTIONS: 'Assertions',
            ERROR: 'Error',
            FRAUD: 'Fraud',
            YES: 'Yes',
            NO: 'No',
        },
        COMMENT: {
            COMMENT: "Comments",
            INPUT_PLACEHOLDER: "Enter comment"
        },
        WARNING_MODAL: {
            TITLE: "Warning - Assertion level risk cannot be deleted",
            DESCRIPTION: "This assertion level risk cannot be deleted until the following key control(s) linked to it have been disassociated (i.e., linked to a different risk) or deleted in the Understanding the Entity's Information System and Communication and Control Activities form. Note that deleting a key control will delete any completed walkthrough documentation on this form.",
            TABLE: {
                AUDIT_AREAS: "Audit Area",
                SCOTABDS: "SCOTABDs",
                KEY_CONTROLS: "Key Controls"
            }
        },
        WARNING_MODAL_FS: {
            TITLE: "Warning - FS level risk cannot be deleted",
            DESCRIPTION: "This financial statement level risk cannot be deleted until the link to the following assertion level risks have been disassociated on the Risk Assessment Summary.",
        }
    }
}