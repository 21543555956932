import { createContext, useCallback, useState } from 'react';
import { useUpdateEffect, usePageLeave, useUnmount } from 'react-use';
import { isEqual } from 'lodash';
import { copyObject } from '@ais/forms';
import { useForm } from "react-hook-form"
import { Form } from "@ais/palette";
import { useIdle } from 'react-use';
import { WAITING_TIME } from '@ais/constants';
import { useProjectFormInstanceProvider } from '@providers';

export const VFCanvasContext = createContext({});

export const VFCanvasProvider = ({ schema, isInstance, answerList, onSaveAnswer, children }) => {
  const { isFormFocused } = useProjectFormInstanceProvider();
  const [concurrencyValue, setConcurrencyValue] = useState();
  const initialValues = useCallback(() => {
    if (isInstance) {
      let answerValues = {}
      for (const [key, value] of Object.entries(answerList)) {
        if (!concurrencyValue)
          answerValues[key.toLowerCase()] = value
        else {
          const {value: concValue, fieldId, fieldValidation} = concurrencyValue;
          if (key.toLowerCase() === fieldId) {
            if (fieldValidation === 'percentage' && concValue?.includes('%'))
              answerValues[key.toLowerCase()] = Number(concValue.replace('%', '')) / 100;
            else
              answerValues[key.toLowerCase()] = concValue;
          }
          else
            answerValues[key.toLowerCase()] = value
        }
      };
      return copyObject(answerValues);
    } else {
      const formValues = {}
      schema?.properties?.forEach((property) => {
        property.fields.forEach((field) => {
          field.forEach((innerField) => {
            formValues[innerField.id] =
              innerField.defaultValue || innerField.value;
          });
        });
      });
      return copyObject(formValues);
    }
  }, [answerList, schema, concurrencyValue])

  const formCanvas = useForm({ values: initialValues() });
  const isIdle = useIdle(WAITING_TIME.MINS_15);

  const getIdsWithUpdatedValues = (currentValues, updatedValues) => {
    return currentValues ? Object.keys(updatedValues).filter(
      (key) =>
        !currentValues.hasOwnProperty(key)
        || (currentValues.hasOwnProperty(key) && !isEqual(currentValues[key], updatedValues[key]))
    ) : Object.keys(updatedValues)
  }

  const onSubmit = useCallback((values, currentValues, fromPageLeave) => {
    if (!isInstance || (!isFormFocused && !fromPageLeave)) {
      return;
    }

    const idListWithNewValues = getIdsWithUpdatedValues(currentValues, values);

    if (!idListWithNewValues || idListWithNewValues.length === 0) {
      return;
    }
    idListWithNewValues.forEach((answerId) => {
      const updatedAnswer = values[answerId]
      if (updatedAnswer === null || updatedAnswer === undefined) {
        return
      }
      onSaveAnswer(answerId, values[answerId])
    });

    setConcurrencyValue(null)
  }, [isFormFocused]);

  usePageLeave(() => {formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues, true))()});
  useUnmount(() => formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues, true))())

  const handleConcurrencyUpdate = (value, fieldId, fieldValidation) => {
    if (value) {
      setConcurrencyValue({value, fieldId, fieldValidation})
    }
  };

  useUpdateEffect(() => {
    if (isIdle && isInstance) {
      const answerValues = initialValues();
      formCanvas.reset(answerValues);
    }
  }, [answerList, isIdle, isInstance])

  return (
    <VFCanvasContext.Provider
      value={{
        actions: {
          handleSubmit: formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues)),
          handleConcurrencyUpdate 
        },
      }}
    >
      <Form form={formCanvas}>
        {children}
      </Form>
    </VFCanvasContext.Provider>
  )
};