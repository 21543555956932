import { useEffect, useContext } from 'react'
import { CustomToastContext } from '@contexts/CustomToast/CustomToastContext';
import { useProjectFormInstanceProviderV2 } from '@providers';

export const useCustomToast = ({
    isLoading,
    isSuccess,
    isError,
}) => {
    const { setIsLoading, setIsSuccess, setIsError } = useContext(CustomToastContext)

    useEffect(() => {
        setIsLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        setIsSuccess(isSuccess)
    }, [isSuccess])

    useEffect(() => {
        setIsError(isError)
    }, [isError])
}

export const useCustomToastV2 = ({
    isLoading,
    isSuccess,
    isError,
}) => {
    const projectFormInstanceProvider = useProjectFormInstanceProviderV2();

    const handleState = (state, value) => {
        if (!projectFormInstanceProvider || !projectFormInstanceProvider.actions) return;
        const {actions: {onCustomFormSaving}} = projectFormInstanceProvider;
        if (onCustomFormSaving) onCustomFormSaving(state, value);
    }
    
    useEffect(() => {
        handleState('isLoading', isLoading);
    }, [isLoading])

    useEffect(() => {
        handleState('isSuccess', isSuccess);
    }, [isSuccess])

    useEffect(() => {
        handleState('isError', isError);
    }, [isError])
}