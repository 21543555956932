import { useState, useEffect, useRef} from 'react';
import { useFormContext } from "react-hook-form"
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

export const InstanceLongAnswer = (props) => {
  const { field, defaultValue, isIdle, onFocus, disabled} = props;
  const formCanvas = useFormContext();
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isIdle && containerRef.current?.classList.contains('Mui-focused')) { 
        containerRef.current?.classList.remove('Mui-focused');
        inputRef.current?.blur();
    }
  }, [isIdle])

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{field.label}</InputLabel>
      <OutlinedInput
        inputRef={inputRef}
        ref={containerRef}
        multiline
        minRows={1}
        maxRows={20}
        label={field.label}
        placeholder={field.placeholder}
        inputProps={{
          maxLength: 4096,
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => formCanvas.setValue(field.id, e.target.value)}
        onFocus={onFocus}
        notched
        disabled={disabled}
      />
    </FormControl>
  );
};
