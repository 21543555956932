
import { useMemo } from 'react';

import { useOthers } from "@components/Concurrency/store/users";
import {
  PLANNING_ANALYTICS_IDS
} from '@constants/forms';
const {
  CPP,
  GLC,
  TRENDING_BY_MONTH
} = PLANNING_ANALYTICS_IDS
export const useLocking = (fieldId, userId) => {
  const others = useOthers();
  const isLockedByUser = useMemo(() => {
    let lockedUserObject = {}; 
    switch (fieldId) {
      case GLC.LONGANSWER_ID:
        lockedUserObject = others.find((user) => GLC.RADIOGROUP_IDS.includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
        break;
      case CPP.LONGANSWER_ID:
        lockedUserObject = others.find((user) => CPP.RADIOGROUP_IDS.includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
        break;
      case TRENDING_BY_MONTH.LONGANSWER_ID:
        lockedUserObject = others.find((user) => TRENDING_BY_MONTH.RADIOGROUP_IDS.includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
        break;
      default:
        lockedUserObject = others.find((user) => user.presence.focusedId === fieldId && user.info.userId.toLowerCase() !== userId.toLowerCase());
        break;
    }

    return lockedUserObject
  }, [others, fieldId, userId]);
  
  return isLockedByUser;
};
