import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PlanningAnalyticsContext = createContext({});

export const PlanningAnalyticsProvider = ({ children }) => {
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [answerList, setAnswerList] = useState([]);
    const [comment, setComment] = useState({})  
    const [ isLoadCurrentPeriodSection, setIsLoadCurrentPeriodSection ] = useState(false);
    const [isGeneralLedgerExpanded, setIsGeneralLedgerExpanded] = useState(false);
    const [isCurrentPeriodExpanded, setIsCurrentPeriodExpanded] = useState(false);
    const [isTrendingByMonthExpanded, setIsTrendingByMonthExpanded] = useState(false);

    const handleIsGeneralLedgerExpanded = (isExpanded) => setIsGeneralLedgerExpanded(isExpanded);
    const handleIsCurrentPeriodExpanded = (isExpanded) => setIsCurrentPeriodExpanded(isExpanded);
    const handleIsTrendingByMonthExpanded = (isExpanded) => setIsTrendingByMonthExpanded(isExpanded);
    const handleIsLoadCurrentPeriodSection = (isExpanded) => setIsLoadCurrentPeriodSection(isExpanded);

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment,
            isLoadCurrentPeriodSection,
            isGeneralLedgerExpanded,
            isCurrentPeriodExpanded,
            isTrendingByMonthExpanded,
            actions: {
                updateIsGeneralLedgerExpanded: handleIsGeneralLedgerExpanded,
                updateIsCurrentPeriodExpanded: handleIsCurrentPeriodExpanded,
                updateIsTrendingByMonthExpanded: handleIsTrendingByMonthExpanded,
                updateIsLoadCurrentPeriodSection: handleIsLoadCurrentPeriodSection,
            }
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment,
            isLoadCurrentPeriodSection,
            isGeneralLedgerExpanded,
            isCurrentPeriodExpanded,
            isTrendingByMonthExpanded,
        ]
    )

    return (
        <PlanningAnalyticsContext.Provider value={memoized}>{children}</PlanningAnalyticsContext.Provider>
    )
}

PlanningAnalyticsProvider.propTypes = {
    children: PropTypes.node
}