export const richTextEditorTextContent = (htmlString) => {    
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    return dom.body.textContent;   
}

export const doubleLineBreaks = (content) => {

    const container = document.createElement('div');
    container.innerHTML = content;

    container.querySelectorAll('p').forEach((p) => {
        if (
            p.children.length === 1 &&
            p.children[0].nodeName === 'BR' &&
            !p.textContent.trim()
        ) {
            const parent = p.parentNode;
            const br1 = document.createElement('br');
            const br2 = document.createElement('br');
            parent.insertBefore(br2, p.nextSibling);
            parent.replaceChild(br1, p); 
        }
    });

    container.querySelectorAll('p').forEach((p) => {
        const nextSibling = p.nextSibling;
        if (nextSibling && nextSibling.nodeName === 'P') {
            const br = document.createElement('br');
            p.parentNode.insertBefore(br, nextSibling);
        }
    });

    const finalHTML = container.innerHTML;
    return finalHTML;
};