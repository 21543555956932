import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProjectFormInstanceV2 from "@views/ProjectFormInstance/v2/ProjectFormInstanceV2";

import { ProjectFormInstanceProviderV2 } from "@providers";
import loadable from "@loadable/component";
import { useGetProjectFormWithSchemaById, useGetAnswersByProjectFormId } from "@services/forms/projectforms";
import { useProject } from '@services/project';
import { FormProvider, useForm } from 'react-hook-form';
import { RoomProvider } from "@components/Concurrency/provider/RoomProvider";
import useUserProviderState from "@contexts/UserContext";
import { GraphContextProvider } from '@contexts/PlanningAnalytics/GraphContext';
import { PlanningAnalyticsProvider } from '@contexts/PlanningAnalytics/PlanningAnalyticsContext';
import { ProjectScopeProvider } from "@contexts/ProjectScope/ProjectScopeContext";
import { RiskAssessmentProvider } from "@contexts/RiskAssessment/RiskAssessmentContext"
import { ScopingFrameworkAndPerformanceStandardProvider } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext";
import { AccountAssertionLevelRiskProvider } from '@views/RiskAssesmentSummary';
import { InternalControlsContextProvider } from '@contexts/InternalControls/InternalControlsContext';
import { KeyControlDrawerProvider } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import { DeficiencySummaryProvider } from '@contexts/DeficiencySummary/DeficiencySummaryContext'

const UnauthorizedErrorPage = loadable(() => import("@views/UnauthorizedErrorPage/UnauthorizedErrorPage.jsx"))
const ProjectFormRenderer = () => {
    const { projectId, projectFormId } = useParams();
    const { data: projectForm, isError, isLoading, isFetching, isFetched } = useGetProjectFormWithSchemaById(projectFormId, projectId);
    const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId);
    const { data: projectData } = useProject(projectId);
    const [userId, setUserId] = useState('');
    const formHook = useForm();
    const { getUser } = useUserProviderState();

    useEffect(() => {
        async function initData() {
            const user = await getUser();
            const userLocalId = user?.activeDirectoryId ?? '';

            setUserId(userLocalId);
        }

        initData()
    }, [])

    if (isError) return <UnauthorizedErrorPage />
    if (isLoading && isFetching && !isFetched) return <></>

    return (
        <FormProvider {...formHook}>
            <ProjectFormInstanceProviderV2 projectData={projectData} projectFormData={projectForm} answers={answers} userId={userId}>
                <PlanningAnalyticsProvider>
                    <DeficiencySummaryProvider>
                        <InternalControlsContextProvider>
                            <ProjectScopeProvider>
                                <KeyControlDrawerProvider>
                                    <GraphContextProvider projectForm={projectForm}>
                                        <ScopingFrameworkAndPerformanceStandardProvider>
                                            <RiskAssessmentProvider>
                                                <AccountAssertionLevelRiskProvider>
                                                    <RoomProvider roomId={projectForm.projectFormID} userId={userId}>
                                                        <ProjectFormInstanceV2 />
                                                    </RoomProvider>
                                                </AccountAssertionLevelRiskProvider>
                                            </RiskAssessmentProvider>
                                        </ScopingFrameworkAndPerformanceStandardProvider>
                                    </GraphContextProvider>
                                </KeyControlDrawerProvider>
                            </ProjectScopeProvider>
                        </InternalControlsContextProvider>
                    </DeficiencySummaryProvider>
                </PlanningAnalyticsProvider>
            </ProjectFormInstanceProviderV2>
        </FormProvider>
    )
}

export default ProjectFormRenderer