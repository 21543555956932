import { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"

import { Grid } from '@mui/material';

import { Select, Box, Chip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import formServices from '@services/forms/forms';
import { useParams } from 'react-router-dom';
import { useGetAnswersByProjectFormId } from '@services/forms/projectforms.js';
import { sortReviewerSignoffAsc, getFormattedSignOffDate } from '@utilities/dateHelpers.js';
import clsx from 'clsx';
import styles from '@views/ProjectScopingForm/CLAInitialScopingConsiderations/CLAInitialScopingConsiderations.module.css'
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';
import { useDisabledGlobalQueryLoading } from '@hooks/index';

const menuItemStyle = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
};

const CLAInitialScopingConsiderations = (props) => {
    useDisabledGlobalQueryLoading();
    const { projectFormId, handCaptureInput, fieldId, disabled, onOpen, onClose, signOffList, answerList } = props;
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const formCanvas = useFormContext();


    const [projectScopeId, setProjectScopeId] = useState();
    const [selectOpen, setSelectOpen] = useState(false);
    const [internalControlOptions] = useState([{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]);
    const [isInternaControlHighlighted, setIsInternaControlHighlighted] = useState(false)
    const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)

    const isIdle = useRoomIdle();

    const displayEmpty = true;
    const placeholder = 'Select';



    const retrieveProjectScopeInformation = async () => {
        const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
        setProjectScopeId(projectScopeInfo?.data?.ProjectScopeId);
    };

    const handleInternalControlChange = (event) => {
        handCaptureInput(event.target.value);
    }

    useEffect(() => {
        if (!projectScopeId) {
            retrieveProjectScopeInformation();
        }
    }, []);

    useEffect(() => {
        if (isIdle) {
            setSelectOpen(false)
        }
    }, [isIdle]);

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        let matchingAnswer = {}
        if (answers?.length) {
            matchingAnswer = answers.find((answer) => answer.questionId.toLowerCase() === fieldId.toLowerCase())
        }
        if (matchingAnswer?.lastUpdate) {
            const dateModified = new Date(matchingAnswer.lastUpdate).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            shouldHighlight = new Date(getFormattedSignOffDate(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate)).getTime() < dateModified
            setIsInternaControlHighlighted(isSignoffEnabled && shouldHighlight)
        }
    }, [answers, signOffList])

    return (
        <Box className={clsx(isInternaControlHighlighted && styles.DropdownSelectContainerHighlighted)}>
            <FormControl fullWidth>
                <InputLabel id={fieldId} shrink sx={{ backgroundColor: 'white' }}>Will the audit team test the operating effectiveness of internal controls in the current year or rely on evidence obtained from prior year tests of controls?</InputLabel>
                <Select
                    labelId={fieldId}
                    id="internalControlsDropdown"
                    value={formCanvas.getValues(fieldId) ?? ''}
                    label="Will the audit team test the operating effectiveness of internal controls in the current year or rely on evidence obtained from prior year tests of controls?"
                    onChange={handleInternalControlChange}
                    displayEmpty={displayEmpty}
                    disabled={disabled}
                    open={selectOpen}
                    onOpen={() => {
                        setSelectOpen(true);
                        onOpen();
                    }}
                    onClose={() => {
                        setSelectOpen(false);
                        onClose();
                    }}
                >
                    {displayEmpty && (
                        <MenuItem disabled={true} value="" sx={menuItemStyle}>
                            {placeholder}
                        </MenuItem>
                    )}
                    {internalControlOptions?.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option?.hasOwnProperty('value') ? option.value : option}
                            sx={menuItemStyle}
                        >
                            {option?.hasOwnProperty('label') ? option.label : option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default CLAInitialScopingConsiderations;
