import React from 'react';
import { useParams } from "react-router-dom";
import { FIELD_TYPES } from "@ais/constants";
import styles from '@components/FormView/FormView.module.css';
import CLAEvaluationOfDeficiencies from '@views/DeficiencySummaryForm/Deficiency/EvaluationOfDeficiencies/CLAEvaluationOfDeficiencies';
import CLACommunicationOfDeficiencies from "@views/DeficiencySummaryForm/Deficiency/CommunicationOfDeficiencies/CLACommunicationOfDeficiencies/";
import { useProjectFormInstanceProviderV2 } from '@providers';

export const DeficiencyFieldSelector = ({ field }) => {
    const { type } = field;

    const {projectId } = useParams();

    const {units, userId} = useProjectFormInstanceProviderV2();

    switch (type) {
        case FIELD_TYPES.EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: {
          return (
            <div className={styles.field__wrapper}>
                <div className={styles.field__wrapper_inner}>
                    <CLAEvaluationOfDeficiencies
                        projectId={projectId}
                        unitIds={units?.map(item => item.ProjectUnitID)}
                        signOffList={[]}//THIS IS A GAP={signOffList}
                        userId={userId}
                    />
                </div>
            </div>
        )
        }
        case FIELD_TYPES.COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLACommunicationOfDeficiencies
                            signOffList={[]}//THIS IS A GAP={signOffList}
                        />
                    </div>
            </div>
            )
        }
        default:
          return <></>;
      }

};
