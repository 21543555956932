import { useEffect, useState, memo } from "react"
import { styled } from "@mui/system"
import {
    Box,
    TextField,
    IconButton
} from "@mui/material"
import Chart from "react-apexcharts"
import { useParams } from "react-router-dom";
import { useGeneralLedgerSummary } from "@services/customForm/trendingByMonth"
import { GRAPHS_DRAWER } from "@constants/index"
import { Comment, CommentResolved } from '@ais/assets';
import projectFormCommentService from "@services/forms/projectFormComments";
import { useFetchTrialBalanceGeneralLedgers } from "@services/customForm/generalLedger.js";
import flow from "lodash/fp/flow"
import filter from "lodash/fp/filter"
import sortBy from "lodash/sortBy"
import Skeleton from '@mui/material/Skeleton';
import { isEqual } from 'lodash';
import CustomToast from '@components/CustomToast/CustomToast';
import { usePlanningAnalytics } from "../../hooks/usePlanningAnalytics";
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { useOthers } from "@components/Concurrency/store/users";
import styles from '@components/FormView/FormView.module.css';
import { useRoomIdle } from '@components/Concurrency/provider/RoomProvider';
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
const Flex = styled("div")`
    display: flex;
    flex-direction: row;
`

const arePropsEqual = (oldProps, newProps) => {
    const newDisabled = newProps.disabled
    const oldDisabled = oldProps.disabled
    const newTrialBalanceIds = newProps.graph.trialBalanceId
    const oldTrialBalanceIds = oldProps.graph.trialBalanceId
    const newProjectFormId = newProps.projectFormId
    const oldProjectFormId = oldProps.projectFormId
    const newProjectPeriodEndDate = newProps.projectPeriodEndDate
    const oldProjectPeriodEndDate = oldProps.projectPeriodEndDate
    const newSectionId = newProps.sectionId
    const oldSectionId = oldProps.sectionId
    const newUserId = newProps.userId
    const oldUserId = oldProps.userId
    const newSelectedTb = newProps.selectedTrialBalances
    const oldSelectedTb = oldProps.selectedTrialBalances
    const newSelectedPriorPeriods = newProps.selectedPriorPeriods
    const oldSelectedPriorPeriods = oldProps.selectedPriorPeriods
    const newComments = newProps.comments
    const oldComments = oldProps.comments

    return (
        newDisabled === oldDisabled &&
        isEqual(newTrialBalanceIds, oldTrialBalanceIds) &&
        newProjectFormId === oldProjectFormId &&
        newProjectPeriodEndDate === oldProjectPeriodEndDate &&
        newSectionId === oldSectionId &&
        newUserId === oldUserId &&
        isEqual(newSelectedTb, oldSelectedTb) &&
        isEqual(newSelectedPriorPeriods, oldSelectedPriorPeriods) &&
        isEqual(newComments, oldComments)
    )
}

export const LineChart = memo((props) => {
    const { graph,
        selectedTrialBalances,
        selectedPriorPeriods,
        projectPeriodEndDate,
        comments,
        projectFormId,
        sectionId,
        userId,
        getTrialBalanceValues,
        disabled,
        handleUnlock,
        handleLock,
        fieldId,
        isLocked
    } = props

    const { MONTHS,
        COMMENT,
        COMMENT_PLACEHOLDER,
        DEFAULT_INCOME,
        DEFAULT_INCOME_GROUPING_ID
    } = GRAPHS_DRAWER.EN
    const { projectId } = useParams()
    const [loading, setLoading] = useState(true);
    const [openCommentBox, setOpenCommentBox] = useState(false);
    const [comment, setComment] = useState("");
    const [commentId, setCommentId] = useState(null);
    const [correlationDetailIds, setCorrelationDetailIds] = useState([]);
    const [generalLedgerIds, setGeneralLedgerIds] = useState([]);
    const [successComment, setSuccessComment] = useState(false)
    const [errorComment, setErrorComment] = useState(false)
    const [loadingComment, setLoadingComment] = useState(false)

    const generalLedgerQrys = useFetchTrialBalanceGeneralLedgers(correlationDetailIds, projectId);
    const { data: summary, isLoading: isSummaryLoading } = useGeneralLedgerSummary(generalLedgerIds, correlationDetailIds);
    const others = useOthers();
    const isLockedByUser = others.find((user) => user.presence.focusedId === fieldId && user.info.userId.toLowerCase() !== userId.toLowerCase())
    const {
        concurrencyEventReceived,
        comment: concurrencyComment
    } = usePlanningAnalytics()
    const { setRefetchComments } = useGraphContext()
    const isIdle = useRoomIdle();


    const [chartSettings, setChartSettings] = useState({
        series: [],
        options: {
            colors: selectedPriorPeriods?.map((priorPeriod) => priorPeriod.color),
            chart: {
                type: "line",
                animations: {
                    enabled: false
                },
                stacked: false,
                toolbar: {
                    autoSelected: "zoom",
                    show: false
                },
                background: "#fff",
                width: "100%"
            },
            annotations: {
                points: []
            },
            fill: {
                type: "solid",
                opacity: 1
            },
            dataLabels: {
                enabled: false
            },
            title: {
                align: "center",
                // TODO: Might need to update after implementing null handling for Planning Analytics in another US
                text: graph.name === 'placeholder' ? '' : graph.name
            },
            xaxis: {
                categories: MONTHS
            },
            stroke: {
                curve: "straight",
                width: 3
            },
            legend: {
                show: false
            },
            tooltip: {
                shared: true,
                x: {}
            }
        }
    })

    const getGraphData = (summary, priorPeriod, categoryName, subGroupingName) => {
        if (!summary) return []
        return flow(
            filter((agg) => agg.periodName === priorPeriod && agg.groupingName === categoryName && agg.subGroupingName === subGroupingName),
        )(summary);
    }

    const handleCommentBlur = async () => {
        const trialBalances = getTrialBalanceValues();
        if (!trialBalances)
            return;

        const trialBalance = trialBalances.find(trialBalance => graph?.trialBalanceId.includes(trialBalance?.value));
        if (!trialBalance && graph.trialBalanceId !== DEFAULT_INCOME) return;
        const commentPath = {
            trialBalances: graph.groupingId === DEFAULT_INCOME_GROUPING_ID ? { trialBalanceId: DEFAULT_INCOME, trialBalanceName: "", }
                : trialBalances.map((item) => ({
                    trialBalanceId: item.correlationDetailId,
                    trialBalanceName: item.label,
                })),
            [graph.grouping]: graph.name
        }
        if (!commentId) {
            try {
                setLoadingComment(true)
                const result = await projectFormCommentService.createProjectFormComments({
                    ProjectFormId: projectFormId,
                    FormParentAttributeId: sectionId,
                    Comment: comment,
                    CommentPath: JSON.stringify(commentPath),
                    ModifiedUser: userId
                }, projectId);
                if (result.status !== 201) {
                    setSuccessComment(false)
                    setErrorComment(true)
                    setLoadingComment(false)
                } else {
                    setCommentId(result.data.ProjectFormCommentId);
                    setSuccessComment(true)
                    setErrorComment(false)
                    setLoadingComment(false)
                }
            } catch (error) {
                setSuccessComment(false)
                setErrorComment(true)
            } finally {
                setLoadingComment(false)
            }
        } else {
            try {
                setLoadingComment(true)
                const result = await projectFormCommentService.updateProjectFormComments({
                    ProjectFormCommentId: commentId,
                    Comment: comment,
                    CommentPath: JSON.stringify(commentPath),
                    ModifiedUser: userId,
                    ProjectFormId: projectFormId
                }, projectId);

                if (result.status !== 204) {
                    setSuccessComment(false)
                    setErrorComment(true)
                    setLoadingComment(false)
                } else {
                    setSuccessComment(true)
                    setErrorComment(false)
                    setLoadingComment(false)
                }

            } catch (error) {
                setSuccessComment(false)
                setErrorComment(true)
            } finally {
                setLoadingComment(false)
            }
        }

        handleUnlock && handleUnlock()
    }

    const handleCommentChange = (comments) => {
        if (comments?.length) {
            const trialBalances = getTrialBalanceValues();
            if (!trialBalances)
                return;

            const trialBalance = trialBalances.find(trialBalance => graph?.trialBalanceId.includes(trialBalance?.value));
            const trialBalanceId = trialBalance?.correlationDetailId ?? graph.trialBalanceId;
            const comment = comments.find(comment => {
                const CommentPath = comment?.CommentPath
                const FormParentAttributeId = comment?.FormParentAttributeId

                const _commentPath = JSON.parse(CommentPath ?? "[]");
                if (_commentPath[graph.grouping] &&
                    _commentPath[graph.grouping] === graph.name &&
                    (Array.isArray(_commentPath?.trialBalances) ? _commentPath?.trialBalances.map(x => x.trialBalanceId).includes(trialBalanceId)
                        : _commentPath?.trialBalances.trialBalanceId === trialBalanceId) &&
                    FormParentAttributeId.toLowerCase() === sectionId?.toLowerCase()) {
                    return comment;
                }
            })

            if (graph.groupingId === DEFAULT_INCOME_GROUPING_ID && Array.isArray(comments)) {
                setComment(comments[0]?.Comment ?? "");
                setCommentId(comments[0]?.ProjectFormCommentId ?? null);
            } else {
                setComment(comment?.Comment ?? "");
                setCommentId(comment?.ProjectFormCommentId ?? null);
            }
        }
    } 
    
    useEffect(() => {
        if (isIdle) { 
            handleCommentChange(comments)
        }
    }, [isIdle])

    useEffect(() => {
        handleCommentChange(comments)
    }, [comments])

    useEffect(() => {
        setCorrelationDetailIds(selectedTrialBalances);
    }, [selectedTrialBalances]);

    useEffect(() => {
        if (!generalLedgerQrys) return
        if (generalLedgerQrys.some(({ isFetching }) => isFetching) ||
            (!loading && generalLedgerIds.length !== generalLedgerQrys.length) ||
            !loading
        ) return

        const generalLedgerQrysData = generalLedgerQrys.map(tb => tb.data.id)
        setGeneralLedgerIds(generalLedgerQrysData);
    }, [JSON.stringify(generalLedgerQrys)])

    useEffect(() => {
        const endMonth = new Date(projectPeriodEndDate).getUTCMonth() + 1
        setChartSettings((prevChartSettings) => ({
            ...prevChartSettings,
            options: {
                ...prevChartSettings.options,
                colors: selectedPriorPeriods?.map((priorPeriod) => priorPeriod.color),
                xaxis: {
                    categories: [...MONTHS.slice(endMonth), ...MONTHS.slice(0, endMonth)]
                }
            }
        }))
    }, [selectedPriorPeriods, projectPeriodEndDate])

    useEffect(() => {
        if (isSummaryLoading && graph.trialBalanceId !== DEFAULT_INCOME) return;
        const localSeries = [];
        selectedPriorPeriods?.map((spp) => {
            const localMatrix = [];
            for (let x = 0; x < 12; x++) {
                localMatrix[x] = 0;
            }
            const groupingId = graph?.generalLedgerId === '-1' ? 'D. Income' : graph?.groupingId;
            const filteredData = getGraphData(summary, spp.code, graph?.grouping, groupingId)
            filteredData.forEach((graphData) => {
                const hasTransactionPeriod = graphData?.transactionPeriod;
                if (graphData?.transactionPeriod === null || graphData?.transactionPeriod.monthEndTotal === null) return
                if (hasTransactionPeriod) {
                    const month = Number?.parseInt(graphData?.transactionPeriod) - 1;
                    localMatrix[month] = Math.round(graphData.monthEndTotal)
                }
            })
            const endMonth = new Date(projectPeriodEndDate).getUTCMonth() + 1
            let localMatrixValues = Object.values(localMatrix)
            localMatrixValues = [...localMatrixValues.slice(endMonth), ...localMatrixValues.slice(0, endMonth)]
            localSeries.push({ name: spp.label, data: localMatrixValues })
        });
        setChartSettings((prevChartSettings) => ({
            ...prevChartSettings,
            series: sortBy(localSeries, 'name')
        }));
        setLoading(false)
    }, [isSummaryLoading, selectedPriorPeriods])

    useEffect(() => {
        if (comments?.length && concurrencyEventReceived && !isIdle) {
            const trialBalances = getTrialBalanceValues();
            if (!trialBalances)
                return;

            const trialBalance = trialBalances.find(trialBalance => graph?.trialBalanceId.includes(trialBalance?.value));
            const trialBalanceId = trialBalance?.correlationDetailId ?? graph.trialBalanceId;
            const _commentPath = JSON.parse(concurrencyComment?.CommentPath ?? "{}");
            let key = 'Type'
            if ('Classification' in _commentPath) key = 'Classification';
            if ('Category' in _commentPath) key = 'Category';
            if (_commentPath[key] === graph.name) {
                setComment(concurrencyComment?.Comment ?? "");
                setCommentId(concurrencyComment?.ProjectFormCommentId ?? null);
            } else if (_commentPath[key] === graph.name && _commentPath?.trialBalances.map(x => x.trialBalanceId).includes(trialBalanceId)) {
                setComment(concurrencyComment?.Comment ?? "");
                setCommentId(concurrencyComment?.ProjectFormCommentId ?? null);
            }
            setRefetchComments(true)
        }
    }, [comments, concurrencyEventReceived, concurrencyComment, isIdle])

    return (
        <Box>
            {loading && <Skeleton animation="wave" variant="rectangular" sx={{ width: "100%", height: "700px" }} />}
            {!loading &&
                <>
                    <Chart
                        type="line"
                        options={chartSettings?.options}
                        series={chartSettings?.series}
                        width="100%"
                    />
                    <Flex sx={{ justifyContent: "end", alignItems: "end", pr: "20px", pl: "20px", flexDirection: "column" }}>
                        <IconButton
                            sx={{ padding: "0 0 10px 0" }}
                            onClick={() => setOpenCommentBox((prevOpenCommentBox) => !prevOpenCommentBox)}
                        >
                            {comment ? <CommentResolved /> : <Comment />}
                        </IconButton>
                        {openCommentBox &&
                            <div style={{ width: '100%' }}>
                                <VFRenderedFieldWrapper
                                    className={styles.field__wrapper_noalign}
                                    isLockedByUser={isLockedByUser}
                                    isLocked={isLocked}
                                >
                                    <TextField
                                        label={COMMENT}
                                        fullWidth
                                        multiline
                                        placeholder={COMMENT_PLACEHOLDER}
                                        rows={2}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        onBlur={handleCommentBlur}
                                        onFocus={handleLock}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            notched: true
                                        }}
                                        inputProps={{
                                            maxLength: 4096
                                        }}
                                        disabled={disabled}
                                        error={errorComment}
                                    />
                                </VFRenderedFieldWrapper>
                            </div>
                        }
                    </Flex>
                </>
            }
            <CustomToast
                error={errorComment}
                success={successComment}
                loading={loadingComment}
            />
        </Box>
    )
}, arePropsEqual)