import React from 'react';
import PropTypes from "prop-types";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';


import CLARiskCollapsible from './CLARiskCollapsible';

import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import { boxColumnStyles, boxHeaderColStyles, boxHeaderRowRiskStyles } from './muiStyles';

const CLARisks = (props) => {
    const { ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const {
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        projectRisksAuditArea,
        financialRisks,
        scotabd,
        disabled,
        isExpanded,
        userId
    } = props;

    return (
        <Box sx={{ ...boxColumnStyles, ml: '30px', mb: '20px', fontSize: '14px' }}>
            <TableContainer >
                <Table data-test="tableContainer">
                    <TableHead data-test="tableHeader" sx={boxHeaderRowRiskStyles}>
                        <TableRow>
                            <TableCell sx={boxHeaderColStyles} data-test="identified-risk-header" colSpan={2}>{CONSTANT_TEXT.IDENTIFIED_RISK}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-factor-header">{CONSTANT_TEXT.INHERENT_RISK_FACTOR}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="reference-walkthrough-header">{CONSTANT_TEXT.REFERENCE_FOR_WALKTHROUGH}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-assessment-header">{CONSTANT_TEXT.INHERENT_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="test-controls-header">{CONSTANT_TEXT.REFERENCE_FOR_TEST_OF_CONTROLS_IF_PERFORMED}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="control-risk-assesment-header">{CONSTANT_TEXT.CONTROL_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="risk-material-header">{CONSTANT_TEXT.RISK_OF_MATERIAL_MISSTATEMENT}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-test="tableBody" className='risk-table-body'>
                        {projectRisksAuditArea?.riskInfo?.map((risk, index) =>
                            <CLARiskCollapsible
                                key={index}
                                inherentRiskOptions={inherentRiskOptions}
                                controlRiskOptions={controlRiskOptions}
                                isInternalControlEffectiveness={isInternalControlEffectiveness}
                                risk={risk}
                                riskFactorInfo={projectRisksAuditArea?.riskFactorInfo}
                                index={index}
                                financialRisks={financialRisks}
                                scotabd={scotabd}
                                disabled={disabled}
                                isExpanded={isExpanded}
                                userId={userId}
                            />)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

CLARisks.propTypes = {
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    isInternalControlEffectiveness: PropTypes.bool,
    projectRisksAuditArea: PropTypes.object,
    financialRisks: PropTypes.array,
    disabled: PropTypes.bool,
}

export default CLARisks;