export const PROJECT_SCOPING_FORM = {
    EN: {
        TITLE: 'Project Scoping Form',
        PERFORMANCE_STANDARDS_DROPDOWN: {
            LABEL: 'What performance standards apply to this project?',
            TOOLTIP: 'Removal of previously selected performance standards may result in changes to audit program steps (i.e., deletions to available steps in the Tailor Procedures drawer and/or deletion of steps tailored on the audit program form).'
        },
        FRAMEWORKS_DROPDOWN: {
            LABEL: 'What financial reporting frameworks apply to this project?',
            TOOLTIP: 'Removal of previously selected financial reporting frameworks may result in changes to audit program steps (i.e., deletions to available steps in the Tailor Procedures drawer and/or deletion of steps tailored on the audit program form).'
        }
    }
}