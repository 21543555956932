import { createContext, useState } from 'react';


export const ProjectFormInstanceContext = createContext({});

export const ProjectFormInstanceProvider = ({ userId, children }) => {

  const [formTypeId, setFormTypeId] = useState(null);
  const [unitIds, setUnitIds] = useState([]);
  const [isFormFocused, setIsFormFocused] = useState(false);

  const [financialStatementTableRows, setFinancialStatementTableRows] = useState([]);
  const [isTbLinkDrawerOpened, setIsTbLinkDrawerOpened] = useState(false);
  const [isInitialScopingConsiderationExpanded, setIsInitialScopingConsiderationExpanded] = useState(false);
  const [isDeterminingSCOTABDSExpanded, setIsDeterminingSCOTABDSExpanded] = useState(false);
  const [isCommonIdentifiedRisksExpanded, setIsCommonIdentifiedRisksExpanded] = useState(false);
  const [isNonCustomSectionExpanded, setIsNonCustomSectionExpanded] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [unitAnswerList, setUnitAnswerList] = useState([]);
  const [modifiedAfterSignOff, setModifiedAfterSignOff] = useState([]);

  const handleUpdateUnitIds = (unitIds) => {
    setUnitIds(unitIds ?? []);
  };

  const handleUpdateIsFormFocused = (isFormFocused) => {
    setIsFormFocused(isFormFocused);
  };

  const handleIsInitialScopingConsiderationExpanded = (isExpanded) => {
    setIsInitialScopingConsiderationExpanded(isExpanded)
  };
  const handleIsDeterminingSCOTABDSExpanded = (isExpanded) => {
    setIsDeterminingSCOTABDSExpanded(isExpanded)
  };
  const handleIsCommonIdentifiedRisksExpanded = (isExpanded) => {
    setIsCommonIdentifiedRisksExpanded(isExpanded)
  };

  const handleIsNonCustomSectionExpanded = (section, isExpanded) => {
    setIsNonCustomSectionExpanded(prevState => {
      const i = prevState.findIndex(item => item.section === section)
      if(i !== -1){
        return [
          ...prevState.slice(0,i),
          { ...prevState[i], isExpanded },
          ...prevState.slice(i+1)
        ]
      }
      else return [...prevState, { section, isExpanded }]
    })
  }

  const handleIsTbLinkDrawerOpened = (value) => {
    setIsTbLinkDrawerOpened(value);
  }

  return (
    <ProjectFormInstanceContext.Provider
      value={{
        unitIds,
        isFormFocused,
        answerList,
        unitAnswerList,
        modifiedAfterSignOff,
        formTypeId,
        financialStatementTableRows,
        isInitialScopingConsiderationExpanded,
        isDeterminingSCOTABDSExpanded,
        isCommonIdentifiedRisksExpanded,
        isNonCustomSectionExpanded,
        isTbLinkDrawerOpened,
        userId,
        actions: {
          updateUnitIds: handleUpdateUnitIds,
          updateIsFormFocused: handleUpdateIsFormFocused,
          updateIsInitialScopingConsiderationExpanded: handleIsInitialScopingConsiderationExpanded, 
          updateIsDeterminingSCOTABDSExpanded: handleIsDeterminingSCOTABDSExpanded, 
          updateIsCommonIdentifiedRisksExpanded: handleIsCommonIdentifiedRisksExpanded,
          updateIsTbLinkDrawerOpened: handleIsTbLinkDrawerOpened,
          updateIsNonCustomSectionExpanded: handleIsNonCustomSectionExpanded,
        },
        setStates: {
          setAnswerList,
          setUnitAnswerList,
          setModifiedAfterSignOff,
          setFormTypeId,
          setFinancialStatementTableRows,
        }
      }}
    >
      {children}
    </ProjectFormInstanceContext.Provider>
  )
};