import formApi from '@utilities/claApiForm';
import {useQuery, useMutation} from "@tanstack/react-query"

export const createMethodologyVersion = async (methodologyVersion) => {
  try {
    const response = await formApi.post(
      '/methodologyversion',
      methodologyVersion
    );
    return { status: 200, data: response };
  } catch (error) {
    return { status: 400, message: error };
  }
};

export const deleteMethodologyVersion = async (id) => {
  try {
    await formApi.delete(`/methodologyversion/${id}`);
    return { status: 200 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

export const updateMethodologyVersion = async (id, payload) => {
  try {
    await formApi.put(`/methodologyversion/${id}`, payload);
    return { status: 200 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

export const getAllMethodologyVersions = async (sortBy, sortOrder, searchBy, searchText) => {
  const params = { sortby: sortBy, sortorder: sortOrder, searchby: searchBy, searchtext: searchText };
  const response = await formApi.get('/methodologyversion', { params });
  const methodologyversions = response.data.map(forms => {
    const { MethodologyVersionID, MethodologyVersion, MethodologyVersionLabel, FormsCount, FormApprovedCount, Description, ReleaseNote,
      IsMinorRelease, MethodologyVersionStatus, IsProcedureLibraryApproved, MethodologyVersionReportId } = forms;
    return {
      id: MethodologyVersionID,
      methodologyVersion: MethodologyVersion,
      methodologyVersionLabel: MethodologyVersionLabel,
      formsCount: FormsCount,
      formApprovedCount: FormApprovedCount,
      description: Description,
      releaseNote: ReleaseNote,
      isminorRelease: IsMinorRelease,
      methodologyVersionStatus: MethodologyVersionStatus,
      isProcedureLibraryApproved: IsProcedureLibraryApproved,
      methodologyVersionReportId: MethodologyVersionReportId
    }
  });

  try {
    return { status: 200, data: methodologyversions }
  }
  catch (error) {
    return { status: 400, message: error }
  }
}

export const getLatestMethodologyVersion = async () => {
  try {
    const response = await formApi.get('/methodologyversion/latest');
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const updateProjectMethodologyVersion = async (methodologyVersionId, projectId) => {
  try {
    const body = { MethodologyVersionId: methodologyVersionId, ProjectId: projectId };
    await formApi.put(`/methodologyversion/updateprojectmethodologyversion/${projectId}`, body, { timeout: 9000000 });
    return { status: 204 }
  } catch (exception) {
    return { status: 400, message: exception }
  }
};

const removeNewMethodologyProjectInvalidAuditAreasAndSCOTABD = async (projectId, invalidAuditAreaSCOTABDs) => {
  try {
    await formApi.post(`/methodologyversion/removeinvaliditems/${projectId}`, invalidAuditAreaSCOTABDs, { timeout: 9000000 });
    return { status: 204 }
  } catch (exception) {
    return { status: 400, message: exception }
  }
}

export const useUpdateProjectMethodologyVersion = () => {
  return useMutation(async ({methodologyVersionId, projectId}) => await updateProjectMethodologyVersion(methodologyVersionId, projectId))
}

const getUpdateMethodologyGetInvalidAuditAreaSCOTABD = async (projectId, newMethodologyVersionId) => {
  try {
    const { data } = await formApi.get(`/methodologyversion/invalidauditareascotabd/${projectId}/${newMethodologyVersionId}`, { timeout: 9000000 });
    return data;
  } catch (exception) {
    return { status: 400, message: exception }
  }
}

export const useGetUpdateMethodologyGetInvalidAuditAreaSCOTABD = (projectId, newMethodologyVersionId) => {
  return useQuery(
    ["MethodologyVersionInvalidAuditAreaSCOTABD", projectId, newMethodologyVersionId],
    () => getUpdateMethodologyGetInvalidAuditAreaSCOTABD(projectId, newMethodologyVersionId),
    {
        enabled: !!projectId && !!newMethodologyVersionId
    }
  )
}

export const useRemoveNewMethodologyProjectInvalidAuditAreasAndSCOTABD = (projectId) => {
  return useMutation(async (invalidAuditAreaSCOTABDs) => removeNewMethodologyProjectInvalidAuditAreasAndSCOTABD(projectId, invalidAuditAreaSCOTABDs))
}

const methodologyVersionService = {
  createMethodologyVersion,
  updateMethodologyVersion,
  deleteMethodologyVersion,
  getAllMethodologyVersions,
  getLatestMethodologyVersion,
};

export default methodologyVersionService;
