import { useState, useEffect } from 'react'
import {
    Box
} from '@mui/material';

import CLACheckBoxField from '@components/Forms/CLACheckBoxField/CLACheckBoxField';
import styles from "../CLACommunicationOfDeficiencies.module.css";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';

import { useCommunicationStore } from '../communication';
import { DeficiencyLabelTypography } from './helpers';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProjectNoId } from '@hooks/useProject';

const CommunicationOfDeficienciesSubRow = (props) => {
    const {row, onAutoSave, disabled } = props;
    const isProjectFinalized = useFinalizedProjectNoId();

    const { COMMUNICATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const [management, setManagement] = useState(row?.IsCommunicatedManagement);
    const [shouldHighlightRow, setShouldHighlightRow] = useState(false);
    
    useEffect(()=>{
        setManagement(row?.IsCommunicatedManagement)
    }, [row?.IsCommunicatedManagement])

    const handleChange = (field, value) => {
        if (onAutoSave) {
            onAutoSave(row, field, value);
        }
    }
    
    const latestSignoffTime = useCommunicationStore(state => state.latestSignoffTime);

    useEffect(() => {
        if(!row.IsCommunicatedGovernance){
            handleChange("IsCommunicatedGovernance", true)
        }
    }, []) 

    useEffect(() => {
        const dateModified = new Date(row.ValidFrom).getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        if ('ProjectDeficiencyId' in row) {
            setShouldHighlightRow(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
        } else if ('ProjectDeficiencyCombinationId' in row) {
            setShouldHighlightRow(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
        }
    }, [latestSignoffTime, row])

    return (
        <>
            <Box sx={{ p: `20px 65px 10px 65px` }}>
                <div>
                    <div className={styles['cod-header-row']}>
                        <div className={styles['cod-header-cell']}>
                            {COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_TO}
                        </div>
                        <div className={styles['cod-header-cell']}>
                            {COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_BY}
                        </div>
                    </div>
                    <div className={styles['cod-body-row']} style={shouldHighlightRow ? {border: '4px dashed red'} : null}>
                        <div>
                            <CLACheckBoxField
                                label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.GOVERNANCE} />}
                                checked={true}
                                name="governance"
                                onChange={() => { }}
                                disabled={true}
                            />
                            <CLACheckBoxField
                                label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT} />}
                                checked={management}
                                name="management"
                                onChange={(e) => {
                                    handleChange('IsCommunicatedManagement', e.target.checked)
                                    setManagement(!management);
                                }}
                                disabled={disabled}
                            />
                        </div>
                        <div className={`${styles['cod-body-cell']} ${styles['cod-written-cell']}`}>
                            <DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.WRITTEN} />
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default CommunicationOfDeficienciesSubRow;