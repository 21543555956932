import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useWindowScroll } from 'react-use';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';

import { successIcon } from "@ais/assets";
import { CUSTOM_TOAST } from '@ais/constants';
import "./CustomToast.css";

export const toastConfigs = (type) => {
    let toastClassName;
    let toastStyles = {};
    let toastProperties = {};

    if (type === CUSTOM_TOAST.TYPES.ERROR) {
        toastClassName = CUSTOM_TOAST.CLASSES.ERROR;
        toastStyles = CUSTOM_TOAST.STYLES.ERROR;
        toastProperties = {
            autoClose: 3000,
            hideProgressBar: true,
        }
    } else if (type === CUSTOM_TOAST.TYPES.SUCCESS) {
        toastClassName = CUSTOM_TOAST.CLASSES.SUCCESS;
        toastStyles = CUSTOM_TOAST.STYLES.SUCCESS;
        toastProperties = {
            hideProgressBar: true,
        }
    } else if (type === CUSTOM_TOAST.TYPES.LOADING) {
        toastClassName = CUSTOM_TOAST.CLASSES.LOADING;
        toastStyles = CUSTOM_TOAST.STYLES.LOADING;
    }

    return {
        autoClose: false,
        icon: false,
        closeButton: false,
        width: 300,
        className: toastClassName,
        style: {
            ...toastStyles
        },
        ...toastProperties,
    }
}

export const Toast = (props) => {
    const { type, text, icon } = props;
    const { y: scrollPosition } = useWindowScroll();

    let toastClassName = "";
    let toastIcon = <React.Fragment />;

    if (type === CUSTOM_TOAST.TYPES.ERROR) {
        toastClassName = CUSTOM_TOAST.CLASSES.WRAPPER_ERROR;
        toastIcon = <HighlightOffIcon />;
    } else if (type === CUSTOM_TOAST.TYPES.LOADING) {
        toastClassName = CUSTOM_TOAST.CLASSES.WRAPPER_LOADING;
        toastIcon = <CircularProgress />;
    } else if (type === CUSTOM_TOAST.TYPES.SUCCESS) {
        toastClassName = CUSTOM_TOAST.CLASSES.WRAPPER_SUCCESS;
        toastIcon = <img src={successIcon} alt='success-icon' className="success-icon" />;
    }

    useEffect(() => {
        /** 
          Add a new className in the react-toastify container 
          so other toast of the application will not be affected 
        */
        const customToastContainerClassName = CUSTOM_TOAST.CLASSES.CONTAINER;
        document.querySelector(".Toastify__toast-container").classList.add(customToastContainerClassName);

        const absoluteToast = {
            position: "absolute",
            right: "1em",
            top: "19em"
        };
        const fixedToast = {
            position: "fixed",
            right: "1em",
            top: "1em"
        };

        const scrollHeight = 255;
        const toastStyle = scrollPosition >= scrollHeight ? fixedToast : absoluteToast;

        /** 
           Modify the style of the toast container 
           by using the new added className 
        */
        for (let prop in toastStyle) {
            document.querySelector(`.${customToastContainerClassName}`).style[prop] = toastStyle[prop];
        }

    }, [scrollPosition]);

    return (
        <div className={`${CUSTOM_TOAST.CLASSES.WRAPPER} ${toastClassName}`}>
            <span className={CUSTOM_TOAST.CLASSES.TEXT}>{text}</span>
            <span>
                {icon ? icon : toastIcon}
            </span>
        </div>
    )
}

export const CustomToast = (props) => {
    const { success, error, loading } = props;

    useEffect(() => {
        if (loading) {
            toast.dismiss();
            toast.info(<Toast type={CUSTOM_TOAST.TYPES.LOADING} />,
                toastConfigs(CUSTOM_TOAST.TYPES.LOADING));
        }

        if (!loading && success) {
            toast.dismiss();
            toast.info(<Toast type={CUSTOM_TOAST.TYPES.SUCCESS} />,
                toastConfigs(CUSTOM_TOAST.TYPES.SUCCESS));
        }

        if (!loading && error) {
            toast.dismiss();
            toast.info(<Toast type={CUSTOM_TOAST.TYPES.ERROR} text="Internal Error! Form not saved" />,
                toastConfigs(CUSTOM_TOAST.TYPES.ERROR));
        }

        return () => {
            toast.dismiss();
        }

    }, [loading, success, error])

    return <React.Fragment />;
}

export default CustomToast;
