import React, { useState } from "react";
import { sanitize } from 'dompurify';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { CLATooltip, CLALinkPanel, CLASimpleDrawer } from "@ais/components";
import { FORM_SETTINGS, INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';
import { Flex } from "@ais/palette";
import { colors } from '@ais/theme';
import { useOthers } from "@components/Concurrency/store/users";
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import { VFSectionV2, VFInnerSection } from "@components/FormView/v2/Section";
import { ShareFormButton } from '@components/ShareForm';
import InherentRiskFactors from '@components/InherentRiskFactors/InherentRiskFactors';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useFinalizedProject } from '@hooks/useProject';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { TailorProcedureProvider } from "@components/Forms/state"
import { ProcedureContextProvider } from '@contexts/Procedures';
import { TailorProceduresDrawerProvider } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';
import ClientAccessModal from '@views/ProjectFormInstance/components/ClientAccess/ClientAccessModal';
import ConcurrentUsersBanner from "../components/Concurrency/ConcurrentUsersBanner";
import { ProjectRiskDeficienciesProvider } from '@contexts/ProjectRiskDeficienciesContext';
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import KeyControlDrawer from '@views/InternalControl/components/Sections/UnderstandingOfEntity/KeyControlDrawer/KeyControlDrawer.jsx';

import styles from './ProjectFormInstanceV2.module.css';

const ProjectFormInstanceV2 = () => {
    const {
        projectForm = null, relatedFields, userId, schema, actions: { onLinkClick }
    } = useProjectFormInstanceProviderV2();
    const { isOpen: isKCDOpen } = useKeyControlDrawerContext();
    const { CLIENT_ACCESS_MODAL: { MODE } } = FORM_SETTINGS.EN;
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const others = useOthers();
    const isLockedByUser = others.find((user) => user.presence.focusedId === ID && user.info.userId.toLowerCase() !== userId.toLowerCase());

    const [isSharedToClient, setIsSharedToClient] = useState(!(projectForm && projectForm.clientProjectFormStatusId === null));
    const [shareClientModalVisble, setShareClientModalVisble] = useState({ visible: false, mode: "" })
    const isInternalControlForm = projectForm?.formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
    const handleShareClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.SHARE })
    };

    const handleRemoveSharedClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.REMOVE_SHARE })
    };

    return (
        <div className={styles['project-form-instance']}>
            <Grid container gap="29px">
                {projectForm?.units && (
                    <Grid container gap="8px" wrap="nowrap">
                        <Grid item>
                            <div className={styles['vf-units-label']}>
                                {PROJECT_FORM_INSTANCE.EN.ASSIGNED_UNITS}
                            </div>
                        </Grid>
                        <Grid>
                            <div className={styles['vf-units']}>
                                {projectForm?.units
                                    .map((item) => item.ProjectUnitTitle)
                                    .join(' | ')}
                            </div>
                        </Grid>
                    </Grid>
                )}
                <Grid container gap="8px">
                    <Grid item xs={12}>
                        <Flex direction="row" justify="between">
                            <div className={styles['vf-form-title']}>
                                <VFRenderedFieldWrapper
                                    className={styles.field__wrapper}
                                    isLockedByUser={isLockedByUser}>
                                        {projectForm?.projectFormName}
                                </VFRenderedFieldWrapper>
                            </div>
                            <ShareFormButton
                                isInternalControlForm={isInternalControlForm}
                                isSharedToClient={isSharedToClient}
                                isProjectFinalized={isProjectFinalized}
                                projectForm={projectForm}
                                onRemoveSharedClientClick={handleRemoveSharedClientClick}
                                onShareClientClick={handleShareClientClick}
                            />
                        </Flex>
                    </Grid>
                    {projectForm?.toolTipText && (
                        <Grid>
                            <CLATooltip
                                title={
                                    <div
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitize(projectForm?.toolTipText, {
                                                ADD_ATTR: ['target'],
                                            }),
                                        }}
                                    />
                                }
                                placement="bottom"
                            >
                                <HelpIcon style={{ fontSize: '18px', color: colors.navy[400] }} />
                            </CLATooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {
                projectForm?.formDescription && projectForm?.formTypeId !== 3 && (
                    <div className={styles['vf-form-description']}>
                        <span>{projectForm?.formDescription}</span>
                    </div>
                )
            }
            <ProjectRiskDeficienciesProvider>
                <TailorProcedureProvider>
                    <ProcedureContextProvider>
                        <TailorProceduresDrawerProvider>
                            {projectForm && projectForm.schema && schema.map((section, index) => {
                                return (
                                    <VFSectionV2 key={index} section={section} isProjectFinalized={isProjectFinalized}>
                                        <VFInnerSection
                                            fields={section.fields}
                                            auditAreaId={section?.auditArea ?? null}
                                            sectionId={section.id}
                                            sectionType={section.sectionType}
                                            section={section} 
                                            sectionIndex={index}
                                        />
                                    </VFSectionV2>
                                )
                            })
                            }
                            {schema && projectForm?.formTypeId !== INTERNAL_CONTROLS_FORM_TYPE_ID && (
                                <InherentRiskFactors projectId={projectId} projectFormId={projectForm.projectFormID} />
                            )}
                        </TailorProceduresDrawerProvider>
                    </ProcedureContextProvider>
                </TailorProcedureProvider>
            </ProjectRiskDeficienciesProvider>
            <ConcurrentUsersBanner userId={userId} />
            <CLASimpleDrawer
                anchor="right"
                isVisible={relatedFields.length > 0}
                onClose={onLinkClick}
                allowBackDropCloseClick
            >
                <CLALinkPanel
                    fields={relatedFields}
                    title="Linked Fields"
                    projectId={projectId}
                    isV2 // to update
                //disable for signedoff
                />
            </CLASimpleDrawer>
            <KeyControlDrawer isVisible={isKCDOpen} isProjectFinalized={isProjectFinalized} />
            {shareClientModalVisble.visible &&
                <ClientAccessModal
                    visible={shareClientModalVisble}
                    setShareClientModalVisble={setShareClientModalVisble}
                    projectForm={projectForm}
                    projectId={projectId}
                    setSharedToClient={setIsSharedToClient}
                />
            }
        </div>
    )
}

export default React.memo(ProjectFormInstanceV2);