import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import {
    Box,
    Divider
} from '@mui/material';

import {CLASelect} from '@ais/forms';
import { updateProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk";
import { colors } from '@ais/theme';
import { useFinalizedProject } from '@hooks/useProject';
import { useOthers } from "@components/Concurrency/store/users"; 
import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import VFRenderedFieldWrapper from '../../../VFRenderedFieldWrapper';

const SummaryProcedure = (props) => {
    const {
        summaryProcedure, 
        index, 
        children,
        projectRisks, 
        getProjectFormProcedures,
        setSuccessToast,
        setErrorToast,
        setLoadingToast,
        userId,
    } = props;
    const {
        SummaryProcedureName: summaryProcedureName,
        SummaryProcedureId: summaryProcedureId,
        ProjectRiskSummaryProcedure
    } = summaryProcedure;

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence();
    const focusId = `summary-procedure-${summaryProcedureId}`;
    const isLocked = others.find((user) => user.presence.focusedId === focusId && user.info.userId != userId)
    const lockingUser = isLocked ? { userId: isLocked.info.userId, alternativeName: isLocked.info.name } : undefined

    const { projectId, projectFormId } = useParams();
    const [defaultValues, setDefaultValues] = useState([]); 
    const [isCLASelectOpen, setIsCLASelectOpen] = useState(false);
    const isProjectFinalized = useFinalizedProject(projectId);
    const isIdle = useRoomIdle();

    function handleCLASelectClick(isOpened) {
        if(isOpened) {
            updateMyPresence({ focusedId: focusId, type: 'custom' });
        } else {
            updateMyPresence({ focusedId: null, type: 'custom' });
        }
        setIsCLASelectOpen(isOpened)
    } 

    const sortedProjectRisks = projectRisks.sort((a, b) => a.RiskName.toLowerCase().localeCompare(b.RiskName.toLowerCase()));

    useEffect(() => {
        const _defaultValues = ProjectRiskSummaryProcedure ? ProjectRiskSummaryProcedure.map(projectRisk => projectRisk.ProjectRiskId) : [];
        setDefaultValues(_defaultValues) 
    }, [ProjectRiskSummaryProcedure])

    useEffect(() => {
        setIsCLASelectOpen(false)
    }, [isIdle])

    const handleOnChange = async (values) => {
        const isEqualValue = isEqual(defaultValues, values);
        if (isEqualValue) return;

        setLoadingToast(true)
        try {
            await updateProjectRiskByAuditAreaAndProjectFormId(projectId, summaryProcedureId, projectFormId, values);
            setDefaultValues(values); 
            getProjectFormProcedures();
            setErrorToast(false)
            setSuccessToast(true)
            setLoadingToast(false)
        } catch (error) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
        }
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: '20px 15px 10px 15px'
            }}>
                <Box sx={{ width: '20%', fontSize: '16px', fontWeigth: '700' }} data-test="procedureName-text"> {`${index}. ${summaryProcedureName}`}</Box>
                <Box sx={{ width: '40%' }}>
                    <VFRenderedFieldWrapper
                        isLockedByUser={lockingUser}
                        isLocked={isLocked}
                        isInstance
                    >
                        <CLASelect
                            data-test="identifiedRisk-dropdown"
                            placeholder="Select"
                            defaultValues={defaultValues}
                            menuItems={sortedProjectRisks?.map(projectRisk => ({ ...projectRisk, label: projectRisk.RiskName, value: projectRisk.ProjectRiskId }))}
                            onChange={handleOnChange}
                            isDisabled={isLocked || isProjectFinalized}
                            onClose={() => handleCLASelectClick(false)}
                            onOpen={() => handleCLASelectClick(true)}
                            open={isCLASelectOpen}
                        />
                    </VFRenderedFieldWrapper>
                </Box>
                <Box sx={{ width: '40%' }} />
            </Box >
            <Box sx={{ p: '0 15px 10px 15px' }} data-test="summaryProcedureSteps-container">
                {children}
            </Box>
            <Divider sx={{ backgroundColor: colors.gray[30], borderWidth: '1px' }} />
        </>
    )
};

export default SummaryProcedure;
