// common modules
import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import apiClientFactory from '@utilities/apiClientFactory.js';

// custom modules
import { NotLoggedInError, authenticated, msalInstance } from '@utilities/authentication.js';


// set of scopes this client may leverage
export const scopes = [
  `${process.env.REACT_APP_API_CLIENT_ID_URI}access_as_user`,
]

// initialize API client
const client = apiClientFactory.createClient({
  baseURL: process.env.REACT_APP_API_AUTH_BASE,
  timeout: 5000,
  headers: {
    'Accept': 'application/json'
  }
});

// add authorization header interceptor
client.interceptors.request.use(async (config) => {
  await msalInstance.initialize();
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!authenticated()) {
    throw new NotLoggedInError(
      'Unable to make request to CLA API; not logged in.'
      );
    }

  let token;
  try {
    const authResult = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: activeAccount || accounts[0]
    });

    token = authResult.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const authResult = await msalInstance.acquireTokenPopup({
        scopes: scopes
      })

      token = authResult.accessToken;
    } else {
      throw error;
    }
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, null);

export default client;