import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from 'react-router-dom';
import ContainerHeader from "./ContainerHeader";
import IncorporateKeyControlsTable from "./IncorporateKeyControlsTable";
import { ReactComponent as DeleteIcon } from "@assets/delete_icon.svg";
import { Box, IconButton } from '@mui/material';
import { CLADialogConfirm } from "@ais/components";
import { INTERNAL_CONTROLS } from "@constants/customForm";
import { renderLabel } from '@ais/forms';
import styles from "./styles.module.css";
import { useDeleteProjectKeyControl, useUpdateProjectKeyControl } from "@services/customForm/internalControls.js"
import { ConcurrentCheckbox } from '../../../ConcurrentCheckbox'
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox'
import CustomToast from '@components/CustomToast/CustomToast';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

import { useOthers } from '@components/Concurrency/store/users';
import { useRoomIdle, useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";

import { isEqual } from 'lodash';

const IncorporateKeyControls = ({ 
  projectInternalControlProjectKeyControl, 
  isProjectFinalized, 
  signOffList,
  userId, 
  isLocked
}) => {
  const { projectFormId } = useParams();
  const { MODAL, LABELS, TOOLTIPS, INCORPORATE_KEY_CONTROLS, KEYS } = INTERNAL_CONTROLS.EN;
  const { projectId } = useParams(); 
  const dialogConfigsInitialValue = { visibility: false, id: undefined };
  const [deleteDialogConfigs, setDeleteDialogConfigs] = useState(dialogConfigsInitialValue);
  const [financialStatementKeyControl, setFinancialStatementKeyControl] = useState([]);
  const [journalKeyControl, setJournalKeyControl] = useState([]);

  const { mutateAsync: deleteProjectKeyControl } = useDeleteProjectKeyControl(projectId, projectFormId);
  const { mutateAsync: updateProjectKeyControl } = useUpdateProjectKeyControl();

  const [loadingIncorporateKeyControls, setLoadingIncorporateKeyControls] = useState(false)
  const [errorIncorporateKeyControls, setErrorIncorporateKeyControls] = useState(false)
  const [successIncorporateKeyControls, setSuccessIncorporateKeyControls] = useState(false)
  const [journalEntryTableHighlighted, setJournalEntryTableHighlighted] = useState(false)
  const [financialStatementTableHighlighted, setFinancialStatementTableHighlighted] = useState(false)
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const { formattedInternalControls, changeJournalFinancialKeyControlField, changeInternalControlsObj } =
    useInternalControlsContext(); 
  const [ focusedIndex, setFocusedIndex ] = useState(null)
  const [ focusedParent, setFocusedParent ] = useState(null)
  const [ previousValue, setPreviousValue ] = useState(null)
  const activeElementRef = useRef(null)
  const isIdle = useRoomIdle()

  /**
   * 
   * @param {number} projectKeyControlId 
   * @returns {number}
   */
  const getFoundKeyControlIndex = (projectKeyControlId) =>
    projectInternalControlProjectKeyControl?.findIndex((keyControl) => {
      return keyControl.ProjectKeyControl[0].ProjectKeyControlId === projectKeyControlId;
    });
  
  const deleteHandler = async (projectKeyControlId) => {
    const res = await deleteProjectKeyControl({projectKeyControlId})

    if (res) {
      setDeleteDialogConfigs(dialogConfigsInitialValue);
    }
  };

  const changeHandler = async (projectKeyControlId, key, value, keyControlCategory, idle = isIdle) => {
    setFocusedParent(null)
    setFocusedIndex(null)
    activeElementRef.current = null
    if (idle) { 
      setPreviousValue(null)
      updateMyPresence({ focusedId: null, type: 'custom' })
      return
    }
    if (previousValue === value) {
      updateMyPresence({ focusedId: null, type: 'custom' })
      setPreviousValue(null)
      return
    }

    if(keyControlCategory === INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID) {
      setJournalKeyControl(prev => {
        const _copy = [...prev]
        _copy.forEach((data) => {
          if (parseInt(data.ProjectKeyControlId) === parseInt(projectKeyControlId)) {
            data[key] = value;
          }
        })
        return _copy
      })
    }

    if(keyControlCategory === INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID) {
      setFinancialStatementKeyControl(prev => {
        const _copy = [...prev]
        _copy.forEach((data) => {
          if (parseInt(data.ProjectKeyControlId) === parseInt(projectKeyControlId)) {
            data[key] = value;
          }
        })
        return _copy
      })
    }
    
    const payload =
      key === KEYS.IS_IMPLEMENTED_EFFECTIVELY
        ? { IsImplementedEffectively: value }
        : key === KEYS.IS_DESIGNED_EFFECTIVELY
          ? { IsDesignedEffectively: value }
          : { WalkthroughComment: value };
    payload['RequestProjectFormId'] = projectFormId;

    if (key === KEYS.IS_IMPLEMENTED_EFFECTIVELY || key !== KEYS.IS_DESIGNED_EFFECTIVELY) {
      updateMyPresence({ focusedId: null, type: 'custom' })
    }

    const foundIndex = getFoundKeyControlIndex(projectKeyControlId);
    const internalControlDataBeforeUpdate = structuredClone(formattedInternalControls);
    changeJournalFinancialKeyControlField(foundIndex, key, value);
    try {
      setLoadingIncorporateKeyControls(true);
      await updateProjectKeyControl({
        ProjectKeyControlId: projectKeyControlId,
        ProjectId: projectId,
        payload,
        projectId
      })
      setSuccessIncorporateKeyControls(true)
      setLoadingIncorporateKeyControls(false)
      
      setPreviousValue(null)
    } catch (error) {
      changeInternalControlsObj(internalControlDataBeforeUpdate);
      setLoadingIncorporateKeyControls(false);
      setSuccessIncorporateKeyControls(false);
      setErrorIncorporateKeyControls(true);
      setPreviousValue(null)
    }
  };

  const filterAndSortKeyControl = (keyControlData, key, formattedInternalControls, signOffList) => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList);

    if (!keyControlData || keyControlData.length === 0) return
    let filteredKeyControl = keyControlData
      .filter((filterData) => filterData[key])
      .toSorted((a, b) => a?.ProjectKeyControl[0]?.ProjectKeyControlId - b?.ProjectKeyControl[0]?.ProjectKeyControlId)
      .map((data) => data.ProjectKeyControl);
 
    const mappedFilterControl = filteredKeyControl.map((data) => {
      const kc = data[0]
      let isAddedAfterSignoff = false

      let journalHistory = []
      let financialHistory = []
      journalHistory = formattedInternalControls?.ProjectKeyControlHistory?.filter((kch) => kch.IsJournalEntry)
      financialHistory = formattedInternalControls?.ProjectKeyControlHistory?.filter((kch) => kch.IsFinancialStatement)

      const kcDateModified = new Date(kc?.ValidFrom + 'Z').getTime();
      let isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, kcDateModified);
      if(isSignoffEnabled){
        if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
          const journalResultAdd = journalHistory.find((jh) => kc.ProjectKeyControlId === jh.ProjectKeyControlId)
          const financialResultAdd = financialHistory.find((fh) => kc.ProjectKeyControlId === fh.ProjectKeyControlId)
          if(journalResultAdd === undefined && financialResultAdd === undefined){
            isAddedAfterSignoff = true
          }
        }
  
        if(journalHistory.length === 0 && kc?.IsJournalEntry){
          if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
            isAddedAfterSignoff = true
          }
        }
        if(financialHistory.length === 0 && kc?.IsFinancialStatement){
          if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
            isAddedAfterSignoff = true
          }
        }
      }

      if (formattedInternalControls?.KeyControlHistory?.length > 0) {
        let journalHistory = []
        let financialHistory = []
        let keyControlArray = []
        let deletedKeyControl = []

        journalHistory = formattedInternalControls?.KeyControlHistory.filter((kch) => kch.IsJournalEntry)
        financialHistory = formattedInternalControls?.KeyControlHistory.filter((kch) => kch.IsFinancialStatement)
        keyControlArray = keyControlData.map((kc) => {
          return kc.ProjectKeyControl[0].ProjectKeyControlId
        })
        deletedKeyControl = formattedInternalControls?.ProjectKeyControlHistory.filter((kch) => {
          return !keyControlArray.includes(kch.ProjectKeyControlId)
        })
        deletedKeyControl.forEach((keyControl) => {
          if(keyControl.IsJournalEntry){
            const dateModified = new Date(keyControl?.ValidTo).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const isModifiedAfterSignoff = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified; 
            setJournalEntryTableHighlighted(isSignoffEnabled && isModifiedAfterSignoff);
          }
          if (keyControl.IsFinancialStatement) {
            const dateModified = new Date(keyControl?.ValidTo).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const isModifiedAfterSignoff = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified; 
            setFinancialStatementTableHighlighted(isSignoffEnabled && isModifiedAfterSignoff);
          }
        })
      }
      isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, kcDateModified);
      const shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified; 

      return {
        ...data[0],
        isHighLighted: isSignoffEnabled && shouldHighlight,
        isAddedAfterSignoff: isAddedAfterSignoff
      }
    });

    return mappedFilterControl;
  };

  useEffect(() => {
    if (projectInternalControlProjectKeyControl) {
      const financialStatementData = filterAndSortKeyControl(projectInternalControlProjectKeyControl, KEYS.IS_FINANCIAL_STATEMENT, formattedInternalControls, signOffList) || [];
      const journalEntryData = filterAndSortKeyControl(projectInternalControlProjectKeyControl, KEYS.IS_JOURNAL_ENTRY, formattedInternalControls, signOffList) || [];
      
      if(!isEqual(financialStatementData, financialStatementKeyControl)) {
        setFinancialStatementKeyControl(financialStatementData)
      }

      if (!isEqual(journalEntryData, journalKeyControl)) {
        setJournalKeyControl(journalEntryData)
      }
    }
  }, [projectInternalControlProjectKeyControl, formattedInternalControls, signOffList])

  useEffect(() => {
    if(!isIdle) return;
    if(focusedIndex === null) return;
    
    if (focusedParent === INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID) {
      setJournalKeyControl(prev => {
        const _copy = [...prev]
        _copy[focusedIndex].WalkthroughComment = previousValue
        return _copy
      })
    }

    if (focusedParent === INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID) {
      setFinancialStatementKeyControl(prev => {
        const _copy = [...prev]
        _copy[focusedIndex].WalkthroughComment = previousValue
        return _copy
      })
    }
    activeElementRef.current?.blur()
     
  }, [isIdle])

  const journalEntriesColumns = useMemo(() => [
    {
      id: KEYS.PROJECT_KEY_CONTROL_NAME,
      text: renderLabel(LABELS.KEY_CONTROLS, TOOLTIPS.KEY_CONTROLS_HEADER, null, { fontSize: '17px', marginLeft: '5px', color: "#2e334e" }),
      otherProps: {
        style: {
          width: "507px"
        },
        className: styles['key-control-name']
      }
    },
    {
      id: KEYS.IS_DESIGNED_EFFECTIVELY,
      text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_DESIGNED_EFFECTIVELY,
      otherProps: {
        align: "center",
        className: styles['designed-col'],
      },
      rowClassname: styles['designed-row']
    },
    {
      id: KEYS.IS_IMPLEMENTED_EFFECTIVELY,
      text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY,
      otherProps: {
        align: "center"
      },
    },
    {
      id: KEYS.ACTIONS,
      text: "",
      otherProps: {
        align: "right"
      },
    },
  ], [])

  const financialStatementColumns = useMemo(() => [
    {
      id: KEYS.PROJECT_KEY_CONTROL_NAME,
      text: renderLabel(LABELS.KEY_CONTROLS, TOOLTIPS.KEY_CONTROLS_HEADER, null, { fontSize: '17px', marginLeft: '5px', color: "#2e334e" }),
      otherProps: {
        style: {
          width: "507px"
        },
        className: styles['key-control-name-col']
      }
    },
    {
      id: KEYS.IS_DESIGNED_EFFECTIVELY,
      text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_DESIGNED_EFFECTIVELY,
      otherProps: {
        align: "center",
        className: styles['designed-col'],
      },
      rowClassname: styles['designed-row']
    },
    {
      id: KEYS.IS_IMPLEMENTED_EFFECTIVELY,
      text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY,
      otherProps: {
        align: "center",
        className: styles['implemented-col']
      },
    },
    {
      id: KEYS.ACTIONS,
      text: "",
      otherProps: {
        align: "right"
      },
    },
  ], [])

  const journalKeyControlRows = useMemo(() => {
    return journalKeyControl.map((data, index) => {
      return {
        projectKeyControlId: data.ProjectKeyControlId,
        isAddedAfterSignoff: data.isAddedAfterSignoff,
        isHighLighted: data.isHighLighted,
        [KEYS.PROJECT_KEY_CONTROL_NAME]: data.ProjectKeyControlName,
        [KEYS.IS_DESIGNED_EFFECTIVELY]:
          <Box>
            <ConcurrentCheckbox
              checked={data.IsDesignedEffectively}
              value={data.IsDesignedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_DESIGNED_EFFECTIVELY,
                  event.target.checked,
                  INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID
                );
              }}
              customFormObjectId={`is-designed-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>,
        [KEYS.IS_IMPLEMENTED_EFFECTIVELY]:
          <Box>
            <ConcurrentCheckbox
              checked={data.IsImplementedEffectively}
              value={data.IsImplementedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_IMPLEMENTED_EFFECTIVELY,
                  event.target.checked,
                  INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID
                );
              }}
              customFormObjectId={`is-implemented-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>,
        [KEYS.ACTIONS]: (
          <IconButton
            onClick={() => {
              setDeleteDialogConfigs({
                visibility: true,
                id: data.ProjectKeyControlId,
              });
            }}
            disabled={isProjectFinalized}
          >
            <DeleteIcon sx={{ fontSize: 40 }} />
          </IconButton>
        ),
        subChildren: (
          <IncorporateKeyControlsTextInput 
            className={`${styles["custom-content-text-field"]} ${styles["field__wrapper"]}`}
            value={data.WalkthroughComment}
            focusId={`incorporate-data-controls-walkthrough-comment-${data.ProjectKeyControlId}`}
            changeHandler={changeHandler}
            projectKeyControlId={data.ProjectKeyControlId}
            key={KEYS.WALKTHROUGH_COMMENT}
            tableId={INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID}
            onFocus={event => {
              updateMyPresence({ focusedId: `incorporate-data-controls-walkthrough-comment-${data.ProjectKeyControlId}`, type: 'custom' })
              setFocusedParent(INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID)
              setFocusedIndex(index)
              setPreviousValue(data.WalkthroughComment)
              activeElementRef.current = event.target
            }}
            inputLableShrink={true}
            sx={{
              width: "100%",
            }}
            inputProps={{
              style: { fontSize: 14 }
            }}
            InputLabelProps={{
              shrink: true
            }}
            label={renderLabel(
              INTERNAL_CONTROLS.EN.LABELS.WALKTHROUGH_COMMENT,
              INTERNAL_CONTROLS.EN.TOOLTIPS.TEXTFIELD,
              null,
              { fontSize: "23px", marginLeft: "10px", color: "#2e334e" }
            )}
            placeholder={INTERNAL_CONTROLS.EN.INCORPORATE_KEY_CONTROLS.PLACEHOLDERS.WALKTHROUGH}
            customFormObjectId={`incorporate-data-controls-walkthrough-comment-${data.ProjectKeyControlId}`}
            disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `incorporate-data-controls-walkthrough-comment-${data.ProjectKeyControlId}` && user.info.userId.toLowerCase() !== userId.toLowerCase())}
            maxLength={null}
            userId={userId}
            isLocked={isLocked}
          />
        ),
      }
    })
  }, [journalKeyControl])

  const financialStatementKeyControlRows = useMemo(() => {
    return financialStatementKeyControl.map((data, index) => {
      return {
        projectKeyControlId: data.ProjectKeyControlId,
        isAddedAfterSignoff: data.isAddedAfterSignoff,
        isHighLighted: data.isHighLighted,
        [KEYS.PROJECT_KEY_CONTROL_NAME]: data.ProjectKeyControlName,
        [KEYS.IS_DESIGNED_EFFECTIVELY]: 
        <Box sx={data.isDesignedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
          <ConcurrentCheckbox
            checked={data.IsDesignedEffectively}
            value={data.IsDesignedEffectively}
            onChange={(event) => {
              changeHandler(
                data.ProjectKeyControlId,
                KEYS.IS_DESIGNED_EFFECTIVELY,
                event.target.checked,
                INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID
              );
            }}
            customFormObjectId={`incorporate-key-controls-is-designed-effectively-${data.ProjectKeyControlId}`}
            disabled={isProjectFinalized}
          />
        </Box>,
        [KEYS.IS_IMPLEMENTED_EFFECTIVELY]: 
        <Box sx={data.isImplementedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
          <ConcurrentCheckbox
            checked={data.IsImplementedEffectively}
            value={data.IsImplementedEffectively}
            onChange={(event) => {
              changeHandler(
                data.ProjectKeyControlId,
                KEYS.IS_IMPLEMENTED_EFFECTIVELY,
                event.target.checked,
                INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID
              );
            }}
            customFormObjectId={`incorporate-key-controls-is-implemented-effectively-${data.ProjectKeyControlId}`}
            disabled={isProjectFinalized}
          />
        </Box>,
        [KEYS.ACTIONS]: (
          <IconButton
            onClick={() => {
              setDeleteDialogConfigs({
                visibility: true,
                id: data.ProjectKeyControlId,
              });
            }}
            disabled={isProjectFinalized}
          >
            <DeleteIcon sx={{ fontSize: 40 }} />
          </IconButton>
        ),
        subChildren: (
          <IncorporateKeyControlsTextInput 
            className={`${styles["custom-content-text-field"]} ${styles["field__wrapper"]}`}
            value={data.WalkthroughComment}
            focusId={`financial-statement-walkthrough-comment-${data.ProjectKeyControlId}`}
            changeHandler={changeHandler}
            projectKeyControlId={data.ProjectKeyControlId}
            key={KEYS.WALKTHROUGH_COMMENT}
            tableId={INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID}
            onFocus={event => {
              updateMyPresence({ focusedId: `financial-statement-walkthrough-comment-${data.ProjectKeyControlId}`, type: 'custom' })
              setFocusedParent(INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID)
              setFocusedIndex(index)
              setPreviousValue(data.WalkthroughComment)
              activeElementRef.current = event.target
            }}
            inputLableShrink={true}
            sx={{
              width: "100%",
            }}
            inputProps={{
              style: { fontSize: 14 }
            }}
            InputLabelProps={{
              shrink: true
            }}
            label={renderLabel(
              INTERNAL_CONTROLS.EN.LABELS.WALKTHROUGH_COMMENT,
              INTERNAL_CONTROLS.EN.TOOLTIPS.TEXTFIELD,
              null,
              { fontSize: "23px", marginLeft: "10px", color: "#2e334e" }
            )}
            placeholder={INTERNAL_CONTROLS.EN.INCORPORATE_KEY_CONTROLS.PLACEHOLDERS.WALKTHROUGH}
            customFormObjectId={`financial-statement-walkthrough-comment-${data.ProjectKeyControlId}`}
            disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `financial-statement-walkthrough-comment-${data.ProjectKeyControlId}` && user.info.userId.toLowerCase() !== userId.toLowerCase())}
            maxLength={null}
            userId={userId}
            isLocked={isLocked}
          />
        ),
      }
    })
  }, [financialStatementKeyControl])
  
  const tableData = [
    {
      tableId: INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID,
      shouldHighlight: journalEntryTableHighlighted,
      cols: journalEntriesColumns,
      rows: journalKeyControlRows,
      headerName: INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ENTRY_HEADER,
    },
    {
      tableId: INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID,
      shouldHighlight: financialStatementTableHighlighted,
      cols: financialStatementColumns,
      rows: financialStatementKeyControlRows,
      headerName: INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_HEADER,
    },
  ];

  return (
    <React.Fragment>
      {tableData.map((data) => {
        if (!data.rows.length) {
          return <React.Fragment key={data.tableId} />
        }

        return (
          <React.Fragment key={data.tableId}>
            <ContainerHeader text={data.headerName} />
            <IncorporateKeyControlsTable
              rows={data.rows}
              cols={data.cols}
              tableId={data.tableId}
              shouldHighlight={data.shouldHighlight}
            />
          </React.Fragment>
        )
      })}
      <CLADialogConfirm
        visible={deleteDialogConfigs.visibility}
        title={MODAL.TITLE}
        cancelText={MODAL.CANCEL}
        confirmText={MODAL.DELETE}
        message={MODAL.BODY}
        onConfirm={() => deleteHandler(deleteDialogConfigs.id)}
        onCancel={() => setDeleteDialogConfigs(dialogConfigsInitialValue)}
      />
      <CustomToast 
        error={errorIncorporateKeyControls} 
        success={successIncorporateKeyControls}
        loading={loadingIncorporateKeyControls}
      />
    </React.Fragment>
  );
};

const IncorporateKeyControlsTextInput = ({
  value,
  focusId,
  className,
  changeHandler,
  projectKeyControlId,
  key,
  table,
  userId,
  isLocked,
  ...props
}) => {
  const [content, setContent] = useState(value);
  const others = useOthers();
  const isIdle = useRoomIdle()

  useEffect(() => {
    if(!isIdle) return;
    setContent(value ?? "")
  }, [isIdle]);

  useEffect(() => {
    setContent(value ?? "")
  }, [value])
  
  return (
    <VFRenderedFieldWrapper
      className={className}
      customTooltipStyle={{ marginLeft: '14em' }}
      isLockedByUser={others.find((user) => user.presence.focusedId === focusId && userId.toLowerCase() !== user.info.userId.toLowerCase())}
      isLocked={isLocked}
    >
      <ConcurrentInternalControlTextbox
        value={content}
        defaultValue={content}
        onKeyUp={(event) => {
          setContent(event.target.value)
        }}
        onBlur={(event) => {
          changeHandler(projectKeyControlId, key, event.target.value, table, isIdle)
        }}
        {...props}
      />
    </VFRenderedFieldWrapper>
  )
}

export default IncorporateKeyControls;
