import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import {
    Collapse,
    IconButton
} from '@mui/material';

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";


import { usePutProjectDeficiencyCombination } from '@services/deficiencyCombination';
import { usePutProjectDeficiencies } from '@services/deficiency';

import styles from "../CLACommunicationOfDeficiencies.module.css";
import CommunicationOfDeficienciesTable from '../Common/CommunicationOfDeficienciesTable';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { getColumns } from './helper';
import { v4 as uuidv4 } from 'uuid';
import { useCustomToast, useCustomToastV2 } from '@hooks/useCustomToast';


const ManagementCommentDeficiency = (props) => {
    const { deficiencies, projectId } = props;    
    const { COMMUNICATION_OF_DEFICIENCIES, EVALUATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const { expand } = props;

    const [isExpanded, setIsExpanded] = useState(expand);
    const [rows, setRows] = useState([]);

    const {
        mutate: putProjectDeficiencyCombination,
        isLoading: loadingPutProjectDeficiencyCombination,
        isError: errorPutProjectDeficiencyCombination,
        isSuccess: successPutProjectDeficiencyCombination 
    } = usePutProjectDeficiencyCombination();

    const {
        mutate: putProjectDeficiency,
        isLoading: loadingPutProjectDeficiency,
        isError: errorPutProjectDeficiency,
        isSuccess: successPutProjectDeficiency 
    } = usePutProjectDeficiencies();
    const { projectFormId } = useParams();

    useCustomToast({
        isLoading: loadingPutProjectDeficiencyCombination || loadingPutProjectDeficiency,
        isSuccess: successPutProjectDeficiencyCombination || successPutProjectDeficiency,
        isError: errorPutProjectDeficiencyCombination || errorPutProjectDeficiency 
    })

    useCustomToastV2({
        isLoading: loadingPutProjectDeficiencyCombination || loadingPutProjectDeficiency,
        isSuccess: successPutProjectDeficiencyCombination || successPutProjectDeficiency,
        isError: errorPutProjectDeficiencyCombination || errorPutProjectDeficiency 
    })

    const apiRequest = {
        UpdateDeficiency: (requestBody) => putProjectDeficiency(requestBody),
        UpdateDeficiencyCombination: (requestBody) => putProjectDeficiencyCombination(requestBody)
    };

    useEffect(() => {
        if (deficiencies.length > 0) {
            const otherDeficiencies = deficiencies
                .filter((deficiency) => deficiency.Determination && deficiency.Determination === EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.OTHER_DEFICIENCY)
                .map(deficiency => ({...deficiency, id: uuidv4()}));            
            setRows(otherDeficiencies);
        }
    }, [deficiencies]);
    
    useUpdateEffect(
        function resetFieldOnUnmount() {
            setIsExpanded(expand)
        }, [expand]);

    const handleSave = async (row, field, value) => {        
        let requestBody = {
            ...row,
            ProjectId: projectId,
            [field]: value,
            RequestProjectFormId: projectFormId
        };

        if(field === 'IsMeritsAttentionOfManagement' && !value) {				
            requestBody = {
                ...requestBody,
                IsCommunicatedGovernance: false,
                IsCommunicatedManagement: false,
                IsCommunicationWritten: false,
                IsCommunicationOral: false,
                PersonCommunicatedTo: null,
                CommunicationDate: null,
            }
        }        
        
        if (field === 'IsCommunicationOral' && !value) {            
            requestBody = { 
                ...requestBody,
                PersonCommunicatedTo: null,
                CommunicationDate: null,					
            }
        }                
        row?.ProjectDeficiencyCombinationId ? await apiRequest['UpdateDeficiencyCombination']({ projectDeficiencyCombinationId: row.ProjectDeficiencyCombinationId, reqBody: requestBody, projectId: projectId })
            : await apiRequest['UpdateDeficiency']({ projectDeficiencyId: row.ProjectDeficiencyId, reqBody: requestBody, projectId: projectId })
    }

    useUpdateEffect(
        function resetFieldOnUnmount() {
            setIsExpanded(expand)
        }, [expand]);

    return <div style={{ borderBottom: '1px solid #d3d3d3' }}>
        <div className={styles['sub-section-header-title']}>
            <IconButton
                sx={{ p: '0' }}
                onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
            </IconButton> {COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY_HEADER}
        </div>
        <div className={styles['subsection-content']}>
            <Collapse in={isExpanded} collapsedSize={0}>
                {rows?.length > 0 ? <CommunicationOfDeficienciesTable columns={getColumns()} rows={rows} projectId={projectId} handleSave={handleSave} otherDeficiency={true} /> :
                    <div className={styles['sub-section-content-empty']} >{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY_EMPTY}</div>
                }
            </Collapse>
        </div>
    </ div>
}

export default ManagementCommentDeficiency;