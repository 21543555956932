import React, { useState, useEffect } from "react"
import GraphsDrawer from "./GraphsDrawer"
import Button from '@mui/material/Button';
import { useProjectDetails } from "@services/customForm/trendingByMonth"
import icon_add_dark from "@assets/icon_add_dark.svg"
import { GRAPHS_DRAWER } from "@constants"
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from "@components/Concurrency/store/users";
import styles from '@components/FormView/FormView.module.css';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { useProjectFormInstanceProvider } from "../../../../providers";
import {
  TRENDING_BY_MONTH
} from '@constants/forms';

const GraphsManager = (props) => {

  const { formValues, projectId, projectFormId, sectionId, schema, questionId, disabled, trendingByMonthId, title } = props
  const { GRAPH_SECTION_TITLE } = GRAPHS_DRAWER.EN
  const [drawerVisible, setDrawerVisible] = useState(false)
  const { data } = useProjectDetails(projectId)
  const { selectedGraphs } = useGraphContext()
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const isIdle = useRoomIdle();
  const {
    userId,
    isTrendingByMonthExpanded
  } = useProjectFormInstanceProvider();

  const isLockedByUser = others.find((user) => user.presence.focusedId === trendingByMonthId && user.info.userId.toLowerCase() !== userId?.toLowerCase())
  const toggleGraphsDrawer = () => {
    setDrawerVisible((prev) => !prev)
    if (!drawerVisible)
      updateMyPresence({ focusedId: trendingByMonthId })
    else
      updateMyPresence({ focusedId: null })
  }

  useEffect(() => {
    if (isIdle) {
      setDrawerVisible(false)
    }
  }, [isIdle])

  const onOpen = () => {
  }
  const handleIsLocked = (title) => {
    if (title === TRENDING_BY_MONTH.title) return isTrendingByMonthExpanded
  }

  return (
    <div>
      <VFRenderedFieldWrapper
        className={styles.field__wrapper_noalign}
        isLockedByUser={isLockedByUser}
        isLocked={isLockedByUser && handleIsLocked(title)}
      >
        <Button
          sx={{ color: "#2e334e" }}
          startIcon={<img src={icon_add_dark} />}
          onClick={toggleGraphsDrawer}
          disabled={disabled || isLockedByUser !== undefined}
        >
          {GRAPH_SECTION_TITLE}
        </Button>
      </VFRenderedFieldWrapper>
      <GraphsDrawer
        projectId={data ? data.CLCProjectId : undefined}
        projectFormId={projectFormId}
        sectionId={sectionId}
        questionId={questionId}
        formValues={formValues}
        isIdle={isIdle}
        visible={drawerVisible}
        schemaSelectedGraphs={selectedGraphs}
        schema={schema}
        onClose={toggleGraphsDrawer}
        onOpen={onOpen}
      />
    </div>
  )
}

export default GraphsManager
