import { createContext, useContext } from "react"
import { useParams } from "react-router-dom"
import { useProjectFormRisks } from "../services/forms/projectforms"
import { useProjectFormDeficiencies } from "../services/deficiencies"

export const ProjectRiskDeficienciesContext = createContext({})

export const ProjectRiskDeficienciesProvider = ({ children }) => {
    const { projectId, projectFormId } = useParams()
    const { data: projectFormRisks } = useProjectFormRisks(projectFormId, projectId)
    const { data: projectFormDeficiencies } = useProjectFormDeficiencies(projectFormId, projectId)

    return (
        <ProjectRiskDeficienciesContext.Provider value={{
            projectFormRisks: projectFormRisks || [],
            projectFormDeficiencies: projectFormDeficiencies || []
        }}>
            { children }
        </ProjectRiskDeficienciesContext.Provider>
    )
}

export const useProjectRiskDeficienciesContext = () => {
    const ctx = useContext(ProjectRiskDeficienciesContext)
    if(!ctx) {
        throw new Error("useProjectRiskDeficienciesContext should be used within `ProjectRiskDeficienciesProvider`")
    }
    return ctx
}
