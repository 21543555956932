import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LineChart } from "./LineChart";
import CLAPriorPeriodSelect from "./CLAPriorPeriodSelect/CLAPriorPeriodSelect";
import * as Constants from "@constants/index";
import { GRAPHS_DRAWER } from "@constants/index";
import { usePeriodSelectionsMutation } from "@services/customForm/trendingByMonth";
import { useFinalizedProject } from '@hooks/useProject';
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import CustomToast from '@components/CustomToast/CustomToast';
import { useProjectFormComments } from "@services/forms/projectFormComments";
import { getTrialBalanceValues } from "../helpers";
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import styles from '@components/FormView/FormView.module.css';
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { useParams } from "react-router-dom";

import { useDisabledGlobalQueryLoading } from '@hooks/index';

const Row = styled(Box)`
    display: flex;
    flex-direction: row;
`

const Grid = styled("div")`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
`

const PriorPeriodIndicator = styled(Box)`
    border-radius: 999px;
    width: 8px;
    height: 8px;
    margin-right: 3px;
`

const defaultIncomeGraph = {
    "grouping": "Type",
    "groupingId": "D. Income",
    "name": "D. Income",
    "trialBalanceId": GRAPHS_DRAWER.EN.DEFAULT_INCOME  // Had to put a string value in this key because firewall is blocking -1 or any number
}

const GraphsDisplayManager = (props) => {
    useDisabledGlobalQueryLoading();
    const { sectionId, project: projectDetails, handleUnlock, handleLock, isLockedByUser, fieldId, isLocked, schema, userId } = props;

    const { projectId, projectFormId } = useParams();
    const disabled = isLockedByUser !== undefined;
    const [selectedTrialBalances, setSelectedTrialBalances] = useState([])
    const { OPTIONS } = Constants.PRIOR_PERIOD.EN
    const CURRENT_PERIOD = Constants.CURRENT_PERIOD.EN
    const mutation = usePeriodSelectionsMutation(projectFormId, sectionId, projectId)
    const isProjectFinalized = useFinalizedProject(projectId);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const { selectedGraphs, selectedPriorPeriod, setSelectedPriorPeriod, refetchComments, setRefetchComments } = useGraphContext()
    const isIdle = useRoomIdle();
    const [selectedPeriods, setSelectedPeriods] = useState(selectedPriorPeriod)
    const formCanvas = useFormContext();

    useEffect(() => {
        if (!formCanvas?.getValues()) return
        let trialBalanceFieldValue = null
        for (const [key, value] of Object.entries(formCanvas?.getValues())) {
            if (Array.isArray(value)) {
                trialBalanceFieldValue = value
                break
            }
        }
        if (trialBalanceFieldValue && trialBalanceFieldValue.length > 0) {
            setSelectedTrialBalances([...trialBalanceFieldValue.map((trialBalanceField) => trialBalanceField.correlationDetailId)])
        }
    }, [formCanvas])

    const getSelectedPriorPeriod = (selectedId) => {
        return OPTIONS?.filter((option) => option.value === selectedId)
    }

    const getTrialBalance = getTrialBalanceValues(schema, formCanvas?.getValues())
    const { data: projectFormComments, refetch } = useProjectFormComments(projectFormId, [...getTrialBalance, GRAPHS_DRAWER.EN.DEFAULT_INCOME], projectId)

    useEffect(async () => {
        if (!refetchComments) return;

        (async () => {
            await refetch();
            setRefetchComments(false);
        })();
    }, [refetchComments]);

    const onSelectionChange = async (values) => {
        setLoading(true)
        let selected = values?.map((value) => getSelectedPriorPeriod(value)[0])
        selected.sort((a, b) => a.label.localeCompare(b.label))
        setSelectedPriorPeriod(selected);
        try {
            await mutation.mutateAsync(selected)
            setError(false)
            setSuccess(true)
        } catch (error) {
            setError(false)
            setSuccess(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isIdle) setSelectedPeriods(selectedPriorPeriod)
    }, [isIdle])

    useEffect(() => {
        setSelectedPeriods(selectedPriorPeriod)
    }, [selectedPriorPeriod])

    return (
        <React.Fragment>
            <Box sx={{ width: "20%" }}>
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLocked}
                >
                    <CLAPriorPeriodSelect
                        onSelectionChange={(values) => {
                            // this will only be triggered when the prior period
                            // selected is closed
                            onSelectionChange(values)
                        }}
                        isIdle={isIdle}
                        onClose={handleUnlock}
                        onOpen={handleLock}
                        defaultValues={selectedPeriods?.map(period => period.value) ?? []}
                        disabled={isProjectFinalized || disabled}
                    />
                </VFRenderedFieldWrapper>
            </Box>
            <Row sx={{ justifyContent: "center", py: "8px" }}>
                <Row sx={{ alignItems: "center", px: "8px" }}>
                    <PriorPeriodIndicator sx={{ backgroundColor: CURRENT_PERIOD.color }} />
                    <Typography sx={{ marginLeft: "4px" }}>
                        {CURRENT_PERIOD.label}
                    </Typography>
                </Row>
                {selectedPriorPeriod?.map((selectedPrior) => (
                    <Row sx={{ alignItems: "center", px: "8px" }} key={selectedPrior.value}>
                        <PriorPeriodIndicator sx={{ backgroundColor: selectedPrior.color }} />
                        <Typography sx={{ marginLeft: "4px" }}>
                            {selectedPrior.label}
                        </Typography>
                    </Row>
                ))}
            </Row>
            <Grid>
                {!selectedGraphs.length && (
                    <LineChart
                        key={defaultIncomeGraph.groupingId}
                        selectedPriorPeriods={[CURRENT_PERIOD, ...selectedPriorPeriod]}
                        selectedTrialBalances={selectedTrialBalances}
                        graph={defaultIncomeGraph}
                        comments={projectFormComments?.data}
                        projectPeriodEndDate={projectDetails?.ProjectPeriodEndDate}
                        projectFormId={projectFormId}
                        sectionId={sectionId}
                        userId={userId}
                        getTrialBalanceValues={() => getTrialBalanceValues(schema, formCanvas?.getValues())}
                        disabled={isProjectFinalized || disabled}
                        projectId={projectId}
                        handleUnlock={handleUnlock}
                        handleLock={handleLock}
                        fieldId={fieldId}
                        isLocked={isLocked}
                    />
                )}
                {selectedGraphs.map((graph) => {
                    let conditionalComments
                    if (graph.groupingId === defaultIncomeGraph.groupingId) {
                        const filteredComments = []
                        projectFormComments?.data?.forEach(item => {
                            const currentItem = {
                                ...item,
                                CommentPath: JSON.parse(item.CommentPath)
                            }
                            if (!currentItem.CommentPath.trialBalances.trialBalanceId || !currentItem.CommentPath.Type === defaultIncomeGraph.groupingId) {
                                return false
                            }

                            filteredComments.push({ ...currentItem, CommentPath: JSON.stringify(currentItem.CommentPath) })
                        })

                        const index = filteredComments.length ? filteredComments.length - 1 : 1
                        conditionalComments = [filteredComments[index]]
                    } else {
                        conditionalComments = projectFormComments?.data
                    }
                    return (
                        <LineChart
                            key={`${graph.groupingId}-${graph.trialBalanceId}`}
                            selectedPriorPeriods={[CURRENT_PERIOD, ...selectedPriorPeriod]}
                            selectedTrialBalances={selectedTrialBalances}
                            graph={graph}
                            comments={conditionalComments}
                            projectPeriodEndDate={projectDetails?.data?.ProjectPeriodEndDate ?? projectDetails?.ProjectPeriodEndDate}
                            projectFormId={projectFormId}
                            sectionId={sectionId}
                            userId={userId}
                            getTrialBalanceValues={() => getTrialBalanceValues(schema, formCanvas?.getValues())}
                            disabled={isProjectFinalized || disabled}
                            handleUnlock={handleUnlock}
                            handleLock={handleLock}
                            fieldId={fieldId}
                            isLocked={isLocked}
                        />
                    )
                })}
            </Grid>
            <CustomToast
                error={error}
                loading={loading}
                success={success}
            />
        </React.Fragment>
    )
}

export default GraphsDisplayManager