import React from 'react';
import { styled } from '@mui/system';

import { FIELD_TYPES } from "@ais/constants";
import styles from '@components/FormView/FormView.module.css';
import { useFinalizedProject } from '@hooks/useProject';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { CLAAccountAssertionLevelRisk, CLAFinancialStatementLevelRisk } from '@views/RiskAssesmentSummary';

const StyledFieldWrapper = styled('div')(() => ({
	padding: '0 24px',
  width: '100%',
}));

export const RiskSummaryFieldSelector = ({ field }) => {
  const { type } = field;
  const { projectForm: { projectFormID, projectId }, userId} = useProjectFormInstanceProviderV2();
  const isProjectFinalized = useFinalizedProject(projectId);
  const disabled = isProjectFinalized;

  switch (type) {
    case FIELD_TYPES.FINANCIAL_STATEMENT_LEVEL_RISK: {
      return (
        <StyledFieldWrapper className={styles.field__wrapper}>
          <div className={styles.field__wrapper_inner}>
            <CLAFinancialStatementLevelRisk
              projectFormId={projectFormID}
              disabled={disabled}
              userId={userId}
            />
          </div>
        </StyledFieldWrapper>
      );
    }
    case FIELD_TYPES.ACCOUNT_AND_ASSERTION_LEVEL_RISK: {
      return (
        <StyledFieldWrapper className={styles.account__assertion__wrapper}>
          <div className={styles.field__wrapper_inner}>
            <CLAAccountAssertionLevelRisk
              projectFormId={+projectFormID}
              projectId={projectId}
              disabled={disabled}
              userId={userId}
            />
          </div>
        </StyledFieldWrapper>
      );
    }
    default:
      return <></>;
  }
};