import { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ButtonGroup, Button } from '@mui/material';

import { DRAG_TYPES } from '@ais/constants'; 
import { DeleteIconComponent } from '@ais/assets';
import { AddQuestion } from '@components/FormView/actions';
import { ReactComponent as Pencil } from '@assets/form_edit.svg';
import { useProjectFormInstanceProviderV2 } from '@providers'; 

import styles from '../../FormView.module.css';

export const VFDraggableInstanceField = (props) => {
  const {
    field,
    section,
    sectionIndex,
    rowIndex,
    children,
    handleDeleteField,
    saveFields
  } = props;
  const { projectForm, schema, actions: { updateSchema } } = useProjectFormInstanceProviderV2();
  const [visible, setVisible] = useState(false);

  const projectFormId = projectForm.projectFormID;
  const dragDropRef = useRef(null);

  const applyClass = () => {
    let classList = [styles['rendered-field'], styles['edit-mode']];
    return classList.join(' ');
  };

  const [, drop] = useDrop({
    accept: DRAG_TYPES.RENDERED_FIELD,
    hover: (item, _) => {
      if (!dragDropRef.current) return;
      const dragIndex = item.rowIndex;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) return;
      const fields = [...schema[sectionIndex]?.fields]; 
      const dragged = fields[dragIndex];
      fields.splice(dragIndex, 1);
      fields.splice(hoverIndex, 0, dragged); 
      saveFields(hoverIndex, dragged[0]);
      updateSchema((prevSchema) => {
        return prevSchema.map((data) => {
          if (data.id.toLowerCase() === section.id.toLowerCase()) {
            return {
              ...data,
              fields: fields
            }
          }
          return data;
        })
      })
      item.rowIndex = hoverIndex;
    }
  });

  const [, drag] = useDrag({
    type: DRAG_TYPES.RENDERED_FIELD,
    item: () => ({ rowIndex }),
  });

  drag(drop(dragDropRef));
  const sectionType = section?.sectionType ?? section?.type;
  return (
    <>
      <div
        className={sectionType === DRAG_TYPES.SECTION_BLANK ? styles['vf-section-blank-wrapper'] : styles['vf-rendered-field-wrapper']}
        style={{ width: `${field.width}%` }}
      >
        <div ref={dragDropRef} className={applyClass()}>
          {children}
          <div className={styles['field-overlay']} style={{ cursor: 'move' }} />
        </div>
        <div className={styles['vf-rendered-field-buttons']}>
          <ButtonGroup sx={{ borderColor: 'gray' }} variant="outlined">
            <Button
              onClick={() => setVisible(true)}
              sx={{ borderColor: 'gray' }}
            >
              <Pencil />
            </Button>
            <Button
              sx={{ borderColor: 'gray' }}
              onClick={() => handleDeleteField(field.id)}
            >
              <DeleteIconComponent />
            </Button>
          </ButtonGroup>
        </div>

        <AddQuestion
          section={section}
          projectFormId={projectFormId}
          visible={visible}
          setVisible={setVisible}
          additionalData={{ ...field, rowIndex }}
          editMode={true}
        />
      </div>
    </>
  );
};
