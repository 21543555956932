import React, { useState, useEffect } from 'react';
import MaterialWeakness from "./MaterialWeakness/MaterialWeakness";
import SignificantDeficiency from "./SignificantDeficiency/SignificantDeficiency";
import ManagementCommentDeficiency from "./ManagementCommentDeficiency/ManagementCommentDeficiency";

import style from "./CLACommunicationOfDeficiencies.module.css";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useParams } from 'react-router-dom';
import { useLoading } from '@hooks/index';
import { useGetProjectDeficiencies } from '@services/deficiencies';
import { useGetProjectDeficiencyCombinations } from '@services/deficiencyCombination';
import { useRoomIdle } from '@components/Concurrency/provider/RoomProvider';
import { useProjectFormInstanceProvider } from '../../../../providers';
import { useCommunicationStore } from './communication';
import { useDeficiency } from '../../hooks/useDeficiency';
import { useDisabledGlobalQueryLoading } from '@hooks/index';

const CLACommunicationOfDeficiencies = (props) => {
    useDisabledGlobalQueryLoading();
    const { signOffList } = props;
    const updateLatestSignoffTime = useCommunicationStore(state => state.updateLatestSignoffTime);
    const { unitIds } = useProjectFormInstanceProvider();
    const { COMMUNICATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const [expandAll, setExpandAll] = useState(false);
    const [deficiencies, setDeficiencies] = useState([]);
    const [projectDeficiencies, setProjectDeficiencies] = useState([]);
    const [projectDeficiencyCombinations, setProjectDeficiencyCombinations] = useState([]);
    const setLoading = useLoading();
    const { projectId, projectFormId } = useParams();
    const {
        concurrencyEventReceived,
        deficiencies: concurrentDeficiency,
        deficiencyCombinations: concurrentDeficiencyCombination
    } = useDeficiency()
    const isIdle = useRoomIdle()
    const {
        data: ProjectDeficiencies,
        isLoading: isLoadingProjectDeficiencies
    } = useGetProjectDeficiencies(null, projectId, null, unitIds, projectFormId);

    const {
        data: ProjectDeficiencyCombinations,
        isLoading: isLoadingProjectDeficiencyCombinations
    } = useGetProjectDeficiencyCombinations(projectId, unitIds, projectFormId);

    useEffect(() => {
        if (ProjectDeficiencies && ProjectDeficiencies.status === 200) {
            setProjectDeficiencies(ProjectDeficiencies.data)
        }
    }, [ProjectDeficiencies])

    useEffect(() => {
        if (ProjectDeficiencyCombinations && ProjectDeficiencyCombinations.status === 200) {
            setProjectDeficiencyCombinations(ProjectDeficiencyCombinations.data)
        }
    }, [ProjectDeficiencyCombinations])

    useEffect(() => {
        let newDeficiency = [];

        if (projectDeficiencies) {
            newDeficiency.push(...projectDeficiencies)
        }

        if (projectDeficiencyCombinations) {
            const newDeficiencyIdsToBeRemoved = []
            projectDeficiencyCombinations.forEach(combination => {
                combination.Deficiencies.forEach(def => {
                    if (newDeficiency.some(newDef => newDef.ProjectDeficiencyId === def.ProjectDeficiencyId)) {
                        newDeficiencyIdsToBeRemoved.push(def.ProjectDeficiencyId);
                    }
                })
            });

            newDeficiency = newDeficiency.filter(def => !newDeficiencyIdsToBeRemoved.includes(def.ProjectDeficiencyId));
            newDeficiency.push(...projectDeficiencyCombinations)
        }
        setDeficiencies(newDeficiency)
    }, [projectDeficiencies, projectDeficiencyCombinations])

    useEffect(() => {
        if (concurrencyEventReceived && concurrentDeficiency.length > 0) {
            let _deficiencies = [];
            if (!projectDeficiencies.find(data => data.ProjectDeficiencyId === concurrentDeficiency[0].ProjectDeficiencyId)) {
                _deficiencies = [
                    ...projectDeficiencies,
                    concurrentDeficiency[0]
                ]
            } else {
                for (const deficiency of projectDeficiencies) {
                    const currentDeficiency = concurrentDeficiency.find(data => data.ProjectDeficiencyId === deficiency.ProjectDeficiencyId);
                    if (currentDeficiency) {
                        _deficiencies.push(currentDeficiency)
                    } else {
                        _deficiencies.push(deficiency)
                    }
                }
            }
            setProjectDeficiencies(_deficiencies)
        }
    }, [concurrencyEventReceived, concurrentDeficiency])

    useEffect(() => {
        if (concurrencyEventReceived && concurrentDeficiencyCombination) {
            let _deficiencyCombinations = [];
            if (typeof concurrentDeficiencyCombination !== 'object') {
                _deficiencyCombinations = projectDeficiencyCombinations.filter(data => data.ProjectDeficiencyCombinationId !== parseInt(concurrentDeficiencyCombination))
            } else {
                if (!projectDeficiencyCombinations.find(data => data.ProjectDeficiencyCombinationId === concurrentDeficiencyCombination.ProjectDeficiencyCombinationId)) {
                    _deficiencyCombinations = [
                        concurrentDeficiencyCombination,
                        ...projectDeficiencyCombinations
                    ]
                } else {
                    for (const deficiencyCombination of projectDeficiencyCombinations) {
                        if (deficiencyCombination.ProjectDeficiencyCombinationId === concurrentDeficiencyCombination.ProjectDeficiencyCombinationId) {
                            _deficiencyCombinations.push(concurrentDeficiencyCombination)
                        } else {
                            _deficiencyCombinations.push(deficiencyCombination)
                        }
                    }
                }
            }
            setProjectDeficiencyCombinations(_deficiencyCombinations)
        }
    }, [concurrencyEventReceived, concurrentDeficiencyCombination])
    useEffect(() => {
        if (isLoadingProjectDeficiencies || isLoadingProjectDeficiencyCombinations) setLoading(true);
        else setLoading(false);
    }, [isLoadingProjectDeficiencies, isLoadingProjectDeficiencyCombinations]);

    useEffect(() => {
        if (isIdle) {
            setProjectDeficiencies(projectDeficiencies)
            setProjectDeficiencyCombinations(projectDeficiencyCombinations)
        }
    }, [isIdle]);

    const handleExpandAll = () => {
        setExpandAll(true);
    }

    const handleCollapseAll = () => {
        setExpandAll(false);
    }

    useEffect(() => {
        const reviewers = signOffList.filter(signoff => signoff.signOffLevel !== 1);
        const latestSignoffTime = new Date(reviewers[reviewers?.length - 1]?.signOffDate).getTime();
        updateLatestSignoffTime(latestSignoffTime)
    }, [signOffList])

    return <div>
        <div className={style['section-header-container']}>
            <div className={style['section-header-title']}>{COMMUNICATION_OF_DEFICIENCIES.SECTION_HEADER}</div>
            <div className={style['section-header-expand-all']}>
                {expandAll ? <a href="#collapse" className={style.expand_all_link} onClick={() => handleCollapseAll()}>{COMMUNICATION_OF_DEFICIENCIES.COLLAPSE_ALL}</a> :
                    <a href="#expand" className={style.expand_all_link} onClick={() => handleExpandAll()}>{COMMUNICATION_OF_DEFICIENCIES.EXPAND_ALL}</a>
                }
            </div>
        </div>
        <div className={style['sub-section-container']}>
            <MaterialWeakness expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
        <div className={style['sub-section-container']}>
            <SignificantDeficiency expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
        <div className={style['sub-section-container']}>
            <ManagementCommentDeficiency expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
    </div>
}

export default CLACommunicationOfDeficiencies;