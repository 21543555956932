import React, { useCallback, useState } from 'react';
import { Typography, InputAdornment, Avatar} from '@mui/material';
import { TbLinkDrawer } from './TbLinkDrawer';
import clsx from "clsx"
import styles from "./TbLink.module.css"

const CheckIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" {...props}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>

    )
}

const TbLink= ({ text = "TB", criteria, state = -1, valid = true, onSave, disabled, onDrawerOpen, onDrawerClose }) => {

    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const onTbLinkClicked = useCallback(() => {
        setDrawerOpen((prevValue) => !prevValue)
        onDrawerOpen && onDrawerOpen();
    }, [])

    const componentClassName = clsx(
        styles["tblink"],
        (valid && state >= 0) && styles["tblink--valid"],
        !valid && styles["tblink--invalid"]
    )

    const textComponentClassName = clsx(
        !valid && styles["tblink__text--invalid"]
    )

    const handleSave = async (formValues, isValid) => {
        onSave && await onSave(formValues, isValid);
    }

    const handleCancel = () => {
        onDrawerClose && onDrawerClose();
    }

    return (
        <>
            <InputAdornment position='end' >
                {valid ? (
                    <>
                        { state < 0 ? (
                            <>
                                <span className={componentClassName} onClick={onTbLinkClicked}>
                                    <Avatar component='span'
                                        target='blank'
                                        style={{ backgroundColor: 'white', color: 'hsl(181deg 37% 51%)', width: '30px' }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontWeight: 'bold',
                                                textDecoration: 'underline hsl(181deg 37% 51%)'
                                            }}>
                                            {text}
                                        </Typography>
                                    </Avatar>
                                </span>
                            </>
                        ) : (
                            <>
                                <span className={componentClassName} onClick={onTbLinkClicked}>
                                    <Avatar component='span'
                                        target='blank'
                                        style={{ backgroundColor: 'white', color: "inherit", marginRight: '7px' }}
                                    >
                                        <CheckIcon className={styles["tblink__check-icon"]} />
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontWeight: 'bold'
                                            }}>
                                            {text}
                                        </Typography>
                                    </Avatar>
                                </span>
                            </>
                        )}
                    </>
                ) : (
                    <span className={componentClassName} onClick={onTbLinkClicked}>
                        <Avatar component='span'
                            target='blank'
                            style={{ backgroundColor: 'white', color: 'hsl(181deg 37% 51%)' }}
                        >
                            <Typography
                                className={textComponentClassName}
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 'bold',
                                }}>
                                !{text}
                            </Typography>
                        </Avatar>
                    </span>
                )}
                
            </InputAdornment>
            {drawerOpen && (
                <TbLinkDrawer
                    disabled={disabled}
                    criteria={criteria}
                    open={drawerOpen}
                    setOpen={setDrawerOpen}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </>
    )
}

export default TbLink
