import { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useFinalizedProject } from '@hooks/useProject';
import formServices from '@services/forms/forms';
import projectFormServices from '@services/forms/projectforms.js';
import { getProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk.js";
import { fetchProjectRisksAuditArea } from "@services/riskassessment";
import { getFormattedSignOffDate } from '@utilities/dateHelpers';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

export const RiskAssessmentContext = createContext({});

export const RiskAssessmentProvider = ({ children }) => {
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [financialStatementPersuasiveRisk, setFinancialStatementPersuasiveRisk] = useState([]);
    const [answerList, setAnswerList] = useState([]);
    const [auditAreaWithComment, setAuditAreaWithComment] = useState([]);

    const [concurrencyRiskInfo, setConcurrencyRiskInfo] = useState(null);
    const [concurrencyOtherRelevantAssertion, setConcurrencyOtherRelevantAssertion] = useState(null);
    const [financialStatementLevelRiskToggle, setFinancialStatementLevelRiskToggle] = useState(false);
    const [isFinancialStatementExpanded, setIsFinancialStatementExpanded] = useState(false);
    const [isAssertionLevelRiskExpanded, setIsAssertionLevelRiskExpanded] = useState(false);
    const [auditAreaScotabdRisk, setAuditAreaScotabdRisk] = useState([]);
    const [modifiedRiskAssessmentSummaryAfterSignOff, setModifiedRiskAssessmentSummaryAfterSignOff] = useState([]);
    const [loadingProjectRiskAssessmentSummary, setLoadingProjectRiskAssessmentSummary] = useState(false)
    const [errorProjectRiskAssessmentSummary, setErrorProjectRiskAssessmentSummary] = useState(false)
    const [successProjectRiskAssessmentSummary, setSuccessProjectRiskAssessmentSummary] = useState(false)
    const [signOffList, setSignOffList] = useState([]);

    const { projectId, projectFormId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);

    const handleIsFinancialStatementExpanded = (isExpanded) => setIsFinancialStatementExpanded(isExpanded);
    const handleIsAssertionLevelRiskExpanded = (isExpanded) => setIsAssertionLevelRiskExpanded(isExpanded);
    const handleAuditAreaScotabdRisk = (data) => setAuditAreaScotabdRisk(data);
    const handleModifiedRiskAssessmentSummaryAfterSignOff = (data) => setModifiedRiskAssessmentSummaryAfterSignOff(data);
    const handleLoadingProjectRiskAssessmentSummary = (isLoading) => setLoadingProjectRiskAssessmentSummary(isLoading);
    const handleSuccessProjectRiskAssessmentSummary = (isSuccess) => setSuccessProjectRiskAssessmentSummary(isSuccess);
    const handleErrorProjectRiskAssessmentSummary = (isError) => setErrorProjectRiskAssessmentSummary(isError);
    const handleSignOffList = (data) => setSignOffList(data);
    const handleFinancialStatementLevelRiskToggle = (toggle) => setFinancialStatementLevelRiskToggle(toggle);

    const latestModifiedAnswerAfterSignOff = async (auditAreaScotabdRiskData) => {
        let answerResult = [];

        const { data } = await getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
        const projectRisks = data ?? [];
        answerResult = projectRisks.filter((projectrisk) => { return projectrisk.IsPervasive === true });

        const projectAuditAreaRisk = await fetchProjectRisksAuditArea(projectFormId, projectId) ?? [];
        answerResult.push(...projectAuditAreaRisk);

        if (!!auditAreaScotabdRiskData.length) {
            const { riskInfo } = await formServices.getProjectScopeAuditAreaSCOTABDRiskList(auditAreaScotabdRiskData, projectId, projectFormId);
            const riskInfoData = riskInfo ?? [];
            answerResult.push(...riskInfoData);

            const { otherRelevantAssertions } = await formServices.getBatchProjectScopeAuditAreaSCOTABDOtherRelevantAssertions(auditAreaScotabdRiskData, projectId);
            const otherRelevantAssertionData = otherRelevantAssertions ?? [];
            answerResult.push(...otherRelevantAssertionData);
        }

        return answerResult;
    };

    const sortSignOffListAsc = (list) => list.sort((a, b) => new Date(a.signOffDate).getTime() > new Date(b.signOffDate).getTime() ? 1 : -1);

    const listOfModifiedAnswerAfterSignOff = (lastSignOffDate, value) => {
        if (Array.isArray(value) && value.length > 0) {
            const modifiedList = () => {
                let result = [];
                if (!lastSignOffDate) return result;
                result = value
                    .filter(
                        (answer) =>
                            answer.validFrom &&
                            new Date(getFormattedSignOffDate(lastSignOffDate)).getTime() <
                            new Date(getFormattedSignOffDate(answer.validFrom)).getTime() &&
                            isSignoffLogicEnabled(isProjectFinalized, new Date(getFormattedSignOffDate(answer.validFrom)).getTime())
                    );

                return result;
            }

            setModifiedRiskAssessmentSummaryAfterSignOff(modifiedList);
        }
    }

    const riskAssessmentIdentifyModifiedAnswerAfterSignOff = useCallback(async (auditAreaScotabdRiskData = []) => {
        const answerValue = await latestModifiedAnswerAfterSignOff(auditAreaScotabdRiskData);

        const signOffResult = await projectFormServices.getSignOffByProjectFormId(projectFormId, projectId);
        const sortSignOff = sortSignOffListAsc(signOffResult || []);

        let lastSignOffDate = null;
        if (sortSignOff && sortSignOff?.length > 0) {
            const reviewerSignOffs = sortSignOff.filter(
                (signOff) => signOff.signOffLevel !== 1
            );
            lastSignOffDate =
                getFormattedSignOffDate(reviewerSignOffs[reviewerSignOffs?.length - 1]?.signOffDate);
        }

        listOfModifiedAnswerAfterSignOff(lastSignOffDate, answerValue)
    }, [signOffList]);

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            financialStatementPersuasiveRisk,
            setFinancialStatementPersuasiveRisk,
            auditAreaWithComment,
            setAuditAreaWithComment,
            concurrencyRiskInfo,
            setConcurrencyRiskInfo,
            concurrencyOtherRelevantAssertion,
            setConcurrencyOtherRelevantAssertion,
            isFinancialStatementExpanded,
            isAssertionLevelRiskExpanded,
            auditAreaScotabdRisk,
            modifiedRiskAssessmentSummaryAfterSignOff,
            signOffList,
            financialStatementLevelRiskToggle,
            loadingProjectRiskAssessmentSummary,
            successProjectRiskAssessmentSummary,
            errorProjectRiskAssessmentSummary,
            actions: {
                riskAssessmentIdentifyModifiedAnswerAfterSignOff,
                updateIsFinancialStatementExpanded: handleIsFinancialStatementExpanded,
                updateIsAssertionLevelRiskExpanded: handleIsAssertionLevelRiskExpanded,
                updateAuditAreaScotabdRisk: handleAuditAreaScotabdRisk,
                updateModifiedRiskAssessmentSummaryAfterSignOff: handleModifiedRiskAssessmentSummaryAfterSignOff,
                updateLoadingProjectRiskAssessmentSummary: handleLoadingProjectRiskAssessmentSummary,
                updateSuccessProjectRiskAssessmentSummary: handleSuccessProjectRiskAssessmentSummary,
                updateErrorProjectRiskAssessmentSummary: handleErrorProjectRiskAssessmentSummary,
                updateSignOffList: handleSignOffList,
                updateFinancialStatementLevelRiskToggle: handleFinancialStatementLevelRiskToggle
            }
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            financialStatementPersuasiveRisk,
            setFinancialStatementPersuasiveRisk,
            auditAreaWithComment,
            setAuditAreaWithComment,
            concurrencyRiskInfo,
            setConcurrencyRiskInfo,
            concurrencyOtherRelevantAssertion,
            setConcurrencyOtherRelevantAssertion,
            isFinancialStatementExpanded,
            isAssertionLevelRiskExpanded,
            auditAreaScotabdRisk,
            modifiedRiskAssessmentSummaryAfterSignOff,
            signOffList,
            financialStatementLevelRiskToggle,
            loadingProjectRiskAssessmentSummary,
            successProjectRiskAssessmentSummary,
            errorProjectRiskAssessmentSummary,
        ]
    )

    return (
        <RiskAssessmentContext.Provider value={memoized}>{children}</RiskAssessmentContext.Provider>
    )
}

RiskAssessmentProvider.propTypes = {
    children: PropTypes.node
}