import clientApi from '@utilities/claApiClient';
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import logger from '@utilities/logService'

const getIndustries = async () => {
  try {
    const response = await clientApi.get('/industries');
    const industries = [];
    response.data.map((industry) => {
      const { MethodologyIndustryId, MethodologyIndustryName } = industry;
      industries.push({
        industryId: MethodologyIndustryId,
        industryName: MethodologyIndustryName,
      });
    });
    return { status: 200, data: industries };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const addProjectTeam = async (projectTeam, projectId) => {
  try {
    const response = await clientApi.post(`/projectteams/${projectId}`, projectTeam);
    return { status: 200, data: response };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const getIndustriesByMethodologyVersionId = async (methodologyVersionId, isEnabledOnly = false) => {
  try {
    const response = await clientApi.get(`/industries/getIndustriesByMethodologyVersionId/${methodologyVersionId}`);
    let data = response.data.map((data) => ({
      industryId: data.MethodologyIndustryId,
      industryName: data.MethodologyIndustryName,
      isEnabled: data.IsEnabled
    }))

    if(isEnabledOnly) {
      data = data.filter((industry) => industry.isEnabled)
    }

    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const downloadAuditDocumentation = async (projectId) => {
  try {
    const response = await clientApi.get(`/projects/${projectId}/audit-documentation`, {
      responseType: 'blob',
    });
    return { status: 200, data: response.data };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};
const downloadAuditDocumentationByContent = async (projectId, body) => {
  try {
    const response = await clientApi.post(`/projects/audit-documentation/${projectId}`, { body: body }, {
      responseType: 'blob',
    });
    return { status: 200, data: response.data };
  } catch (ex) {
    return { status: 400, message: ex };
  }
}; // To change name

const clientServices = {
  getIndustries,
  addProjectTeam,
  getIndustriesByMethodologyVersionId,
  downloadAuditDocumentation,
  downloadAuditDocumentationByContent,
};

export default clientServices;

// TANSTACK USEQUERY
const getCLCDatabaseClients = async () => {
  try {
    const response = await clientApi.get('/clc-database/clients/');
    return { status: 200, data: response };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};

const getCLCDatabaseServiceLines = async () => {
  try {
    const response = await clientApi.get('/clc-database/servicelines/');
    return response.data;
  } catch (ex) {
    return { status: 400, message: ex };
  }
};


const getCLCDatabaseClientById = async (id) => {
  try {
    const response = await clientApi.get(`/clc-database/clients/${id}`);
    return { status: 200, data: response };
  } catch (ex) {
    return { status: 400, message: ex };
  }
};


export const useGetCLCServiceLines = (enabled) => {
  return useQuery(
    ["CLCDatabaseServiceLines"],
    () => getCLCDatabaseServiceLines(),
    {
      enabled: !!enabled,
    }
  );
};

export const useGetCLCDatabaseClients = (enabled) => {
  return useQuery(
    ["CLCDatabaseClients"],
    () => getCLCDatabaseClients(),
    {
      enabled: !!enabled,
    }
  );
};

const getClientsByUserId = async (userId, clientNameSearch, limit = 10, offset = 0) => {
  const params = { limit: limit, offset: offset * limit, clientName: clientNameSearch }
  const { data } = await clientApi.get(`/clients/userid?emailAddress=${userId}`, { params, timeout: 900000 });
  return { rows: data, nextOffset: data?.length < limit ? undefined : (offset * limit) + 1 }
};

export const useGetCLCDatabaseClientByUserId = (userId, clientNameSearch) => {
  return useInfiniteQuery(
    ["CLCDatabaseClientsByUserId", clientNameSearch],
    ({ pageParam }) => getClientsByUserId(userId, clientNameSearch, 10, pageParam),
    {
      enabled: !!userId && !!clientNameSearch,
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastGroup, groups) => {
        return _lastGroup.rows.length <= 10 &&  _lastGroup.rows.length >= 1 ? groups.length : undefined
      },
      select: (data) => {
        const allRows = data ? data.pages.flatMap((d) => d.rows) : []
        return allRows.map((row) => ({ 
          value: row?.AccountId, 
          text: row?.CLCClientDisplayId,
          accountNumber: row?.AccountNumber ?? '', 
          isRestricted: row?.IsRestricted ?? '',
          engagementLeaders: row?.EngagementLeaders ?? []
        }))
      },
      onError: (error) => {
        logger.error(error)
      }
    }
  );
};

export const projectFormsUserPreferences = async (projectFormId, userId) => {
try {
    const response = await clientApi.get(`/project-forms/${projectFormId}/user-preferences/client-projects/${userId}`);
    return response;
  }
  catch (ex) {
    return { status: 400, message: ex }
  }
}

export const useProjectFormsUserPreferences = (projectFormId, userId, enabled) => {
  return useQuery(["CLCProjectFormsUserPreferences", projectFormId, userId],
    () => projectFormsUserPreferences(projectFormId, userId), {
      enabled: !!enabled && !!userId && !!projectFormId,
      refetchOnWindowFocus: false,
    });
};

const getClientsWithProjects = async (clientNameSearch) => {
  const params = {
    clientName: clientNameSearch
  };

  const { data } = await clientApi.get(`/clients/accessible`, { params });
  return data;
};

export const useGetClientsWithProjects = (clientNameSearch) => {
  return useQuery(
    ['CLCDatabaseClientsWithProjects', clientNameSearch],
    () => getClientsWithProjects(clientNameSearch),
    {
      enabled: !!clientNameSearch && typeof clientNameSearch === 'string' && clientNameSearch.length >= 3,
      refetchOnWindowFocus: false,
      select: (clients) => {
        return !clients || !clients.length || clients.length < 1
          ? []
          : clients.map((client) => ({
              ...client,
              value: client.AccountId,
              text: client.ClientName
            }));
      }
    }
  );
};

export const useGetIndustriesByMethodologyVersionId = (methodologyVersionId, filtered = false, isEnabledOnly = false) => {
  return useQuery(
      ["industriesByMethodologyVersionId", methodologyVersionId],
      () => getIndustriesByMethodologyVersionId(methodologyVersionId, isEnabledOnly),
      {
          enabled: !!methodologyVersionId,
          select: (industries) => {
            if(!filtered) {
              return industries
            }
            const industriesFiltered = industries.data.map((industry) => ({
              label: industry.industryName,
              value: industry.industryId,
            }));
            return industriesFiltered
          }
      }
  )
}
