import { useState, useEffect, useCallback, useContext } from "react"
import { useProjectScopeByProjectForm } from "@services/forms/forms"
import {
    useRiskCharacteristics,
    useDeleteProjectScopeRiskCharacteristicMutation,
    useProjectScopeRiskCharacteristicMutation,
    useProjectScopeRiskCharacteristics
} from "@services/riskCharacteristics"
import { useSelectionState } from "@hooks/useSelectionState"
import { useCustomToast, useCustomToastV2 } from '@hooks/useCustomToast';
import { ScopingFrameworkAndPerformanceStandardContext } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext";
import { useParams } from 'react-router-dom';
import { useProjectScope } from "../CLAProjectScopeAuditArea/hooks/useProjectScope";
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { useQueryClient } from "@tanstack/react-query";
import { SchemaContext } from '@ais/providers';
import { useSectionScrollHelpersStore } from "../store/sectionScrollHelpers";
import { COMMON_IDENTIFIED_RISKS, RISK_CHARACTERISTICS } from '@constants/forms';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

const PROJECT_FORM_RISKS_QUERY_KEY = 'projectFormRisks'
const PROJECT_SCOPE_FORM_QUERY_KEY = 'projectScopeForm'

export const useProjectRiskCharacteristics = (methodologyVersionId, projectFormId, methodologyIndustries, signOffList, schemaProp = null) => {
    const { schema } = useContext(SchemaContext);
    const { selectedFrameworks, selectedPerformanceStandards } = useContext(ScopingFrameworkAndPerformanceStandardContext)
    const [selectedRisks, setSelectedRisks] = useState([])
    const [projectFormRiskCharacteristic, setProjectFormRiskCharacteristic] = useState([])
    const [selections, deletedSelections, { selectOne, selectAll, isSelected }] = useSelectionState("id", selectedRisks, true)
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const { data: projectScopes, isFetching: fetchingProjectScopes } = useProjectScopeByProjectForm(projectFormId, projectId)
    const { data: projectFormRiskCharacteristicData, isFetching: fetchingProjectFormRiskCharacteristics } = useProjectScopeRiskCharacteristics(projectScopes?.ProjectScopeId, projectId)
    const { data: riskCharacteristics, isFetching: fetchingRiskCharacteristics } = useRiskCharacteristics(methodologyVersionId)
    const {
        mutateAsync: deleteProjectScopeRiskCharacteristic,
        isSuccess: deleteProjectScopeRiskCharacteristicSuccess,
        isLoading: deleteProjectScopeRiskCharacteristicLoading,
        isError: deleteProjectScopeRiskCharacteristicError,
    } = useDeleteProjectScopeRiskCharacteristicMutation(projectId)
    const {
        mutateAsync: createProjectScopeRiskCharacteristic,
        isSuccess: createProjectScopeRiskCharacteristicSuccess,
        isLoading: createProjectScopeRiskCharacteristicLoading,
        isError: createProjectScopeRiskCharacteristicError,
    } = useProjectScopeRiskCharacteristicMutation(projectId)
    const queryClient = useQueryClient();

    const {
        commonRisks,
        setConcurrencyEventReceived,
        concurrencyEventReceived
    } = useProjectScope();

    const setProjectScopeIdInStore = useSectionScrollHelpersStore(state => state.setProjectScopeIdInStore);
    const setProjectFormAuditAreasInStore = useSectionScrollHelpersStore(state => state.setProjectFormAuditAreasInStore);

    // Change initial value to null to track absence or presence of its value
    const [projectFormAuditAreas, setProjectFormAuditAreas] = useState(null);

    useCustomToast({
        isLoading: createProjectScopeRiskCharacteristicLoading || deleteProjectScopeRiskCharacteristicLoading,
        isSuccess: createProjectScopeRiskCharacteristicSuccess || deleteProjectScopeRiskCharacteristicSuccess,
        isError: createProjectScopeRiskCharacteristicError || deleteProjectScopeRiskCharacteristicError
    });

    useCustomToastV2({
        isLoading: createProjectScopeRiskCharacteristicLoading || deleteProjectScopeRiskCharacteristicLoading,
        isSuccess: createProjectScopeRiskCharacteristicSuccess || deleteProjectScopeRiskCharacteristicSuccess,
        isError: createProjectScopeRiskCharacteristicError || deleteProjectScopeRiskCharacteristicError
    });

    const getLatestRiskCharacteristicsTime = (ValidFrom) => {
        return new Date(ValidFrom).getTime()
    }

    const getShouldHighlightValue = useCallback((formattedList, signOffList) => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlightAfterAdd = false
        let shouldHighlightAfterDelete = false
        let isAddedAfterSignoff = false
        let isDeletedAfterSignoff = false
        formattedList?.forEach((auditArea) => {
            const latestAddedRiskCharacteristic = getLatestRiskCharacteristicsTime(auditArea.ValidFrom)
            shouldHighlightAfterAdd = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < latestAddedRiskCharacteristic
            if (shouldHighlightAfterAdd && isSignoffLogicEnabled(isProjectFinalized, latestAddedRiskCharacteristic)) {
                isAddedAfterSignoff = true
            }

            const latestDeletedRiskCharacteristic = getLatestRiskCharacteristicsTime(auditArea.ValidTo)
            shouldHighlightAfterDelete = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < latestDeletedRiskCharacteristic
            if (shouldHighlightAfterDelete && isSignoffLogicEnabled(isProjectFinalized, latestDeletedRiskCharacteristic)) {
                isDeletedAfterSignoff = true
            }

        })
        return isAddedAfterSignoff || isDeletedAfterSignoff
    }, [signOffList])

    const filter = useCallback(() => {
        if (!projectScopes || !riskCharacteristics || !projectFormRiskCharacteristic) {
            return
        }
        let areas = {}
        const projectScopeAuditAreas = projectScopes.AuditAreas

        projectScopeAuditAreas.sort(function (a, b) {
            if (a.DisplayOrder < b.DisplayOrder) return -1;
            if (a.DisplayOrder > b.DisplayOrder) return 1;

            if (a.IsCustom < b.IsCustom) return -1;
            if (a.IsCustom > b.IsCustom) return 1;

            return a.AuditAreaName.localeCompare(b.AuditAreaName)
        });

        const projectScopeScotabds = projectScopes.SCOTABDs
        const preSelected = []
        let filteredRiskCharacteristics = riskCharacteristics.filter((riskCharacteristic) => {
            if (!riskCharacteristic.IsEnabled) {
                return false;
            }
            if (riskCharacteristic.MethodologyIndustries.filter((rca) => methodologyIndustries.includes(rca.MethodologyIndustryId))?.length < 1) {
                return false
            }
            const projectScopeAuditAreasIds = projectScopeAuditAreas.map((psa) => psa.AuditAreaId)
            if (riskCharacteristic.RiskCharacteristicAuditAreas.filter((rca) => projectScopeAuditAreasIds.includes(rca.AuditAreaId))?.length < 1) {
                return false
            }
            const projectScopeScotabdsIds = projectScopeScotabds.map((psa) => psa.SCOTABDId)
            if (riskCharacteristic.SCOTABDs.filter((rca) => projectScopeScotabdsIds.includes(rca.SCOTABDId))?.length < 1) {
                return false
            }
            if (riskCharacteristic.RiskCharacteristicFrameworks.filter((rca) => selectedFrameworks?.includes(rca.FrameworkId))?.length < 1) {
                return false
            }
            if (riskCharacteristic.RiskCharacteristicPerformanceStandards.filter((rca) => selectedPerformanceStandards?.includes(rca.PerformanceStandardId))?.length < 1) {
                return false
            }
            return true
        })
        projectScopeAuditAreas.forEach((auditArea, index) => {
            let filtered = filteredRiskCharacteristics.filter((riskCharacteristic) => {
                return riskCharacteristic?.RiskCharacteristicAuditAreas?.filter((rca) => rca.AuditAreaId === auditArea.AuditAreaId)?.length > 0
            })
            if (filtered.length < 1) {
                return
            }
            const formattedList = filtered.map((riskCharacteristic) => {
                const deleteProjectScopeRiskCharacteristic = projectScopes.deletedProjectScopeRiskCharacteristics.find((dpsrc) => dpsrc.RiskCharacteristicId === riskCharacteristic.RiskCharacteristicId)
                const projectScopeRiskCharacteristic = projectFormRiskCharacteristic.find((formRiskCharacteristic) => formRiskCharacteristic.RiskCharacteristicId === riskCharacteristic.RiskCharacteristicId)
                return ({
                    id: riskCharacteristic.RiskCharacteristicId,
                    name: riskCharacteristic.RiskCharacteristicName,
                    riskCharacteristicId: riskCharacteristic.RiskCharacteristicId,
                    projectScopeRiskCharacteristicId: projectScopeRiskCharacteristic?.ProjectScopeRiskCharacteristicId,
                    selected: !!projectScopeRiskCharacteristic,
                    ValidFrom: projectScopeRiskCharacteristic?.ValidFrom,
                    ValidTo: deleteProjectScopeRiskCharacteristic?.ValidTo
                })
            })
            formattedList.forEach((item) => {
                if (item.selected) {
                    preSelected.push(item)
                }
            })

            const projectScopeScotabdIds = projectScopeScotabds.filter((pss) => auditArea.ProjectScopeAuditAreaId === pss.ProjectScopeAuditAreaId).map(pss => pss.SCOTABDId).flat();
            const riskCharacteristicScotabdIds = filtered.map(f => f.SCOTABDs).flat();

            if (riskCharacteristicScotabdIds.filter(rcs => projectScopeScotabdIds.includes(rcs.SCOTABDId)).length > 0) {
                const areaIndex = Object.keys(areas).find(key => areas[key].id === auditArea.AuditAreaId);

                if (!areas[areaIndex]) {
                    areas[index] = {
                        id: auditArea.AuditAreaId,
                        name: auditArea.AuditAreaName,
                        riskCharacteristics: formattedList,
                        shouldHighlight: getShouldHighlightValue(formattedList, signOffList),
                    }
                }
                else {
                    const auditAreaName = `${areas[areaIndex].name}, ${auditArea.AuditAreaName}`
                    areas[areaIndex] = {
                        id: auditArea.AuditAreaId,
                        name: auditAreaName,
                        riskCharacteristics: formattedList,
                        shouldHighlight: getShouldHighlightValue(formattedList, signOffList),
                    }
                }
            }
        })
        if (preSelected.length < 1) {
            selectAll([])
        }
        setSelectedRisks(preSelected)
        setProjectFormAuditAreas(Object.values(areas))
    }, [projectScopes, riskCharacteristics, projectFormRiskCharacteristic, methodologyIndustries, selectedFrameworks, selectedPerformanceStandards, signOffList]);

    useEffect(() => {
        if (!!projectFormAuditAreas) setProjectFormAuditAreasInStore(projectFormAuditAreas);
    }, [projectFormAuditAreas]);

    /**
     * @returns {string}
     */
    const getCommonIdentifiedRiskSectionId = () => {
        const projectSchema = schemaProp ? schemaProp : schema?.properties;
        const commonIdentifiedRiskSection = projectSchema?.find((section) => section?.title === COMMON_IDENTIFIED_RISKS
            // handle old Project Scoping Form schema which still uses the title 'Risk Characteristics'
            || section?.title === RISK_CHARACTERISTICS);
        return commonIdentifiedRiskSection.id;
    };

    const [isToggleRiskCharacteristicLoading, setIsToggleRiskCharacteristicLoading] = useState(false);

    const toggleRiskCharacteristic = useCallback(async (event, riskCharacteristic, isChecked = null) => {
        setIsToggleRiskCharacteristicLoading(true);
        selectOne(riskCharacteristic)
        if (event?.target.checked || !!isChecked) {
            const formSectionId = getCommonIdentifiedRiskSectionId();

            await createProjectScopeRiskCharacteristic({
                RiskCharacteristicId: riskCharacteristic.riskCharacteristicId,
                ProjectScopeId: projectScopes.ProjectScopeId,
                RequestProjectFormId: projectFormId,
                FormSectionId: formSectionId.toUpperCase()
            })

            queryClient.invalidateQueries([PROJECT_SCOPE_FORM_QUERY_KEY]);
            queryClient.invalidateQueries([PROJECT_FORM_RISKS_QUERY_KEY]);

            setIsToggleRiskCharacteristicLoading(false);

            return;
        }
        await deleteProjectScopeRiskCharacteristic({
            ProjectScopeRiskCharacteristicId: riskCharacteristic.projectScopeRiskCharacteristicId,
            RequestProjectFormId: projectFormId,
            ProjectScopeId: projectScopes.ProjectScopeId
        })
        setConcurrencyEventReceived(false);
        queryClient.invalidateQueries([PROJECT_SCOPE_FORM_QUERY_KEY]);
        queryClient.invalidateQueries([PROJECT_FORM_RISKS_QUERY_KEY]);
        setIsToggleRiskCharacteristicLoading(false);
    }, [selectOne, projectScopes])

    useEffect(() => {
        if ((fetchingProjectScopes || fetchingRiskCharacteristics || fetchingProjectFormRiskCharacteristics) && projectFormRiskCharacteristic) {
            return
        }

        setProjectScopeIdInStore(projectScopes?.ProjectScopeId);

        filter()
    }, [fetchingProjectScopes, fetchingRiskCharacteristics, fetchingProjectFormRiskCharacteristics, projectFormRiskCharacteristic, concurrencyEventReceived, selectedFrameworks, selectedPerformanceStandards, signOffList])



    useEffect(() => {
        if (fetchingProjectFormRiskCharacteristics)
            return
        setProjectFormRiskCharacteristic(projectFormRiskCharacteristicData)
    }, [projectFormRiskCharacteristicData, fetchingProjectFormRiskCharacteristics])

    useEffect(() => {
        if (concurrencyEventReceived) {
            setProjectFormRiskCharacteristic(commonRisks)
        }
    }, [concurrencyEventReceived, commonRisks])

    return [projectFormAuditAreas, { selectOne, isSelected, toggleRiskCharacteristic, isToggleRiskCharacteristicLoading }]
}
