import { Comment, CommentResolved } from '@ais/assets';
import { TailorProcedureContext } from '@components/Forms/state';
import { useUserList } from '@hooks/index';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";

import { CLATooltip } from "@ais/components";
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import {
    PROCEDURE_FIELD
} from '@constants/forms';
import HelpIcon from '@mui/icons-material/Help';
import projectFormServices from "@services/forms/projectforms";
import { formatUtcToLocalTimeZone } from '@utilities/dateHelpers';
import stringHelper from '@utilities/stringHelper';
import styles from './SummaryProcedureSection.module.css';

import { useRoomIdle, useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from "@components/Concurrency/store/users";
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import { useFinalizedProject } from '@hooks/useProject';
import VFRenderedFieldWrapper from '../../../VFRenderedFieldWrapper';
import { useCLAProcedureItem } from './CLAProcedureContexProvider';

const SummaryProcedureStep = (props) => {
    const {
        padding,
        summaryProcedureStep,
        childProcedureSteps,
        currentIndex,
        isParent,
        userId,
        sectionId,
        setSuccessToast,
        setErrorToast,
        setLoadingToast
    } = props;

    const others = useOthers();

    const { projectId, projectFormId } = useParams();
    const { dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const updateMyPresence = useUpdateMyPresence();

    const isIdle = useRoomIdle();

    const [openCommentBox, toggleCommentBox] = useCLAProcedureItem(summaryProcedureStep.SummaryProcedureStepId);
    const [workpaperRef, setWorkpaperRef] = useState('');
    const [comment, setComment] = useState(null);
    const [checked, setChecked] = useState(false); 
    const [signOff, setSignOff] = useState({
        signOffUser: null,
        signOffDate: null
    });
    const [savedWorkRef, setSavedWorkRef] = useState('')

    const CLATextFieldRef = useRef(null);
    const TextFieldCommentRef = useRef(null);
    const {getUserById} = useUserList();

    const focusId = `summary-procedure-step-${summaryProcedureStep.SummaryProcedureStepId}`;
    const isLocked = others.find((user) => user.presence.focusedId === focusId && user.info.userId != userId)
    const lockingUser = isLocked ? { userId: isLocked.info.userId, alternativeName: isLocked.info.name } : undefined

    const handleOnFocusProcedure = () => {
        updateMyPresence({ focusedId: focusId, type: 'custom' })
    } 
    
    const {
        ProcedureStepDescription: procedureStepDescription,
        Assertions: assertions,
        AdditionalGuidance,
        ExternalReference,
        IsSignOffRequired
    } = summaryProcedureStep;
    
    const assertionAcronym = !assertions ? [] : assertions.map((assertion) => {
        return assertion.AssertionAcronym.trim();
    });

    const assertionName = !assertions ? [] : assertions.map((assertion) => {
        return assertion.AssertionName.trim();
    });

    const extractTextOrUrl= (str) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');
        const pTags = doc.getElementsByTagName('p')
        const data = [];
        const urlRegex = /(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?/;

        const findUrlsInNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const pUrl = node.textContent.match(urlRegex);
                if (pUrl) {
                    const url = stringHelper.ensureHttpProtocol(pUrl[0]);
                    return `<a target="_blank" href="${url}" rel="noopener noreferrer" style="color:#0d6efd">${url}</a>`;
                }
                return node.textContent;
            } else if (node.nodeName === 'A') {
                let url = node.href.replace(`${node.baseURI}/`, '');
                node.baseURI.split('/').forEach(urlChunk => {
                    url = url.replace(urlChunk, '');
                });
                url = stringHelper.removeExtraSlashes(url);
                url = stringHelper.ensureHttpProtocol(url);
                return `<a target="_blank" href="${url}" rel="noopener noreferrer" style="color:#0d6efd">${node.textContent}</a>`;
            }
            return Array.from(node.childNodes).map(findUrlsInNode).join('');
        };
    
        for (let i = 0; i < pTags.length; i++) {
            const p = pTags[i];
            const content = findUrlsInNode(p);
            data.push(`<span key={"p_${i}"} style="display: inline; margin: 0;">${content}</span>`);
        }
        return data
    };

    const externalReferences = extractTextOrUrl(ExternalReference).map((e, index) => (<span key={index} dangerouslySetInnerHTML={{__html:e}}/>));
    const isProjectFinalized = useFinalizedProject(projectId);

    useEffect(async () => {
        if (summaryProcedureStep?.Comment) {
            setComment(summaryProcedureStep.Comment);
        }
        else {
            setComment('');
        }

        if (summaryProcedureStep?.WorkpaperReference) {
            setWorkpaperRef(summaryProcedureStep.WorkpaperReference)
            setSavedWorkRef(summaryProcedureStep.WorkpaperReference)
        }
        else {
            setWorkpaperRef('');
        }

        if (summaryProcedureStep?.SignOffUser) {
            setChecked(true);
            const { firstName = '', lastName = '', employeeId = null } = await getUserById(summaryProcedureStep.SignOffUser);
            setSignOff({
                signOffUser: `${firstName} ${lastName ? lastName[0] : ''}. ` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : ''),
                signOffDate: formatUtcToLocalTimeZone(summaryProcedureStep.SignOffDate),
            })
        } else {
            setChecked(false);
            setSignOff({
                signOffUser: null,
                signOffDate: null
            })
        }
    }, [summaryProcedureStep]);

    const handleWorkpaperReferenceSave = async (e) => {
        updateMyPresence({ focusedId: null, type: 'custom' });

        if(isIdle) return;
        
        if(savedWorkRef !== e.target.value){ //check if WorkPaper reference changed
            setLoadingToast(true)
            if (summaryProcedureStep?.ProjectFormProcedureStepId) {
                try {
                    const workpaperReference = e.target.value;
                    const payload = {
                        WorkPaperReference: workpaperReference,
                        SignOffDate: summaryProcedureStep?.SignOffDate ?? null,
                        SignOffUser: summaryProcedureStep?.SignOffUser ?? null,
                    };
                    await projectFormServices.updateProjectFormProcedureStep(projectId, projectFormId, summaryProcedureStep?.ProjectFormProcedureStepId, payload);
                    setSavedWorkRef(workpaperReference)
                    setErrorToast(false)
                    setSuccessToast(true)
                    setLoadingToast(false)
                } catch (error) {
                    setErrorToast(true)
                    setSuccessToast(false)
                    setLoadingToast(false)
                }
            }
        }
    }

    const handleCommentSave = async (e) => {
        updateMyPresence({ focusedId: null, type: 'custom' });

        if(isIdle) return;
        if (summaryProcedureStep?.ProjectFormProcedureStepId) {
            setLoadingToast(true)
            try {
                const comment = e.target.value;
                const payload = {
                    Comment: comment,
                    SignOffDate: summaryProcedureStep?.SignOffDate ?? null,
                    SignOffUser: summaryProcedureStep?.SignOffUser ?? null,
                };
                await projectFormServices.updateProjectFormProcedureStep(projectId, projectFormId, summaryProcedureStep?.ProjectFormProcedureStepId, payload);
                setErrorToast(false)
                setSuccessToast(true)
                setLoadingToast(false)
            } catch (error) {
                setErrorToast(true)
                setSuccessToast(false)
                setLoadingToast(false)
            }
        }
    };

    const saveFormSignOff = async (checked) => {
        const payload = {
            SignOffUser: checked ? userId.trim() : null,
            WorkPaperReference: workpaperRef,
            Comment: comment
        };

        updateMyPresence({ focusedId: null, type: 'custom' });
        
        const { status, data } = await projectFormServices.updateProjectFormProcedureStep(projectId, projectFormId, summaryProcedureStep?.ProjectFormProcedureStepId, payload);

        if (status !== 200) return;

        const summaryProcedureStepExists = data?.find(step => step.SummaryProcedureStepId === summaryProcedureStep.SummaryProcedureStepId && step?.ProjectFormProcedureStepId === summaryProcedureStep?.ProjectFormProcedureStepId)

        if (!summaryProcedureStepExists) return;

        const newSummaryProcedureStep = { ...summaryProcedureStep };
        newSummaryProcedureStep.SignOffDate = summaryProcedureStepExists.SignOffDate;
        newSummaryProcedureStep.SignOffUser = summaryProcedureStepExists.SignOffUser;
        newSummaryProcedureStep.WorkpaperReference = summaryProcedureStepExists.WorkPaperReference;
        newSummaryProcedureStep.Comment = summaryProcedureStepExists.Comment;

        let signOffUserName = null;
        let signOffUserDate = null;
        if (checked) {
            const { firstName = '', lastName = '', employeeId = null } = await getUserById(newSummaryProcedureStep.SignOffUser);
            signOffUserName = `${firstName} ${lastName ? lastName[0] : ''}. ` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : '');
            signOffUserDate = formatUtcToLocalTimeZone(newSummaryProcedureStep.SignOffDate)
        }
         
        setSignOff({
            signOffUser: signOffUserName,
            signOffDate: signOffUserDate
        })
        setChecked(checked);
        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPDATE_SUMMARY_PROCEDURE,
            payload: {
                summaryProcedureStep: newSummaryProcedureStep,
                sectionId: sectionId
            }
        });

        return {
            workpaperRef: newSummaryProcedureStep.WorkpaperReference,
            comment: newSummaryProcedureStep.Comment,
            signOff: {
                signOffUser: signOffUserName,
                signOffDate: signOffUserDate,
            },
            checked,
            projectFormProcedureStepId: summaryProcedureStep?.ProjectFormProcedureStepId
        };
    };

    const handleCheckSave = async (e) => {
        setLoadingToast(true)
        const checked = e.target.checked;
        if (summaryProcedureStep?.ProjectFormProcedureStepId) {
            try {
                await saveFormSignOff(checked);

                // Special handling of save when triggered via signing off a step
                // Manually plugs all required data from saveFormSignOff because data of signOff state not
                // updated yet.
                setErrorToast(false)
                setSuccessToast(true)
                setLoadingToast(false)
            } catch (error) {
                setErrorToast(true)
                setSuccessToast(false)
                setLoadingToast(false)
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <VFRenderedFieldWrapper
                isLockedByUser={lockingUser}
                isLocked={isLocked}
                isInstance
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: '20px',
                    fontSize: isParent ? '14px' : '12px',
                    fontWeigth: 700
                }}>
                    <Box sx={{
                        width: '60%',
                        display: 'flex',
                        flexDirection: 'column',
                        pl: `${padding}px`,
                    }}>
                        <Box>
                        <span className="ql-editor" style={{ paddingRight: '10px' }} dangerouslySetInnerHTML={{__html: `<p>${currentIndex}.${procedureStepDescription}</p>`}}></span>
                            {(AdditionalGuidance !== '' && !!AdditionalGuidance) && <CLATooltip title={
                                <span dangerouslySetInnerHTML={{ __html: AdditionalGuidance }} />
                              }
                             placement="top">
                                <HelpIcon style={{ fontSize: '15px' }} />
                            </CLATooltip>}
                        </Box>
                    </Box>
                    <Box className={styles.tableCenter__cell} sx={{ textAlign: 'center' }}>
                        <CLATooltip title={assertionName.join(', ')} placement="top">
                            <Typography sx={{
                                overflowWrap: 'break-word',
                                color: '#4B4B4B'
                            }}>
                                {assertionAcronym.join(', ')}
                            </Typography>
                        </CLATooltip>
                    </Box>
                    <Box sx={{
                        display:'flex', width: '10%'
                    }}>
                        {IsSignOffRequired && <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => handleCheckSave(e)}
                                    disabled={isLocked || isProjectFinalized}                                
                                    onFocus={handleOnFocusProcedure}
                                />
                            }
                            label={<Typography component='p' sx={{ fontSize: '0.7rem' }}>
                                {checked ?
                                    <span>
                                        {signOff.signOffUser}
                                        <br />
                                        {signOff.signOffDate}
                                    </span> : ''}
                            </Typography>}
                            labelPlacement="end"
                        />}
                    </Box>
                    <Box className={styles.tableCenter__cell}>
                        <CLATextField
                            ref={CLATextFieldRef}
                            label=""
                            onChange={(e) => setWorkpaperRef(e.target.value)}
                            onBlur={handleWorkpaperReferenceSave}
                            value={workpaperRef}
                            inputLableShrink={true}
                            placeholder={PROCEDURE_FIELD.WORKPAPER_REF}
                            disabled={isLocked || isProjectFinalized}                                
                            onFocus={handleOnFocusProcedure} />
                    </Box>
                    <Box className={styles.tableCenter__cell}
                        sx={{
                            pr: '65px',
                            justifyContent: 'end'
                        }}>
                        <IconButton
                            sx={{ padding: 0 }}
                            disabled={isLocked}                            
                            onClick={toggleCommentBox}
                        >
                            {comment ? <CommentResolved /> : <Comment />}
                        </IconButton>
                    </Box>
                </Box>
                {
                  openCommentBox  && <Box sx={{ p: `20px 65px 10px ${padding}px` }}> 
                    <TextField
                        ref={TextFieldCommentRef}
                        label={PROCEDURE_FIELD.COMMENT}
                        fullWidth
                        multiline
                        placeholder="Enter comment"
                        rows={2}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onBlur={handleCommentSave}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            notched: true
                        }}
                        inputProps={{
                            maxLength: 4096
                        }}
                        disabled={isLocked || isProjectFinalized}                                
                        onFocus={handleOnFocusProcedure}
                    />
                    </Box>
                }
                {
                    externalReferences.length > 0 && <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: isParent ? '14px' : '12px',
                        pl: `${padding}px`
                    }}>
                        <span style={{ fontWeight: '700' }}>{PROCEDURE_FIELD.EXTERNAL_REFERENCES}</span>
                        {externalReferences}
                    </Box>
                }
            </VFRenderedFieldWrapper>
            <Box>
                {childProcedureSteps}
            </Box>
        </Box >
    )
};

export default SummaryProcedureStep;