import { createContext, useContext, useEffect, useState } from "react"

const GraphContext = createContext()

export const GraphContextProvider = ({ projectForm, children }) => {
    const [selectedGraphs, setSelectedGraphs] = useState([])
    const [selectedPriorPeriod, setSelectedPriorPeriod] = useState([])
    const [refetchComments, setRefetchComments] = useState(false);
    const [trialBalances, setTrialBalances] = useState();

    useEffect(() => {
        if(projectForm){
            const schemaWithSelectedPeriod = projectForm.schema.find((schema) => schema.selectedPeriods ? true : false)
            const schemaWithSelectedGraph = projectForm.schema.find((schema) => schema.selectedGraphs ? true : false)
            if(schemaWithSelectedPeriod){
                setSelectedPriorPeriod(schemaWithSelectedPeriod.selectedPeriods ?? []);
            }
            if(schemaWithSelectedGraph){
                setSelectedGraphs(schemaWithSelectedGraph.selectedGraphs ?? [])
            }
        }
    }, [projectForm])

    return (
        <GraphContext.Provider value={{ 
            selectedGraphs, 
            selectedPriorPeriod, 
            refetchComments,
            trialBalances,
            setSelectedPriorPeriod,
            setSelectedGraphs,
            setRefetchComments,
            setTrialBalances,
        }}>
            { children }
        </GraphContext.Provider>
    )
}

export const useGraphContext = () => {
    const ctx = useContext(GraphContext)
    if(!ctx) {
        throw new Error("`useGraphContext` must be used within GraphContextProvider")
    }
    return ctx
}