import { CLANestedDataGrid } from '@ais/components';
import React, { useState, useEffect } from 'react'
import styles from './styles'
import CustomKeyControl from './CustomKeyControl';

const ScotabdsTable = ({ projectScopeAuditAreaId, scotabds, isProjectFinalized, signOffList, userId = null }) => {
  const [rows, setRows] = useState([])
  const [expandedRows, setExpandedRows] = useState([]);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  const columns = [
    {
      field: 'ClassOfTransactionName',
      headerName: 'SCOTABDs',
      flex: 1,
      minWidth: 530,
      maxWidth: 530,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderCell: params => (<span style={{ fontWeight: '700', fontSize: '1.2em' }}>{params.row.ClassOfTransactionName}</span>)
    },
    {
      field: 'AssertionList',
      headerName: 'Relevant Assertions',
      flex: 1,
      minWidth: 530,
      maxWidth: 530,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => <span style={{ paddingLeft: '50px', fontWeight: '700' }}>Relevant Assertions</span>,
      renderCell: params => (<span style={{ fontWeight: '600', fontSize: '1.2em', paddingLeft: '50px' }}>{params.row.AssertionList || ""}</span>)
    }
  ]

  useEffect(() => {
    if (!scotabds || scotabds?.length < 1) {
      setRows([])
      return
    }
    const __scotabds = scotabds.map((scotabd, index) => {
      return {
        ...scotabd,
        id: index++,
        AssertionList: scotabd?.AssertionList
          ? scotabd?.AssertionList.map(assertion => assertion?.AssertionName)?.join(', ')
          : ''
        ,
        ExpandedPanel: (
          <CustomKeyControl
            projectScopeAuditAreaId={projectScopeAuditAreaId}
            projectScopeAuditAreaSCOTABDId={scotabd.ProjectScopeAuditAreaSCOTABDId}
            projectKeyControls={scotabd
              ?.ProjectScopeAuditAreaSCOTABDProjectKeyControl
              ?.map(({ProjectKeyControl, IsCoversOtherRelativeAssertion}) => ({ProjectKeyControl, IsCoversOtherRelativeAssertion})) ?? []}
            assertionList={scotabd?.AssertionList ?? []}
            setRows={setRows}
            rowIndex={index}
            isProjectFinalized={isProjectFinalized}
            signOffList={signOffList}
            userId={userId}
          />
        )
      }
    })

    /** Fetch all scotabds with ProjectScopeAuditAreaSCOTABDProjectKeyControl */
    const filteredScotabds = __scotabds.filter((data) => {
      return data.ProjectScopeAuditAreaSCOTABDProjectKeyControl && data.ProjectScopeAuditAreaSCOTABDProjectKeyControl.length > 0
    })
    setRows(filteredScotabds)
  }, [scotabds, signOffList])

  return (
    <div style={{ padding: "10px 0px 0px 3.4em" }}>
      <CLANestedDataGrid
        sx={styles}
        rows={rows}
        columns={columns}
        autoHeight={true}
        hideFooterRowCount={true}
        showFooterContent={false}
        hideFooterPagination={true}
        headerHeight={65}
        hideFooter={true}
        expandedRow={expandedRows}
        handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      />
    </div>
  )
}

export default ScotabdsTable