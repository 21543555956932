import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import projectFormServices from '@services/forms/projectforms';
import { useLoading } from '@hooks/index';
import { CLADialogConfirm } from "@ais/components";
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { useProjectFormInstanceProviderV2 } from '@providers';

import { VFDraggableInstanceField } from './VFDraggableInstanceField';
import { VFDroppableExistingField } from './VFDroppableExistingField';

const { MODAL: { DELETE: MODAL_DELETE }, BUTTONS } = PROJECT_FORM_INSTANCE.EN;

const NATGFieldWrapper = ({ children, field, section = {}, sectionIndex, rowIndex }) => {
    const { isFormInstanceQuestion, rules } = field;
    const { id: sectionId } = section; 
    const { sectionOnEditView, deleteModalData, actions: { updateSchema, handleDeleteModalData } } = useProjectFormInstanceProviderV2();
    const { projectId, projectFormId } = useParams();
    const setLoading = useLoading();
    const isEditMode = sectionOnEditView === sectionId;

    const deleteQuestion = async () => {
        setLoading(true);  
        const response = await projectFormServices.deleteInstanceQuestionById(
            projectId,
            projectFormId,
            deleteModalData.fieldId
        );
        if (response.status === 200) {
            const newSection = response.data.section;
            updateSchema((prevSchema) => {
                return prevSchema.map((data) => {
                    if (data.id.toLowerCase() === newSection.id.toLowerCase()) {
                        return newSection
                    }
                    return data;
                })

            }) 
        }
        handleDeleteModalData({ visible: false, fieldId: null })
        setLoading(false);
    } 

    const saveFields = async (newRowIndex, fieldToSave) => {
        try {
            await projectFormServices.updateQuestionToProjectFormSchema(
                projectId,
                projectFormId,
                sectionId,
                newRowIndex,
                fieldToSave
            );
        } catch (error) {
            // * Show an error here
        }
    };

    const handleDeleteField = (id) => {
        handleDeleteModalData({ visible: true, fieldId: id });
    } 

    return (
        <>
            {isFormInstanceQuestion && isEditMode && (
                <VFDraggableInstanceField
                    field={field}
                    section={section}
                    sectionIndex={sectionIndex}
                    rowIndex={rowIndex}
                    handleDeleteField={handleDeleteField}
                    saveFields={saveFields}
                >
                    {children}
                </VFDraggableInstanceField>
            )}
            {isEditMode && !isFormInstanceQuestion && (
                <VFDroppableExistingField
                    field={field}
                    section={section}
                    sectionIndex={sectionIndex}
                    rowIndex={rowIndex}
                    isEditMode={isEditMode}
                    rules={rules}
                    handleDeleteField={handleDeleteField}
                    saveFields={saveFields}
                >
                    {children}
                </VFDroppableExistingField>
            )}
            {!isEditMode && (children)}
            <CLADialogConfirm
                visible={deleteModalData.visible}
                title={MODAL_DELETE.HEADER}
                cancelText={BUTTONS.CANCEL}
                confirmText={BUTTONS.DELETE}
                message={MODAL_DELETE.DELETE_MESSAGE}
                onConfirm={deleteQuestion}
                onCancel={() => handleDeleteModalData({ visible: false, fieldId: null })}
            />
        </>
    );
}

export default memo(NATGFieldWrapper);
