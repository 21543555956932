import { useContext } from 'react' 

import { RESERVED_SECTION_IDS } from '@ais/constants';

import { PlanningAnalyticsContext } from "@contexts/PlanningAnalytics/PlanningAnalyticsContext"

export const usePlanningAnalytics = () => {
  const { ANALYTICS: {
    GENERAL_LEDGER,
    CURRENT_PERIOD,
    TRENDING_BY_MONTH
  }} = RESERVED_SECTION_IDS;

  const {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    comment,
    setComment,
    isGeneralLedgerExpanded,
    isCurrentPeriodExpanded,
    isTrendingByMonthExpanded,
    isLoadCurrentPeriodSection,
    actions,
  } = useContext(PlanningAnalyticsContext)

  const updateNonCustomFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  };

  const updateAnalyticsSectionExpanded = (sectionId, isExpanded) => {
    switch(sectionId) {
      case GENERAL_LEDGER:
        actions.updateIsGeneralLedgerExpanded(isExpanded);
        return;
      case CURRENT_PERIOD:
        actions.updateIsCurrentPeriodExpanded(isExpanded);
        return;
      case TRENDING_BY_MONTH:
        actions.updateIsTrendingByMonthExpanded(isExpanded);
        return;
      default:
        return;
    }
  };

  return {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    updateNonCustomFieldAnswer,
    comment,
    setComment,
    isGeneralLedgerExpanded,
    isCurrentPeriodExpanded,
    isTrendingByMonthExpanded,
    isLoadCurrentPeriodSection,
    actions: {...actions, updateAnalyticsSectionExpanded}
  }
}