import {DRAG_TYPES, FIELD_TYPES} from '@ais/constants';

export const RISK_ASSESMENT_PROCEDURE_ID = 5

export const TRIAL_BALANCE_DROPDOWN_FIELD_ID = "TRIAL-BALANCE-FIELD-ID-1"
export const TRENDING_BY_MONTH_FIELD_QUESTION_ID = "TRENDING_BY_MONTH-FIELD-ID-0"
export const PLANNING_ANALYTICS_IDS = {
  GLC: {
    LONGANSWER_ID: '0142bfff-c7d2-4b79-832f-0091cbf08a56',
    RADIOGROUP_IDS: ['a0b25f15-db65-4dbd-883e-33ba42dca581', '2329c1bb-368c-40d6-beda-3573ad72ae40']
  },
  CPP: {
    LONGANSWER_ID: '4db6da62-c4cd-4d8c-9756-8a7b60f45df8',
    RADIOGROUP_IDS: ['2329c1bb-368c-40d6-beda-3573ad72ac39', '6f3201ec-eb69-429d-96cc-2b80081484cf']
  },
  TRENDING_BY_MONTH: {
    LONGANSWER_ID: '533137cd-c238-47b1-80e6-3fd71625116c',
    RADIOGROUP_IDS: ['36fdd3c3-3fca-40d3-8e1b-adc82bc29ab2', 'f7184d4b-0d30-4143-ab9c-0da7fabe21f6']
  }
}
export const TRIAL_BALANCE_SECTION = {
  id: "TRIAL-BALANCE-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_BLANK,
  fields: [
    [
      {
        type: FIELD_TYPES.TRIAL_BALANCE,
        id: TRIAL_BALANCE_DROPDOWN_FIELD_ID,
        index: 0,
        text: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
        rowIndex: 0,
      }
    ],
  ],
  text: "Custom component enabled. To preview, click 'View Form'",
  title: "",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const GENERAL_LEDGER_COMPLETENESS_1 = {
  id: "GENERAL-LEDGER-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.RADIOGROUP,
        id: "GENERAL-LEDGER-FIELD-ID-0",
        index: 0,
        label: "Were the procedures performed somewhere else?",
        required: true,
        columns: 6,
        options: [
          "Yes",
          "No"
        ],
        allowOtherOption: false,
        tooltip: "",
        width: 30,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        defaultValue: "No",
        defaultValues: [],
        sectionIndex: 0,
        rowIndex: 0
      }
    ],
    [
      {
        type: FIELD_TYPES.SHORT_ANSWER,
        id: "GENERAL-LEDGER-FIELD-ID-1",
        index: 0,
        label: "Workpaper Reference",
        required: true,
        validation: "none",
        placeholder: "Enter Workpaper Reference",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "GENERAL-LEDGER-CRITERIA-ID-0",
              criteriaType: "question",
              questionId: "GENERAL-LEDGER-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "Yes"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      }
    ],
    [
      {
        type: FIELD_TYPES.GENERAL_LEDGER,
        id: "GENERAL-LEDGER-FIELD-ID-1",
        index: 0,
        text: "General ledger completness table is enabled. To preview, click 'View Form'",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "GENERAL-LEDGER-CRITERIA-ID-0",
              criteriaType: "question",
              questionId: "GENERAL-LEDGER-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      }
    ]
  ],
  text: "Custom component enabled. To preview, click 'View Form'",
  title: "General Ledger Completeness",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: false,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const GENERAL_LEDGER_COMPLETENESS_2 = {
  id: "GENERAL-LEDGER-SECTION-ID-2",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.FREETEXT,
        id: "GENERAL-LEDGER-2-FIELD-ID-0",
        index: 0,
        text: "<p>General Ledger rolled forward successfully.</p>",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
        sectionIndex: 2,
        rowIndex: 0,
      },
    ],
  ],
  title: "General Ledger Completeness",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const CURRENT_PERIOD_TO_PRIOR = {
  id: "CURRENT_PERIOD_TO_PRIOR-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.RADIOGROUP,
        id: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-0",
        index: 0,
        label: "Were the analytics performed somewhere else?",
        required: true,
        columns: 6,
        options: [
          "Yes",
          "No"
        ],
        allowOtherOption: false,
        tooltip: "",
        width: 30,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        defaultValue: "No",
        defaultValues: [],
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.SHORT_ANSWER,
        id: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-1",
        index: 0,
        label: "Workpaper Reference",
        required: true,
        validation: "none",
        placeholder: "Enter Workpaper Reference",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "CURRENT_PERIOD_TO_PRIOR-CRITERIA-ID-1",
              criteriaType: "question",
              questionId: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "Yes"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.LONG_ANSWER,
        id: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-2",
        index: 0,
        label: "Expectations",
        required: true,
        validation: "none",
        placeholder: "Please fill out this section before you move on to the next section",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        minRows: 3,
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "CURRENT_PERIOD_TO_PRIOR-CRITERIA-ID-2",
              criteriaType: "question",
              questionId: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.CURRENT_PERIOD_TO_PRIOR,
        id: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-3",
        index: 0,
        text: "Current period to period table is enabled. To preview, click 'View Form'",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "CURRENT_PERIOD_TO_PRIOR-CRITERIA-ID-3",
              criteriaType: "question",
              questionId: "CURRENT_PERIOD_TO_PRIOR-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        }
      }
    ]
  ],
  text: "Custom component enabled. To preview, click 'View Form'",
  title: "Current Period to Prior Period",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const TRENDING_BY_MONTH = {
  id: "TRENDING_BY_MONTH-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.RADIOGROUP,
        id: "TRENDING_BY_MONTH-FIELD-ID-0",
        index: 0,
        label: "Were the analytics performed somewhere else?",
        required: true,
        columns: 6,
        options: [
          "Yes",
          "No"
        ],
        allowOtherOption: false,
        tooltip: "",
        width: 30,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        defaultValue: "No",
        defaultValues: [],
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.SHORT_ANSWER,
        id: "TRENDING_BY_MONTH-FIELD-ID-1",
        index: 0,
        label: "Workpaper Reference",
        required: true,
        validation: "none",
        placeholder: "Enter Workpaper Reference",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "TRENDING_BY_MONTH-CRITERIA-ID-1",
              criteriaType: "question",
              questionId: "TRENDING_BY_MONTH-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "Yes"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.LONG_ANSWER,
        id: "TRENDING_BY_MONTH-FIELD-ID-2",
        index: 0,
        label: "Expectations",
        required: true,
        validation: "none",
        placeholder: "",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        minRows: 3,
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "TRENDING_BY_MONTH-CRITERIA-ID-2",
              criteriaType: "question",
              questionId: "TRENDING_BY_MONTH-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.TRENDING_BY_MONTH,
        id: "TRENDING_BY_MONTH-FIELD-ID-3",
        index: 0,
        text: "Trending by month graphs is enabled. To preview, click 'View Form'",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "TRENDING_BY_MONTH-CRITERIA-ID-3",
              criteriaType: "question",
              questionId: "TRENDING_BY_MONTH-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        }
      }
    ],
  ],
  text: "Custom component enabled. To preview, click 'View Form'",
  title: "Trending by Month",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const KPIS_RATIOS = {
  id: "KPIS_RATIOS-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.RADIOGROUP,
        id: "KPIS_RATIOS-FIELD-ID-0",
        index: 0,
        label: "Were the analytics performed somewhere else?",
        required: true,
        columns: 6,
        options: [
          "Yes",
          "No"
        ],
        allowOtherOption: false,
        tooltip: "",
        width: 30,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        defaultValue: "No",
        defaultValues: [],
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.SHORT_ANSWER,
        id: "KPIS_RATIOS-FIELD-ID-1",
        index: 0,
        label: "Workpaper Reference",
        required: true,
        validation: "none",
        placeholder: "Enter Workpaper Reference",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "KPIS_RATIOS-CRITERIA-ID-1",
              criteriaType: "question",
              questionId: "KPIS_RATIOS-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "Yes"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.LONG_ANSWER,
        id: "KPIS_RATIOS-FIELD-ID-2",
        index: 0,
        label: "Expectations",
        required: true,
        validation: "none",
        placeholder: "",
        defaultValue: "",
        defaultValues: [],
        tooltip: "",
        minRows: 3,
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "KPIS_RATIOS-CRITERIA-ID-2",
              criteriaType: "question",
              questionId: "KPIS_RATIOS-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        },
        sectionIndex: 0,
        rowIndex: 0
      },
    ],
    [
      {
        type: FIELD_TYPES.KPIS_RATIOS,
        id: "KPIS_RATIOS-FIELD-ID-3",
        index: 0,
        text: "KPIs/Ratios graphs is enabled. To preview, click 'View Form'",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [
            {
              id: "KPIS_RATIOS-CRITERIA-ID-3",
              criteriaType: "question",
              questionId: "KPIS_RATIOS-FIELD-ID-0",
              isExternalQuestion: false,
              any: false,
              isEqual: true,
              value: [
                "No"
              ]
            }
          ],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true
        }
      }
    ],
  ],
  text: "Custom component enabled. To preview, click 'View Form'",
  title: "KPIs/Ratios",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const PRIOR_PERIOD_COMPARISON = {
  dropdown: {
    placeholder: "Select"
  },
  categoryLevelPlaceholder: "CATEGORY LEVEL PLACEHOLDER",
  transactionLevelPlaceholder: "TRANSACTION LEVEL PLACEHOLDER",
  commentInputLabel: "Comment"
}

export const EXPORT_GL_DETAILS = {
  EN: {
    LINK: 'Export GL Detail',
    MODAL_DETAILS: {
      TITLE: 'Export GL Detail',
      MESSAGE: 'records have been found',
      WARNING_MESSAGE: 'Warning! Your file has more than 1 million records, you will need a tool other than MS Excel to analyze the data.',
      BUTTON: {
        CONFIRM: 'Export CSV',
        CANCEL: 'Cancel'
      }
    },
    FILE_NAME: 'GL-Detail',
    FILE_TYPE: '.csv',
    MAX_RECORDS: 1000000, // 1 million records
    COLUMNS: [
      {
        id: 'TrialBalanceName',
        displayName: 'Trial Balance'
      },
      {
        id: 'AccountNumber',
        displayName: 'Account Number'
      },
      {
        id: 'AccountDescription',
        displayName: 'Account Description'
      },
      {
        id: 'TransactionDate',
        displayName: 'Transaction Date'
      },
      {
        id: 'TransactionReference',
        displayName: 'Transaction Reference'
      },
      {
        id: 'TransactionDescription',
        displayName: 'Transaction Description'
      },
      {
        id: 'TransactionDebitAmount',
        displayName: 'Debit Amount'
      },
      {
        id: 'TransactionCreditAmount',
        displayName: 'Credit Amount'
      }
    ]
  }
}