export const SERVICE_TYPE = {
  EN: {
    TITLE: 'Service Type',
    EXPORT: 'Export',
    EDIT: 'Edit',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    ADD_ANOTHER: 'ADD ANOTHER SERVICE TYPE',
    HEADER_TOOLTIP: {
      CLA_SERVICE_TYPE: 'Select all CLA Service Types that apply to the AIS Service Type being created. Note: that each CLA Service Type can only be associated to one AIS Service Type.',
      RISK_ASSESSMENT: 'This selection cannot be changed after the initial draft methodology version for a service type has been released.',
      LIST_LIBRARY: 'Some of these lists may only be selected if the Risk Assessment has been turned on for the service type. Other lists may not be turned off as they will be required for all service types.',
      PROCEDURES: 'The Procedures Library may only be selected if the Project Areas list in the List Library has been turned on for the Service Type.',
      PROCEDURE_STEP_ATTRIBUTES: 'These attributes may only be selected if the Procedures Library has been turned on for the Service Type.'
    },
    ERRORS: {
      AIS_SERVICE_TYPE_REQUIRED: 'AIS Service Type name is required',
      AIS_SERVICE_TYPE_UNIQUE: 'AIS Service Type name must be unique',
      CLA_SERVICE_TYPE_REQUIRED: 'CLA Service Type name is required',
    },
    LIST_LIBRARY_OPTIONS: [
      { label: 'Account Balances', value: 'IsAccountBalanceEnabled' },
      { label: 'Assertions', value: 'IsAssertionEnabled' },
      { label: 'Common Identified Risks', value: 'IsCommonIdentifiedRiskEnabled' },
      { label: 'Default Risks', value: 'IsDefaultRiskEnabled' },
      { label: 'Event Conditions', value: 'IsEventConditionEnabled' },
      { label: 'Financial Statement Line Items', value: 'IsFinancialStatementLineItemEnabled' },
      { label: 'Frameworks', value: 'IsFrameworkEnabled' },
      { label: 'Industries', value: 'IsIndustryEnabled' },
      { label: 'Key Deliverables', value: 'IsKeyDeliverablesEnabled' },
      { label: 'Other Characteristics', value: 'IsOtherCharacteristicEnabled' },
      { label: 'Performance Standards', value: 'IsPerformanceStandardEnabled' },
      { label: 'Project Areas', value: 'IsAuditAreaEnabled' },
      { label: 'Project Dashboard Categories', value: 'IsProjectDashboardCategoryEnabled' },
      { label: 'Project Management Categories', value: 'IsProjectManagementCategoryEnabled' },
      { label: 'Risk Factors', value: 'IsRiskFactorEnabled' },
      { label: 'SCOTABDs', value: 'IsSCOTABDEnabled' },
    ],
    PROCEDURE_STEPS_OPTIONS: [
      { label: 'Purpose', value: 'IsProcedureStepPurposeEnabled' },
      { label: 'Nature of Step or Sub-Step', value: 'IsProcedureStepNatureEnabled' },
      { label: 'Timing', value: 'IsProcedureStepTimingEnabled' },
      { label: 'Dependency', value: 'IsProcedureStepDependencyEnabled' },
    ],
    DEFAULT_ROW_VALUE: {
      ServiceType: {
        ServiceTypeName: '',
        IsEnabled: true,
        IsRiskAssessmentApplied: false,
      },
      ProjectTypeNames: [],
      ServiceTypeConfiguration: {
        ServiceTypeId: null,
        ServiceTypeConfigurationVersionId: null,
        IsAccountBalanceEnabled: true,
        IsAssertionEnabled: false,
        IsAuditAreaEnabled: true,
        IsCommonIdentifiedRiskEnabled: false,
        IsDefaultRiskEnabled: false,
        IsEnabled: false,
        IsEventConditionEnabled: false,
        IsFinancialStatementLineItemEnabled: true,
        IsFrameworkEnabled: true,
        IsIndustryEnabled: true,
        IsKPIFormEnabled: false,
        IsKeyDeliverablesEnabled: true,
        IsOtherCharacteristicEnabled: false,
        IsPerformanceStandardEnabled: true,
        IsProcedureLibraryEnabled: false,
        IsProcedureStepDependencyEnabled: false,
        IsProcedureStepNatureEnabled: false,
        IsProcedureStepPurposeEnabled: false,
        IsProcedureStepTimingEnabled: false,
        IsProjectDashboardCategoryEnabled: true,
        IsProjectManagementCategoryEnabled: true,
        IsRiskFactorEnabled: false,
        IsSCOTABDEnabled: false
      }
    }
  }
}
export const SERICETYPE_TABLE_MOCK_DATA = {
  data: [
    {
      "id": 1,
      "ServiceTypeId": 12305,
      "AISServiceTypeName": "Audit",
      "CLAServiceTypeName": "Audit and International Audit",
      "Methodology": {
        "MethodologyVersionId": 484,
        "MethodologyVersionStatusId": 1
      },
      "ListLibrary": [
        {
          "ListLibraryName": "List Library Name 1",
          "ListLibraryId": 14931
        },
        {
          "ListLibraryName": "List Library Name 2",
          "ListLibraryId": 12345
        }
      ],
      "ProcedureStepAttributes": [
        {
          "ProcedureStepAttributesName": "Item 1",
          "ProcedureStepAttributesId": 1232
        },
        {
          "ProcedureStepAttributesName": "Item 2",
          "ProcedureStepAttributesId": 149234131
        }
      ],
      "IsRiskAssessmentApplied": true,
      "Procedures": true,
      "KPIInput": true,
      "Enabled": true,
      "MethodologyVersionId": 484
    },
    {
      "id": 2,
      "ServiceTypeId": 112233,
      "AISServiceTypeName": "AIS Service Type 2",
      "CLAServiceTypeName": "CLA Service Type 2",
      "Methodology": {
        "MethodologyVersionId": 555,
        "MethodologyVersionStatusId": 2
      },
      "ListLibrary": [
        {
          "ListLibraryName": "Account Balances",
          "ListLibraryId": 12112
        },
        {
          "ListLibraryName": "Assertions",
          "ListLibraryId": 12123
        },
        {
          "ListLibraryName": "Default Risks",
          "ListLibraryId": 12143
        }
      ],
      "ProcedureStepAttributes": [
        {
          "ProcedureStepAttributesName": "Purpose",
          "ProcedureStepAttributesId": 1232
        },
        {
          "ProcedureStepAttributesName": "Timing",
          "ProcedureStepAttributesId": 12311
        },
        {
          "ProcedureStepAttributesName": "Dependency",
          "ProcedureStepAttributesId": 11111
        }
      ],
      "IsRiskAssessmentApplied": true,
      "Procedures": true,
      "KPIInput": true,
      "Enabled": true,
      "MethodologyVersionId": 555
    }
  ]

}