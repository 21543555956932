import React from 'react';
import { Box } from "@mui/material";
import { styled } from '@mui/system';
import { useFormContext } from "react-hook-form";

import { FIELD_TYPES } from "@ais/constants";
import styles from '@components/FormView/FormView.module.css';
import useProjectContext from "@contexts/ProjectContext";
import { useLocking, useFinalizedProjectNoId } from '@hooks';
import { useProjectFormInstanceProviderV2 } from '@providers';
import CLATrialBalanceSelect from '@views/KPI/CLATrialBalanceSelect/CLATrialBalanceSelect';

const StyledColumnField = styled(Box)(() => ({
	alignItems: 'start',
	width: '100%',
	padding: '0 24px 0 24px',
}));

export const KPIFieldSelector = ({ field }) => {
	const { projectForm: { formTypeId }, userId, actions: { onSubmit, onFocus } } = useProjectFormInstanceProviderV2();
	const { id, type } = field;
	const isProjectFinalized = useFinalizedProjectNoId();
	const { project } = useProjectContext();
	const { setValue, getValues } = useFormContext();
	const isLockedByUser = useLocking(id, userId);
	const disabled = isProjectFinalized || isLockedByUser !== undefined;

	const value = getValues(id);
	const defaultArrayValue = Array.isArray(value) ? value : value ? [value] : [];

	switch (type) {
		case FIELD_TYPES.KPI_TRIAL_BALANCE: {
			return (
				<StyledColumnField className={styles.field__wrapper}>
					<div className={styles.field__wrapper_inner}>
						<CLATrialBalanceSelect
							defaultValues={defaultArrayValue}
							formTypeId={formTypeId}
							handCaptureInput={(userInput) => {
								setValue(id, userInput);
								onSubmit(id);
							}}
							field={field}
							isDisabled={disabled}
							project={project}
							onFocus={onFocus}
							signOffList={[]}//THIS IS A GAP={signOffList}
							highlightable={true}
							isLockedByUser={isLockedByUser}
							isLocked={isLockedByUser}
						/>
					</div>
				</StyledColumnField>
			)
		}
		default:
			return <></>;
	}
};
