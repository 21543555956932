import React, { useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import { successIcon } from "@ais/assets";
import { toast } from 'react-toastify';
import { useWindowScroll } from 'react-use';
import "./CustomToast.css"

export const TOAST_TYPES = {
    ERROR: 'error',
    SUCCESS: 'success',
    LOADING: 'loading',
};

export const toastConfigs = (type) => {
    let toastClassName;
    let toastStyles = {}
    let toastProperties = {}

    if (type === TOAST_TYPES.ERROR) {
        toastClassName = "custom-toast-error"
        toastStyles = {
            backgroundColor: "#ffd7d4",
            width: 278,
            borderRadius: 50,
        }
        toastProperties = {
            autoClose: 3000,
            hideProgressBar: true,
        }
    } else if (type === TOAST_TYPES.SUCCESS) {
        toastClassName = "custom-toast-success"
        toastStyles = {
            borderRadius: "50%",
            position: "relative",
            left: "250px",
        }
        toastProperties = {
            hideProgressBar: true,
        }
    } else if (type === TOAST_TYPES.LOADING) {
        toastClassName = "custom-toast-loading"
        toastStyles = {
            borderRadius: "50%",
            position: "relative",
            left: "250px",
        }
    }

    return {
        autoClose: false,
        icon: false,
        closeButton: false,
        width: 300,
        className: toastClassName,
        style: {
            ...toastStyles
        },
        ...toastProperties,
    }
}

const Toast = (props) => {
    const { type, text, icon } = props;
    const { y: scrollPosition } = useWindowScroll();

    let toastClassName = "";
    let toastIcon = <React.Fragment />

    if (type === TOAST_TYPES.ERROR) {
        toastClassName = "custom-toast__wrapper-error"
        toastIcon = <HighlightOffIcon />
    } else if (type === TOAST_TYPES.LOADING) {
        toastClassName = "custom-toast__wrapper-loading"
        toastIcon = <CircularProgress />
    } else if (type === TOAST_TYPES.SUCCESS) {
        toastClassName = "custom-toast__wrapper-success"
        toastIcon = <img src={successIcon} alt='success-icon' className="success-icon" />
    }

    useEffect(() => {
        /** 
          Add a new className in the react-toastify container 
          so other toast of the application will not be affected 
        */
        const newClassName = "Toastify__toast-modified-container"
        document.querySelector(".Toastify__toast-container").classList.add(newClassName)

        const absoluteToast = {
            position: "absolute",
            right: "1em",
            top: "19em"
        };
        const fixedToast = {
            position: "fixed",
            right: "1em",
            top: "1em"
        }

        const scrollHeight = 255;
        const toastStyle = scrollPosition >= scrollHeight ? fixedToast : absoluteToast

        /** 
           Modify the style of the toast container 
           by using the new added className 
        */
        for (let prop in toastStyle) {
            document.querySelector(`.${newClassName}`).style[prop] = toastStyle[prop];
        }

    }, [scrollPosition])

    return (
        <div className={`custom-toast__wrapper ${toastClassName}`}>
            <span className="custom-toast__text">{text}</span>
            <span>
                {icon ? icon : toastIcon}
            </span>
        </div>
    )
}

export const CustomToast = (props) => {
    const { success, error, loading } = props;

    useEffect(() => {
        if (loading) {
            toast.dismiss();
            toast.info(<Toast type={TOAST_TYPES.LOADING} />,
                toastConfigs(TOAST_TYPES.LOADING));
        }

        if (!loading && success) {
            toast.dismiss();
            toast.info(<Toast type={TOAST_TYPES.SUCCESS} />,
                toastConfigs(TOAST_TYPES.SUCCESS));
        }

        if (!loading && error) {
            toast.dismiss();
            toast.info(<Toast type={TOAST_TYPES.ERROR} text="Internal Error! Form not saved" />,
                toastConfigs(TOAST_TYPES.ERROR));
        }

        return () => {
            toast.dismiss()
        }

    }, [loading, success, error])

    return <React.Fragment />
}

export default CustomToast
